import React from 'react';
import {
    selectCareersState,
    selectMembersState
} from "../../../reducers/selectors";
import {useDispatch, useSelector} from "react-redux";
import {openGroupSettingsModal} from "../structurePanelSlice";
import {isAllowed} from "../../../../functions/authorization/authorization";
import {STRUCTURE_CREATE_GROUP} from "../../../../functions/authorization/actions";
import sortMembersByFunction from "../../../../functions/sortMembersByFunction";
import {Member} from "../../members/types";

type props = {
    members: any,
    groupId: number,
}

export default function Group ({ members, groupId } : props) {

    const memberInfo = useSelector(selectMembersState);
    const carreers = useSelector(selectCareersState);

    const canCreateGroup = useSelector(state => isAllowed(state, STRUCTURE_CREATE_GROUP))

    const dispatch = useDispatch();

    if(members === undefined || Object.keys(memberInfo).length === 0) {
        return (<div
                className="p-3 rounded col-span-1 border h-full group relative">
                {canCreateGroup &&
                <i
                    className="material-icons absolute top-2 right-2 group-hover:block hidden text-coolgray-500 cursor-pointer hover:text-coolgray-700"
                    onClick={e => dispatch(openGroupSettingsModal({groupId}))}
                >tune</i>
                }
            </div>
        );
    }

    return (
        <>
            <div className="p-3 rounded col-span-1 border h-full group relative">
                {canCreateGroup &&
                    <i
                        className="material-icons absolute top-2 right-2 group-hover:block hidden text-coolgray-500 cursor-pointer hover:text-coolgray-700"
                        onClick={e => dispatch(openGroupSettingsModal({groupId}))}
                    >tune</i>
                }
                    {members
                        .map((mId : number) => memberInfo[mId])
                        .sort(sortMembersByFunction(carreers, new Date()))
                        .map((member : Member) => <div key={member.id} className="w-full">{member.name}</div>)}
            </div>
        </>
    );

}