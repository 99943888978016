import React from "react";
import InputWithIcon from "./InputWithIcon";

type props = {
    onChange: () => void,
    value: string,
    autoFocus?: boolean,
    onKeyUp: () => void,
}

export default function PasswordInput({
        onChange,
        value,
        autoFocus = false,
        onKeyUp = () => null,
} : props) {

    return (
        <InputWithIcon
            type="password"
            placeholder="Wachtwoord"
            onChange={onChange}
            value={value}
            autoFocus={autoFocus}
            icon="lock"
            onKeyUp={onKeyUp}
        />
    );
}