import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {closeMenu} from "../../../actions/appActions"
import HolidayMenu from "./HolidayMenu";
import OccupationMenu from "./OccupationMenu";
import BonusMenu from "./BonusMenu";
import getMenuDate from "../../../functions/getMenuDate";
import {selectAppState} from "../../../reducers/selectors";
import {BONUS, HOLIDAY, OCCUPATION} from "../../../../constants";
import "./menu.css";
import {MONTH_NAMES} from "../../../../functions/date";
import {isAllowed} from "../../../../functions/authorization/authorization";
import {
    EDIT_CEL_BONUS, EDIT_CEL_COMMENT, EDIT_CEL_OCCUPATION
} from "../../../../functions/authorization/actions";

export default function Menu(){

    const appState = useSelector(selectAppState);
    const dispatch = useDispatch();
    const date = getMenuDate(appState.menu_date);
    const [location, setLocation] = useState();
    const openMenuAllowed = useSelector(state =>
        (isAllowed(state, EDIT_CEL_COMMENT) && selectAppState(state).mode === HOLIDAY) // in holiday mode, menu is allowed at least when comment is allowed, holiday buttons are disabled in HolidayMenu if necessary
        || (isAllowed(state, EDIT_CEL_BONUS) && selectAppState(state).mode === BONUS)
        || (isAllowed(state, EDIT_CEL_OCCUPATION) && selectAppState(state).mode === OCCUPATION) // TODO
        );

    useEffect(() => {

        const menuWidth = document.getElementById("menu").offsetWidth;
        const menuHeight = document.getElementById("menu").offsetHeight;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const celWidth = 40;
        const celHeight = 40;
        const verticalOffset = appState.mode === BONUS ? 2*celHeight : celHeight; // bonus calendar has double rows, we want the menu to be directly below selected cel


        //calculate position
        let left = appState.menu_corner[0] + celWidth; // we want the menu to appear on the right of the cel
        let top = appState.menu_corner[1] + verticalOffset + window.scrollY;

        // adjust position if close to page borders
        let marginRight = screenWidth - left; // distance between right menu edge and right screen edge
        if(marginRight < menuWidth + 60){
            left -= menuWidth + celWidth - marginRight;
        }

        let marginBottom = screenHeight - top;
        if(marginBottom < menuHeight ){
            top -= menuHeight + celWidth + 20;
        }


        setLocation((window.innerWidth > 1023) ? {left: left + "px", top: top + "px"} : {});

    }, [appState]);

    if(!openMenuAllowed || appState.menu_active !== true)
        return (<div id="menu" className="hidden"></div>)

    /* position must be 'absolute' for menu, otherwise it stays fixed when scrolling the calendar*/
    return (
        <section id="menu" className="z-40 w-screen fixed lg:absolute px-1.5 pt-3 lg:pt-0 left-0 bottom-0 lg:bottom-auto md:w-420" style={location}>
            <i id="close" className="material-icons" onClick={() => dispatch(closeMenu())}>close</i>
            <div className="date hidden lg:inline-block p-2">{ date.getDate() + ' ' + MONTH_NAMES[date.getMonth()] + ' ' + date.getFullYear() }</div>
            {(
                () => {
                    switch(appState.mode){

                        case HOLIDAY: return <HolidayMenu />
                        case OCCUPATION: return <OccupationMenu />
                        case BONUS: return <BonusMenu />
                        default: return null
                    }
                }
            )()}
        </section>
    )
}