import {RECEIVE_MEMBERS} from "../actions/memberActions";

export default function membersReducer(state={}, action){

    switch(action.type){

        case RECEIVE_MEMBERS:
            return action.members

        default:
            return state

    }
}
