import {changeCompany as changeCompanyCalendar, changeTeam as changeTeamCalendar} from "../../main/actions/appActions";
import {changeCompany as changeCompanyMemberlist, changeTeam as changeTeamMemberlist} from "../../dashboard/actions/membersListActions";
import {selectUserState} from "../reducers/selectors";
import {logout} from "../actions/userActions";

export function saveState(state)
{
    try{
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    }catch (err) {
        console.log(err);
    }
}

export function loadState()
{
    try{
        const state = localStorage.getItem('state');

        if(state === null){
            return undefined;
        }
        return JSON.parse(state);
    }catch(err){
        console.log(err);
        return undefined;
    }
}

export function syncUserApp(store) {

    const state = store.getState();

    //PROBLEM: code is always run, this causes errors when default state is saved aka team_id=null, etc
    // how to make sure only valid data is stored local?
    // how to check if stored user is still logged in
    // how to log user in if is not but valid data?
    // can i save password in localstorage??

    if (validateUserState(selectUserState(state))) {

        // if the servers session has expired, these fetches will fail and redirect the user back to the login page
        store.dispatch(changeCompanyCalendar(selectUserState(state).company_id)); // triggers fetching teams
        store.dispatch(changeTeamCalendar(selectUserState(state).team_id)); // triggers fetching members, states, records

        // sets defaults for the admin panels memberListing
        store.dispatch(changeCompanyMemberlist(selectUserState(state).company_id));
        store.dispatch(changeTeamMemberlist(selectUserState(state).team_id));

        //NOTE same dispatches as in login
    }else{

        // if user state from localStorage is not valid, logout the app and discard all data
        store.dispatch(logout());
        //navigate("/login");
    }

}

function validateUserState(userState) {

    return ["company_id", "team_id"].reduce((valid, name) => {

        const value = userState[name];

        if(!/^\d+$/.test(value)){
            return false
        }
        return valid;

    }, true);
}