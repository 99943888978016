import React from 'react';
import getShift, {selectShiftOffset} from '../../../functions/getShift.js';
import {DAY_NAMES_SHORT} from "../../../../functions/date";
import {useSelector} from "react-redux";
import {selectAppState} from "../../../reducers/selectors";
import {useGetTeamQuery} from "../../../../dashboard/components/structure/team/selectors";

export default function HeadCel({date}){

    const shiftOffset = useSelector(selectShiftOffset);
    let weekday = DAY_NAMES_SHORT[date.getDay()];

    const teamId = useSelector(state => selectAppState(state).team_id);
    const team = useGetTeamQuery(teamId);

    const satColor = 'bg-' + team?.theme + '-300';
    const sunColor = 'bg-' + team?.theme + '-500';

    return(
        <div className={"cel head " + (weekday === 'zat' ? satColor : '') + (weekday === 'zon' ? sunColor : '') + " sticky lg:relative top-0 z-20"}>
            <span className="day">{date.getDate()}</span>
            <span className="weekday">{weekday}</span>
            <span className="shift">{getShift(date, shiftOffset)}</span>
        </div>
    );
}