
import React from "react";
//import { BrowserRouter as AppRouter, Route, Link } from "react-router-dom";

const NotFound = () => (

    <div>404 not found</div>
);

export default NotFound;
