import React, {useState} from "react";
import {selectStructurePanelState} from "../../../reducers/selectors";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../../modal/Modal";
import ModalHeader from "../../modal/ModalHeader";
import ModalBody from "../../modal/ModalBody";
import {closeGroupSettingsModal} from '../structurePanelSlice';
import ModalTitle from "../../modal/ModalTitle";
import MinGroupOccupation from "./MinGroupOccupation";
import DeleteSection from "../../modal/DeleteSection";
import ConfirmButton from "../../../../components/buttons/ConfirmButton";
import {useGetGroupQuery} from "./selectors";
import {useUpdateGroupMutation, useDeleteGroupMutation} from "./groupApi";

export default function GroupSettingsModal() {

    const menuState = useSelector(state => selectStructurePanelState(state).groupSettingsModal);
    const group = useGetGroupQuery(menuState.groupId);
    const [minOccupation, setMinOccupation] = useState(group?.min_occupation ?? 999);
    const [updateTrigger] = useUpdateGroupMutation();
    const [deleteTrigger] = useDeleteGroupMutation();

    const dispatch = useDispatch();

    const doUpdate = () => {
        updateTrigger({
            id: menuState.groupId,
            min_occupation: minOccupation,
        });
        dispatch(closeGroupSettingsModal());
    }

    const doDelete = () => {
        deleteTrigger(menuState.groupId);
        dispatch(closeGroupSettingsModal());
    }

    return (
            <Modal>
                <ModalHeader onClose={e => dispatch(closeGroupSettingsModal())}>
                    <ModalTitle>Groep instellingen</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <MinGroupOccupation value={minOccupation} setValue={setMinOccupation} />

                    <DeleteSection onDelete={doDelete}>
                        Groepen kunnen enkel verwijderd worden als er geen
                        leden meer aan toegewezen zijn.
                    </DeleteSection>

                    <div className="text-right">
                        <ConfirmButton onClick={doUpdate} />
                    </div>
                </ModalBody>

            </Modal>
    )

}