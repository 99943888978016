import React from "react";
import IconButton from "./IconButton";

type props = {
    onClick: () => void,
}

export default function ConfirmButton({onClick} : props) {

    return (
        <IconButton
            icon="check_circle"
            color="lime"
            onClick={onClick}>
            Klaar
        </IconButton>
    );

}