import React from "react";
import {
    selectIsCelActive,
    useIsMemberEnabled,
    useIsPresent,
    useIsWeekendShift
} from "./Hooks";
import {getExtraBorderStyles} from "./Cel";
import {useDispatch, useSelector} from "react-redux";
import {openMenu} from "../../../../actions/appActions";
import {selectShiftOffset} from "../../../../functions/getShift";

export default function Occupation({member, date, memberState, celData, group_id, team}){

    const state = useSelector(state => state);
    const enabled = useIsMemberEnabled(memberState, group_id);
    const shiftOffset = useSelector(selectShiftOffset)
    const shiftW = useIsWeekendShift(date, shiftOffset);
    const extraStyles = getExtraBorderStyles(date, state);
    const dispatch = useDispatch();
    const weekendStyle = shiftW ? "whitespace" : "";
    const isPresent = useIsPresent(celData, member.member_id, date);
    const celActive = useSelector(selectIsCelActive(date, member.member_id));
    const activeStyle = celActive ? "bg-lime-200 " : "";
    const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime() === date.getTime();

    let content;

    if(!shiftW) {
        content = memberState.education;
        if (celData !== null) {
            if (isPresent === false) {
                return <div className={"cel whitespace " + extraStyles}></div> // DISABLED RENDER
            } else {
                if (celData.occupation !== null && celData.occupation !== "") {
                    content = celData.occupation;
                }
            }
        }
    }

    const handleClick = (e) => {
        const left = e.target.getBoundingClientRect().left;
        const top = e.target.getBoundingClientRect().top;
        dispatch(openMenu(member, date, [left, top]))
    };

    return (
        <div className={"cel hover:bg-" + team.theme + "-200" + extraStyles + " " + weekendStyle + " " + ((shiftW && !today) ? 'bg-coolgray-200' : '') + " " + activeStyle + " " + content}
             onClick={enabled && !shiftW ? handleClick : null}
        >
            {content}
        </div>
    );

}