import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSchemesState} from "../../../redux/reducers/selectors";
import {getSchemeAbbr} from "../../../functions/scheme";
import "./schemes.css";
import { SHIFT_SCHEME} from "../../../constants";
import {createScheme} from "../../../redux/actions/schemeActions";

export default function Schemes(){

    const dispatch = useDispatch();
    const schemes = useSelector(selectSchemesState);

    const [addRow, setAddRow] = useState(false);
    const [name, setName] = useState();
    const [abbr, setAbbr] = useState();
    const [scheme, setScheme] = useState(Array(28));

    const onSubmit = () => {

        const transformedScheme = scheme.map(x => (x === true ? abbr : ""));
        dispatch(createScheme(name, transformedScheme));
        onClear();
    }

    const onClear = () => {
        setAddRow(false);
        setName();
        setAbbr();
        setScheme(Array(28));
    }

    return (
        <>
            <section className="actions-bar">
                <div className="w-4/12 inline-block">
                </div>
                <div className="w-4/12 inline-block"></div>
                <div className="w-4/12 inline-block children-right">
                    {!addRow &&
                    <button className="btn btn-success" onClick={() => setAddRow(true)}>
                        <i className="material-icons custom">add</i>
                        <span>new</span>
                    </button>
                    }
                    {addRow &&
                    <>
                        <button className="btn btn-success" onClick={() => onSubmit()}>
                            <i className="material-icons custom">done</i>
                            <span>save</span>
                        </button>
                        <button className="btn btn-secondary" onClick={() => onClear()}>
                            <i className="material-icons custom">clear</i>
                            <span>cancel</span>
                        </button>
                    </>
                    }
                </div>
            </section>

            <div className="schemes">

                {addRow &&
                    <div className="scheme">
                        <div className="w-2/12 inline-block">
                            <input className="form-control w-9/12" name="name" placeholder="naam" onChange={e => setName(e.target.value)}/>
                        </div>
                        <div className="w-1/12 inline-block">
                            <input className="form-control w-9/12" name="abbr" placeholder="afkorting" onChange={e => setAbbr(e.target.value)}/>
                        </div>
                        <div className="w-9/12 inline-block scheme-preview">
                            <div className="top flex-wrapper">
                                {SHIFT_SCHEME.map(element => {
                                    return (
                                        <div className={element === "W" ? element : ""}>{element}</div>
                                    );
                                })}
                            </div>
                            <div className="bottom flex-wrapper">
                                {[...Array(28).keys()].map(key => {
                                    const shift = SHIFT_SCHEME[key];
                                    const isW = shift === "W";
                                    return (
                                        <div className={isW ? shift : ""}>
                                            {!isW &&
                                            <input type="checkbox" name={key} key={key} onChange={e => {scheme[key] = e.target.checked; setScheme(scheme)}}/>
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                }

                {Object.values(schemes).map((scheme, i) => {
                    return (
                        <div className="scheme" key={i}>
                            <div className="w-2/12 inline-block">{scheme.name}</div>
                            <div className="w-1/12 inline-block">{getSchemeAbbr(scheme.scheme)}</div>
                            <div className="w-9/12 inline-block scheme-preview align-top">
                                    <div className="top flex-wrapper">
                                        {SHIFT_SCHEME.map((element, i) => {
                                            return (
                                                <div key={i} className={element === "W" ? element : ""}>{element}</div>
                                            );
                                        })}
                                    </div>
                                    <div className="bottom flex-wrapper">
                                        {scheme.scheme.map((element, key) => {
                                            const isW = SHIFT_SCHEME[key] === "W";
                                            return (
                                                <div className={isW ? "W" : ""} key={key}>
                                                    {!isW ? (element.trim() === "" ? "-" : element) : ""}
                                                </div>
                                            );
                                        })}
                                    </div>
                            </div>
                        </div>
                    );
                })}

            </div>

        </>
    );

}
