export default function formatMonth(date){

    let year = date.getFullYear()

    //month id from 0-11
    let month_id = date.getMonth()
    month_id += 1

    let month = ("00" + month_id).slice(-2)

    return year + "-" + month
}