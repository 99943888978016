/* functions to handle form ---------------------------------------------------------------------------------------- */

import {useEffect} from "react";

export function setOptionField(field_id, value){

    const field = document.getElementById(field_id);
    if(field === null){
        return;
    }
    field.value = value;
}

export function useSetOptionDefault(field, value){

    useEffect(() => {
        setOptionField(field, value);
    }, [field, value]);

    return null;
}

export function setCheckbox(field_id, value){

    const field = document.getElementById(field_id);
    if(field === null){
        return;
    }
    field.checked = value;
}

export function useSetCheckBoxDefault(field, value){
    useEffect(() => {
        setCheckbox(field, value);
    }, [field, value]);
    return null;
}