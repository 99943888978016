import React, {useState} from "react";
import {Link} from "@reach/router";
import {onEnter} from "../../functions/onEnter";
import {postToApi} from "../../functions/postToApi";
import Error from "./Error";
import Done from "./Done";
import Button from "../../../components/buttons/Button";
import UsernameInput from "./UsernameInput";
import InputField from "./InputField";

export default function ForgotPassword ({username, setUsername}) {

    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = () => {
        postToApi(
            "forgot-password",
            {username},
            () => setDone(true),
            () => setError(true),
        );
    };

    return (
          <>

              <UsernameInput
                  onChange={e => setUsername(e.target.value)}
                  value={username}
                  autoFocus={true}
                  onKeyUp={onEnter(handleSubmit)}
              />

              {/* Placeholder */}
              <div className="input-group invisible">
                  <InputField />
              </div>

              {(!error && !done) &&
                  <div className="invisible">
                      <Error>just a placeholder</Error>
                  </div>
              }

              {error && <Error>Uw gegevens zijn incorrect.</Error>}

              {done && <Done>Wachtwoord reset mail verzonden!</Done>}

              <div className="text-center py-2">
                  <Button color="amber-700 " onClick={handleSubmit}>Verzend email</Button>
                  <Link to="/login">
                      <Button color="coolgray" onClick={() => null}>Annuleer</Button>
                  </Link>
              </div>
          </>

    );
}