import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectAppState} from "../../../reducers/selectors";
import {MONTH_NAMES} from "../../../../functions/date";
import {approvePeriod} from "../../../actions/recordActions";
import {useSetOptionDefault} from "../../../../functions/forms/controls"
import IconButton from "../../../../components/buttons/IconButton";
import {useGetTeamQuery} from "../../../../dashboard/components/structure/team/selectors";

export default function ApproveBar(){

    const year = useSelector(state => selectAppState(state).year)
    const month = useSelector(state => selectAppState(state).month)
    const team_id = useSelector(state => selectAppState(state).team_id)
    const team = useGetTeamQuery(team_id);
    const date = new Date(Date.UTC(year, parseInt(month)-1, 1));
    const days_in_month = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
    const monthName = MONTH_NAMES[date.getMonth()];
    const [error, setError] = useState("");
    const dispatch = useDispatch();

    // fields
    const init_start = "01";
    const [start, setStart] = useState(init_start);
    useSetOptionDefault("approve-start-date", init_start);

    const init_end = days_in_month.pad(2);
    const [end, setEnd] = useState(init_end);
    useSetOptionDefault("approve-end-date", init_end);

    const handleClick = () => {
      if(parseInt(start) > parseInt(end)){
            setError("invalid period");
      }else{
          setError(null);
          const start_obj = new Date(year, month-1, start);
          const end_obj = new Date(year, month-1, end);
          dispatch(approvePeriod(team_id, start_obj, end_obj))
      }
    };

    return (
        <section id="approve-bar" className="hidden lg:flex bg-coolgray-200 rounded-lg p-2 border-black w-full my-3 flex items-center">
            <div className="inline-block font-bold text-coolgray-700 w-1/5">Verlof goedkeuren</div>
            <div className="inline-block">Van</div>
            <div className="inline-block ml-2 mr-2">
                    <select className="form-control" id="approve-start-date" onChange={e => setStart(e.target.value)}>
                        {[...Array(days_in_month).keys()].map(day => (<option key={day}>{(day+1).pad(2)}</option>))}
                    </select>
            </div>
            <div className="inline-block pr-5">{monthName}</div>
            <div className="inline-block">tot</div>
            <div className="inline-block ml-2 mr-2">
                    <select className="form-control" id="approve-end-date" onChange={e => setEnd(e.target.value)}>
                        {[...Array(days_in_month).keys()].map(day => (<option key={day}>{(day+1).pad(2)}</option>))}
                    </select>
            </div>
            <div className="inline-block pr-5">{monthName}</div>
            <div className="inline-block mt-1">
                <IconButton color={team?.theme} icon="check_circle" onClick={handleClick}>Goedkeuren</IconButton>
            </div>
            <div className="text-danger inline-block">{error}</div>
        </section>
    )
}