import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../../modal/Modal";
import ModalHeader from "../../modal/ModalHeader";
import ModalTitle from "../../modal/ModalTitle";
import ModalBody from "../../modal/ModalBody";
import ConfirmButton from "../../../../components/buttons/ConfirmButton";
import {closeAddTeamModal} from '../structurePanelSlice';
import {selectStructurePanelState} from "../../../reducers/selectors";
import {useAddTeamMutation} from "./teamApi"
import {fetchMembers} from "../../../actions/memberActions";
import {fetchCareers} from "../../../actions/careersActions";

type props = {
    companyId: number,
}

export default function AddTeamModal({ companyId } : props) {

    const dispatch = useDispatch();
    const modalState = useSelector(state => selectStructurePanelState(state).addTeamModal);

    const [triggerAddTeam] = useAddTeamMutation();
    const [name, setName] = useState('');
    const [infoRow, setInfoRow] = useState(1);
    const [shiftOffset, setShiftOffset] = useState('2012-03-03');

    const submit = () => {
        // Submit data
        triggerAddTeam({
            name: name,
            scheme_id: 1,
            company_id: companyId,
            shift_offset: shiftOffset,
            occupation: 10,
            theme: 'lime',
            info_row: infoRow,
        }).then(() => {
            // TODO: make this automatic by using RTK invalidation tags
            dispatch(fetchMembers());
            dispatch(fetchCareers(123));
        });

        // Reset Modal
        dispatch(closeAddTeamModal());
        reset();
    };

    const reset = () => {
        setName('');
        setInfoRow(1);
        setShiftOffset('2012-03-03');
    }

    if(modalState.active === false){ return null; }

    return (
        <Modal>
            <ModalHeader onClose={e => {dispatch(closeAddTeamModal()); reset(); }}>
                <ModalTitle>Voeg ploeg toe</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <label>Naam</label>
                    <input value={name} onChange={e => setName(e.target.value)} type="text" className="form-control" />
                </div>
                <div className="mb-3">
                    <label>Voeg inforij toe</label>
                    <select className="custom-select" value={infoRow} onChange={e => setInfoRow(parseInt(e.target.value))}>
                        <option value="1">Ja</option>
                        <option value="0">Nee</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label>Shift offset</label>
                    <input type="text" className="form-control" placeholder="YYYY-MM-DD" value={shiftOffset} onChange={e => setShiftOffset(e.target.value)} />
                </div>
                <div className="text-right">
                    <ConfirmButton onClick={submit} />
                </div>
            </ModalBody>
        </Modal>
    );

}