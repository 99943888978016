import React from 'react';
import HeadCel from './HeadCel';
import CelGroup from './CelGroup';
import getShift from '../../../functions/getShift.js';
import {useSelector} from "react-redux";
import {selectAppState} from "../../../reducers/selectors";
import CounterCel from "./CounterCel";
import {BONUS} from "../../../../constants";
import {selectStructureState} from "../../../../redux/reducers/selectors";
import {getGroup, getTeam} from "../../../../functions/structure";
import selectCounters from "./selectCounters";
import {useSelectMembers} from "./useSelectMembers";

export default function Day({date, data, team}){

    const members = useSelectMembers();
    const mode = useSelector(state => selectAppState(state).mode)
    const team_id = useSelector(state => selectAppState(state).team_id)
    const structure = useSelector(selectStructureState);
    const offset = getTeam(structure, team_id).shift_offset;
    const counters = useSelector(selectCounters(date), (a, b) => JSON.stringify(a) === JSON.stringify(b)); // put in top level and pass down to prevent repetitive calculations

    let list = [];
    //loop groups
    Object.entries(members).map(([group_id, group]) => {
        if(parseInt(getGroup(structure, group_id).team_id) === parseInt(team_id)) {
            list.push(
                <CelGroup
                    key={group_id}
                    group_id={group_id}
                    members={group}
                    date={date}
                    data={data}
                    counters={counters}
                    team={team}
                />);
        }
        return null;
    });

    let style = "column " + getShift(date, offset).toLowerCase();

    let current_date = new Date();
    current_date.setHours(0);
    current_date.setMinutes(0);
    current_date.setSeconds(0);
    current_date.setMilliseconds(0);
    if(date.getTime() === current_date.getTime()){
        style += " bg-" + team.theme + "-100 active"
    }

    return(
            <div className={style}>
                <HeadCel date={date} />
                {list}
                {mode !== BONUS ? (<CounterCel date={date}/>) : null}
            </div>
    );
}
