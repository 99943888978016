import React from "react";
import NavList from "./NavList";

export default function YearList() {

    let years = []
    const current_year = new Date().getFullYear()
    for(let i=-1; i< 4; i++){
        years.push([current_year + i, current_year +i])
    }

    return (
        <NavList type="year" title="Jaar" items={years} />
    );
}