import React, {useState} from "react";
import {useSelector} from "react-redux";
import {LEVEL_NAMES} from "../../../constants";
import Row from "../../../components/table/Row";
import {useDeleteUserMutation} from "./usersApi";
import {selectUserState} from "../../../redux/reducers/selectors";
import {isAdmin} from "../../../functions/authorization/authorization";
import {useGetMemberQuery} from "../members/selectors";
import EditUserModal from "./EditUserModal";

export default function UserRow({user}: any) {

    const [deleteTrigger, {isLoading}] = useDeleteUserMutation();
    const current_user = useSelector(selectUserState);
    const allowEdit = isAdmin(current_user);
    const member = useGetMemberQuery(user.member_id);
    const [editIsOpen, setEditIsOpen] = useState(false);

    return (
        <>
        <EditUserModal isOpen={editIsOpen} onClose={() => setEditIsOpen(false)} userId={user.id} />
        <Row key={user.id}>
            <div className="w-2/12 inline-block">{user.first_name + " " + user.last_name}</div>
            <div className="w-2/12 inline-block">{user.username}</div>
            <div className="w-2/12 inline-block">{member?.name}</div>
            {/*<div className="w-1/12 inline-block">*/}
            {/*    <span className={"rounded-full py-1 px-2 bg-" + team?.theme + "-500" }>*/}
            {/*        {team?.name}*/}
            {/*    </span>*/}
            {/*</div>*/}
            <div className="w-2/12 inline-block">{user?.email}</div>
            <div className="w-2/12 inline-block">{LEVEL_NAMES[Math.floor(user.level / 10)*10]}</div>
            <div className="w-1/12 inline-block">
                {user.level % 10 === 1
                    ? <i className="material-icons">check</i>
                    : <i className="material-icons invisible">check</i>
                }
            </div>
            {allowEdit &&
            <div className="w-1/12 inline-block inline-flex items-center justify-evenly text-gray-600 opacity-0 group-hover:opacity-100">
                <i onClick={() => setEditIsOpen(true)} className="material-icons cursor-pointer">edit</i>
                {!isLoading && <i className="material-icons cursor-pointer" onClick={() => deleteTrigger(user.id)}>delete</i>}
                {isLoading &&
                <div style={{width: '24px', height: '24px'}}>
                            <span style={{width: '20px', height: '20px'}}
                                  className="spinner-border spinner-border-sm mr-2"
                                  role="status" aria-hidden="true"> </span>
                </div>
                }
            </div>
            }
        </Row>
        </>
    );
}