import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {
    selectAppStateWithoutMenu,
    selectStatesStates,
} from "../../../reducers/selectors";
import {getGroup} from "../../../../functions/structure";
import {selectStructureState} from "../../../../redux/reducers/selectors";
import {isActiveInCurrentMonth, isArbeiderInCurrentMonth} from "../membercolumn/Group";
import {BONUS, HOLIDAY} from "../../../../constants";
import {getMemberState} from "../../../../functions/state";
import {selectHolidayCounters} from "./selectHolidayCounters";
import {selectBonusCounters} from "./selectBonusCounters";
import {useSelectMembers} from "../days/useSelectMembers";
import getStateDate from "../../../functions/getStateDate";
import sortMembersByFunction from "../../../../functions/sortMembersByFunction";

export default function CounterColumn(){

    const members = useSelectMembers();
    const states = useSelector(selectStatesStates)
    const appState = useSelector(selectAppStateWithoutMenu, shallowEqual);
    const holidayCounters = useSelector(selectHolidayCounters, shallowEqual);
    const bonusCounters = useSelector(selectBonusCounters, (a,b) => JSON.stringify(a) === JSON.stringify(b));
    const structure = useSelector(selectStructureState);
    const team_id = appState.team_id;

    return (
        <div className="column counter-column">
            <div className="cel head sticky lg:relative top-0 z-20 bg-white"> </div>
            {
                Object.entries(members).map(([group_id, group]) => {
                    if(parseInt(getGroup(structure, parseInt(group_id)).team_id) !== team_id){
                        return null
                    }
                    return (
                        <div key={group_id}>
                            <div className={"cel whitespace-small"}> </div>
                            <div className={"group"} key={group_id}>
                                {Object.values(group)
                                    .sort(sortMembersByFunction(states, getStateDate(appState)))
                                    .map(member => member.id)
                                    .map(memberId => {
                                    if(
                                        (isArbeiderInCurrentMonth(appState, states, memberId) || appState.mode !== BONUS)
                                        && isActiveInCurrentMonth(appState, states, memberId)
                                    ) {
                                        switch(appState.mode){

                                            case HOLIDAY:
                                                return (
                                                    <div key={memberId}>
                                                        <div className={"cel"}>
                                                            {holidayCounters[memberId] ?? getMemberState(new Date(appState.year, 0, 1), memberId, states).holiday}
                                                        </div>
                                                    </div>
                                                );

                                            case BONUS:
                                                return (
                                                    <div key={memberId}>
                                                        <div className="cel">{bonusCounters[memberId] !== undefined ? bonusCounters[memberId].vuil : " "}</div>
                                                        <div className="cel">{bonusCounters[memberId] !== undefined ? bonusCounters[memberId].oor : " "}</div>
                                                    </div>
                                                );
                                            default:
                                                return <div></div>
                                        }
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    )
                })
            }
            {appState.mode !== BONUS &&
                <>
                    <div className={"cel whitespace-small"}> </div>
                    <div className={"cel"}> </div>
                </>
            }
        </div>
    );
}