import {combineReducers} from "redux";
import membersListReducer from "./membersListReducer"
import careersReducer from "./careersReducer";
import membersReducer from "./membersReducer";
import structurePanelSlice from "../components/structure/structurePanelSlice";

export const  rootReducer = combineReducers({
    membersList: membersListReducer,
    careers: careersReducer,
    members: membersReducer,
    structurePanel: structurePanelSlice,
    }
);
