import React from "react";
import {useSelector} from "react-redux";
import {selectCareersState, selectMembersListState} from "../../../reducers/selectors";
import {useMemberState} from "../../../../main/components/calendar/days/cel/Hooks";
import Insert from "./Insert";
import EditHeader from "./EditHeader";
import CareerRow from "./CareerRow";

export default function EditMember({member, current}){

    const state = useSelector(selectMembersListState);
    const career = useSelector(state => selectCareersState(state)[member.member_id]);
    const insertUnderState = useMemberState(state.insert_date, member.member_id, selectCareersState);

    return (
        <>
            <EditHeader member={member}/>
            {
                career.map(state =>
                    <React.Fragment key={state.id}>
                            <CareerRow current={current} state={state}/>
                            { state.id === insertUnderState.id ? (<Insert current={state} member={member}/>) : null }
                        </React.Fragment>
                )
            }
        </>
    );

}