import React from "react";
import {changeInsertDate} from "../../../actions/membersListActions";
import {getDateOptions, getMonthOptions, getYearOptions} from "../../../../functions/forms/options";
import {useDispatch, useSelector} from "react-redux";
import {selectMembersListState} from "../../../reducers/selectors";

export default function InsertDate({member}){

    const state = useSelector(state => selectMembersListState(state));
    const dispatch = useDispatch();
    const className = "form-control form-control-sm date";
    const insertDate = new Date(state.insert_date);
    const year = insertDate.getFullYear();
    const month = insertDate.getMonth() + 1;
    const date = insertDate.getDate();

    return (
        <>
            <div className="team"></div>
            <div className="group">wijzigen vanaf</div>
            <div className={"select-date"}>
                <select className={className} id={"date" + member.member_id} onChange={e => dispatch(changeInsertDate(year, month, e.target.value))}>
                    {getDateOptions(year, month)}
                </select>
                <select className={className} id={"month" + member.member_id} onChange={e => dispatch(changeInsertDate(year, e.target.value, date))}>
                    {getMonthOptions()}
                </select>
                <select className={className} id={"year" + member.member_id} onChange={e => dispatch(changeInsertDate(e.target.value, month, date))}>
                    {getYearOptions()}
                </select>
            </div>

        </>
    );
}
