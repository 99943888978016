import {RECEIVE_MEMBERS} from "../actions/memberActions";

export default function membersReducer(state = {}, action) {

    switch(action.type){
        case RECEIVE_MEMBERS:
            return Object.fromEntries(Object.entries(action.members).map( a => [a[1].id, a[1]]));

        default:
            return state;
    }
}