import React, {useState} from "react";
import Table from "../../../components/table/Table";
import Head from "../../../components/table/Head";
import {useGetInvitesQuery} from "./invitesApi";
import Row from "../../../components/table/Row";
import InviteRow from "./InviteRow";
import {
    isAdmin,
    isCompanyManager,
    isGeneralManager
} from '../../../functions/authorization/authorization';
import {useSelector} from 'react-redux';
import {selectUserState} from '../../../redux/reducers/selectors';
import {UserState} from './UserList';
import {Invite} from './types';
import {useGetMembersQuery} from '../members/memberApi';
import {getMemberState} from '../../../functions/state';
import {selectCareersState} from '../../reducers/selectors';
import {useGetGroupsQuery} from '../structure/group/groupApi';
import TeamFilter from './TeamFilter';

export default function InviteList() {

    const [filter, setFilter] = useState('');
    const [team, setTeam] = useState(0);
    const {isLoading, data} = useGetInvitesQuery();
    const {data: members} = useGetMembersQuery();
    const {data: groups} = useGetGroupsQuery();
    const user : UserState = useSelector(selectUserState);
    const states = useSelector(selectCareersState);

    const filterColumns = (invite: Invite) => {
        const memberName = members?.find(m => m.id === parseInt(invite.member_id.toString()))?.name ?? '';
        return (memberName).toLowerCase().indexOf(filter.toLowerCase()) !== -1
            || invite.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            || filter === '';
    }

    const filterTeam = (invite: Invite) => {
        const member = members?.find(m => m.id === parseInt(invite.member_id.toString()));
        const groupId = getMemberState(new Date(), member?.id, states).group_id;
        const teamId = groups?.find(g => g.id === groupId)?.team_id;
        return teamId === team || team === 0;
    }

    return (
        <>
            <div className="flex justify-start">
                <input
                    type="text"
                    className="form-control w-96 mr-2"
                    placeholder="Filter gebruikers"
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                />
                {(isCompanyManager(user) || isGeneralManager(user) || isAdmin(user)) &&
                    <TeamFilter teamId={team} setTeamId={setTeam} />
                }
            </div>
                <Table>
                    <Head>
                        <div className="w-1/5 inline-block">Email</div>
                        <div className="w-1/5 inline-block">Gekoppeld teamlid</div>
                        <div className="w-1/5 inline-block">Functie</div>
                    </Head>
                    {isLoading ?
                        <Row>loading ...</Row>
                        :
                        data !== undefined ?
                            data
                                .filter(filterColumns)
                                .filter(filterTeam)
                                .map(invite => <InviteRow key={invite.id} invite={invite} />
                        ) : null
                    }
                </Table>
        </>
    );

}