import React from 'react';

export default function Buttons({mainButtons, secButtons, setRecord, active, showDelete}){

    let buttons = mainButtons.map(value => {
        return (
         <div
                key={value}
                className={ (active === value || (active === "8" && value === "x") ? "active " : "") + "row-button"}
                onClick={ () => setRecord(value)}
            >
            { value }
            </div>
        )
    });

    let dropdown = null
    if(secButtons !== null) {

        let dropdownButtons = []
        secButtons.map((value) => dropdownButtons.push(
            <div
                key={value}
                className={(active === value ? "active " : "") + "row-button"}
                onClick={() => setRecord(value)}
            >
            {value}
            </div>)
        );

        const activeInDropdown = secButtons.includes(active) && active !== "8" ? "active " : "";

        dropdown =  (
            <div id="dropdown-menu" className={"row-button " + activeInDropdown}>
                <i className="material-icons">arrow_drop_down</i>
                <div className="dropdown-content max-h-40 overflow-scroll lg:overflow-visible">
                    {dropdownButtons}
                </div>
            </div>
        );
    }

    return (
        <div className="button-row">
            {buttons}
            {dropdown}
            {showDelete !== false &&
            <div id="delete" className="row-button" onClick={() => setRecord("")}>
                <i className="material-icons">delete</i>
            </div>
            }
        </div>
    );
}
