import React from "react";

type props = {
    children: any,
}

export default function ModalBody({children}: props) {

    return (
        <div className="p-3">
            {children}
        </div>
    );

}