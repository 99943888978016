import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import List from "./List";
import {selectMembersListState} from "../../reducers/selectors";
import {selectUserState} from "../../../redux/reducers/selectors";
import {changeCompany, changeTeam, fetchMembersList} from "../../actions/membersListActions";
import {fetchCareers} from "../../actions/careersActions";
import ControlBar from "./list/ControlBar";
import "./members.css";

export default function Members () {

    const dispatch = useDispatch();

    const listState = useSelector(selectMembersListState);
    const user = useSelector(selectUserState);
    const members = useSelector(state => {

        const members = selectMembersListState(state).members;
        if(listState.team_id !== null && Object.keys(members).includes(listState.team_id.toString())) {
            return members[listState.team_id.toString()];
        }
        return {};
    });

    const team_id = listState.team_id;
    const date  = listState.date;
    const company_id = listState.company_id;

    useEffect(() => {
        if (company_id === null && team_id === null) {
            dispatch(changeCompany(user.company_id));
            dispatch(changeTeam(user.team_id));
        }
    }, [company_id, team_id, user, dispatch]);

    useEffect(() => {
        dispatch(fetchMembersList(company_id, date));
        dispatch(fetchCareers(team_id));
    }, [company_id, team_id, date, dispatch]);

    return (
        <>
            <ControlBar />
            <List members={members} teamId={team_id} />
        </>
    );
}
