import React from "react";
import NavListTitle from "./NavListTitle";
import {Link} from "@reach/router";
import NavListItem from "./NavListItem";
import NavListWrapper from "./NavListWrapper";
import {useSelector} from "react-redux";
import {selectAppState} from "../../../reducers/selectors";
import {
    isAllowed
} from "../../../../functions/authorization/authorization";
import {SEE_TEAM_LIST} from "../../../../functions/authorization/actions";

export default function ExtraList() {

    const teamId = useSelector(state => selectAppState(state).team_id);

    const allowedToSeeTeamList = useSelector(state => isAllowed(state, SEE_TEAM_LIST))

    return (
        <NavListWrapper>
            <NavListTitle>Extra</NavListTitle>
            {allowedToSeeTeamList &&
            <Link to={"/" + teamId + "/list"}><NavListItem active={false}>Team contact info</NavListItem></Link>
            }
            <Link to={"/account"}><NavListItem active={false}>Mijn account</NavListItem></Link>
        </NavListWrapper>
    );
}