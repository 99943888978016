import {
    CHANGE_DATE,
    CHANGE_MODE,
    CHANGE_TEAM,
    OPEN_MENU,
    CLOSE_MENU,
    CHANGE_COMPANY,
    RECEIVED_EXPIRED, RESET_EXPIRED
} from "../actions/appActions"
import {RECEIVE_TEAM_RECORDS} from "../actions/recordActions";

export default function appReducer(state, action){

    // initial state setup
    if(state === undefined){

        const init_state = {
                mode: 'holiday',
                team_id: null,
                company_id:null,
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
                menu_active:false,
                menu_member:{
                    "holiday": null,
                    "occupation": null,
                    "vuil": null,
                    "oor": null,
                    "comment": null
                },
                menu_date:{
                    year:null,
                    month:null,
                    date:null,
                },
                menu_corner: [220, 0],
                records_fetch_timout: false,
                last_records_fetch:0,
                expired: false,
        };
        return init_state
    }

    //actions
    switch (action.type){
        case CHANGE_DATE:
            return Object.assign({}, state, {month: parseInt(action.month), year: parseInt(action.year)});
        case CHANGE_TEAM:
            return Object.assign({}, state, {team_id:parseInt(action.team_id)});
        case CHANGE_COMPANY:
            return Object.assign({}, state, {company_id:parseInt(action.company_id)});
        case CHANGE_MODE:
            return Object.assign({}, state, {mode:action.mode});
        case OPEN_MENU:
            return Object.assign({}, state, {menu_active:true, menu_date:{year:action.date.getFullYear(),month:action.date.getMonth()+1, date:action.date.getDate()}, menu_member: action.member, menu_corner:action.corner});
        case CLOSE_MENU:
            return Object.assign({}, state, {menu_active:false});
        case RECEIVED_EXPIRED:
            return Object.assign({}, state, {expired: true})
        case RESET_EXPIRED:
            return Object.assign({}, state, {expired: false})
        case RECEIVE_TEAM_RECORDS:
            return Object.assign({}, state, {records_fetch_timout: true});
        default:
            return state
    }
}
