import {useGetMembersQuery} from "./memberApi"
import {useSelector} from "react-redux";
import {selectStatesStates} from "../../../main/reducers/selectors";
import {getMemberState} from "../../../functions/state";
import {useGetGroupsQuery} from "../structure/group/groupApi"

export const useGetMemberQuery = (memberId: number) => {
    const {member} = useGetMembersQuery(undefined, {
        selectFromResult: (({data}) => ({member: data?.find(member => member.id === parseInt(memberId.toString()))}))
    });

    return member;
}

export const useGetMembersByTeamQuery = (teamId: number) => {

    const states = useSelector(selectStatesStates);
    const {data: groups} = useGetGroupsQuery();

    const {member} = useGetMembersQuery(undefined, {
        selectFromResult: (({data}) => ({
            member: data?.filter((member) => {
                const state = getMemberState(new Date(), member.id, states);
                const group = groups?.find(group => group.id === state.group_id);
                return group?.team_id === teamId;
            })}
        ))
    });

    return member;
}