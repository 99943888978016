import {RECEIVE_STRUCTURE} from "../actions/structureActions";

export default function structureRedcucer(state={}, action){

    switch(action.type){
        case RECEIVE_STRUCTURE:
            return action.structure;
        default:
            return state;
    }

}