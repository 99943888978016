import React, {useState} from 'react';
import {Link} from "@reach/router";
import Dropdown from "./dropdown/Dropdown.tsx";
import getUrl from "../../functions/getUrl"
import {MONTH_NAMES} from "../../../functions/date";
import {shallowEqual, useSelector} from "react-redux";
import {selectAppStateWithoutMenu} from "../../reducers/selectors";
import {useGetTeamQuery} from "../../../dashboard/components/structure/team/selectors";

export default function Navigation () {

    const appState = useSelector(selectAppStateWithoutMenu, shallowEqual);

    const team = useGetTeamQuery(appState.team_id);

    const [monthsStyle, setMonthsStyle] = useState("hidden");

    /*
    * function to be called onClick for mobile touch users
    * the hoverable element is not visible for pc users, so no need to check screen sizes
    **/
    const toggleMonths = () => {
        document.getElementById("dropdown-wrapper").style.display = "none";
        setMonthsStyle(old => (old === "inline") ? "hidden" : "inline");
    };

    /*
    * function should be called onClick for mobile touch users to show dropdown
    * click function works also for pc users, main is hovering
    **/
    const toggleDropdown = () => {
        setMonthsStyle("hidden");
        const dropdown = document.getElementById("dropdown-wrapper");
        const display = dropdown.style.display;
        dropdown.style.display = (display === "flex") ? "none" : "flex";
    };

    /*
    * Function should be called to hide dropdown onMouseLeave nav area.
    * Screensize check is necessary because clicking on touch device results in triggering onMouseLeave and onClick,
    * resulting in doing an immediately undoing the action.
    **/
    const hideDropdown = () => {
        if(window.innerWidth > 1023){
            document.getElementById("dropdown-wrapper").style.display = "none";
        }
    }

    /*
    * Function should be called to hide dropdown onMouseEnter nav area.
    * Screensize check is necessary because clicking on touch device results in triggering onMouseEnter and onClick,
    * resulting in doing an immediately undoing the action.
    **/
    const showDropdown = () => {
        if(window.innerWidth > 1023) {
            document.getElementById("dropdown-wrapper").style.display = "flex";
        }
    }

    return (
        <>
            <nav id="main" className="w-screen fixed bg-white z-40 top-0 left-0" onMouseLeave={hideDropdown}>
                <div className={"w-full bg-" + team?.theme + "-300 flex justify-center h-38 lg:h-auto"}>
                    <div className="flex-auto max-w-screen-3xl">
                        <div className={"h-full rounded dropdown-button w-1/3 lg:w-1/12 inline-block align-top hover:bg-" + team?.theme + "-500 inline-flex items-center justify-center"} onClick={toggleDropdown} onMouseEnter={showDropdown}>
                            <i className="material-icons mr-1">arrow_drop_down</i>
                            <span>{team?.name}</span>
                        </div>
                        <div className={"w-1/3 lg:w-10/12 inline-block align-top bg-" + team?.theme + "-300"} id="month-hover">
                            <div className={"py-2 text-center lg:hidden hover:bg-" + team?.theme + "-500"} onClick={toggleMonths}>
                                {MONTH_NAMES[appState.month-1]}
                            </div>
                            {/*
                        visibility of the complete monthslist is always visible for lg devices, mobile devices are
                        determined by monthsStyle state, wich is "hidden" by default and toggled by clicking the element
                        above that makes it function like a dropdown
                        */}
                            <div className={"lg:w-full " + monthsStyle + " lg:inline"} id="months-list">
                                {
                                    MONTH_NAMES.map( (month, index) =>
                                        (
                                            <Link
                                                to={getUrl(appState, {month:index+1})}
                                                key={index}
                                                onClick={toggleMonths}
                                                className={
                                                    ((index+1 === appState.month) ? "bg-" + team?.theme + "-500 rounded " : "")
                                                    + "py-2 w-full lg:w-1/12 inline-block text-center hover:bg-" + team?.theme + "-500 hover:rounded"
                                                }
                                            >
                                                { month }
                                            </Link>
                                        )
                                    )
                                }
                            </div>
                        </div>
                        <div className="py-2 w-1/3 lg:w-1/12 inline-block align-top text-center">
                            {appState.year}
                        </div>
                    </div>
                </div>
                <div id="dropdown-wrapper" className="w-full flex justify-center hidden border-b-2" onClick={toggleDropdown}>
                    <Dropdown/>
                </div>
            </nav>
        </>
    );
}
