import React from "react";

type props = {
    children: any,
}

export default function Row({children}: props) {

    return (
        <div className="p-3 border-b flex items-center group">
            {children}
        </div>
    );

}
