import React, {useState} from "react";
import Table from "../../../components/table/Table";
import Head from "../../../components/table/Head";
import {useSelector} from "react-redux";
import {selectStructureState, selectUserState} from "../../../redux/reducers/selectors";
import {
    isCompanyManager,
    isGeneralManager,
    isMember,
    isTeamManager,
    isCoAccount,
    isAdmin
} from "../../../functions/authorization/authorization";
import UserRow from "./UserRow";
import {useGetUsersQuery} from "./usersApi";
import Row from "../../../components/table/Row";
import {User} from "./types";
import {useGetMembersQuery} from '../members/memberApi';
import TeamFilter from './TeamFilter';

// This is the logged in user
// TODO: make consistent with user /userApi types and move out of this file
export type UserState = {
    id: number,
    member_id: number,
    // username
    first_name: string,
    last_name: string,
    // email
    // phone
    level: number,
    team_id: number, // not in types.ts
    company_id: number,
    logged_in: boolean, // not in types.ts
}

export default function UserList() {

    const defaultSort = 'level';
    const structure = useSelector(selectStructureState);
    const user : UserState = useSelector(selectUserState);
    const {isLoading, data} = useGetUsersQuery();
    const [sortOn, setSortOn] = useState(defaultSort);
    const [isSortingAsc, setIsSortingAsc] = useState(true)
    const [filter, setFilter] = useState('');
    const [team, setTeam] = useState(0);
    const {data: members} = useGetMembersQuery();

    if(isLoading || data === undefined){
        return (
            <Table>
                <Row>loading...</Row>
            </Table>
        );
    }

    const tableHead = (
        <Head>
            {
                Object.entries({
                    name: 'Naam',
                    username: 'Gebruikersnaam',
                    member_name: 'Gekoppeld teamlid',
                    email: 'Email',
                    level: 'Functie',
                }).map(([en, nl]) =>
                    <div key={en} className="w-2/12 cursor-pointer flex items-center" onClick={() => {
                        // If not first click on this col
                        if(sortOn === en){
                            // If second click, toggle sorting
                            if(isSortingAsc) {
                                setIsSortingAsc(!isSortingAsc)
                            // If third click, reset
                            }else {
                                setSortOn(defaultSort);
                                setIsSortingAsc(true);
                            }
                        // If first click, just act normal
                        }else{
                            setSortOn(en);
                            setIsSortingAsc(true);
                        }
                    }}>
                        {nl}
                        {sortOn === en && <i className={"material-icons transition-all" + (!isSortingAsc && " -rotate-180")}>keyboard_arrow_down</i>}
                    </div>
                )
            }
            <div className="w-2/12 cursor-pointer">Alles bewerken</div>
        </Head>
    );


    const sort: Record<string, (a: User, b: User) => number> = {
        name: (a: User, b: User) => a.first_name < b.first_name ? -1 : 1,
        username: (a: User, b: User) => a.username < b.username ? -1 : 1,
        member_name: (a: User, b: User) => {
            const aName = members?.find(m => m.id === a.member_id)?.name ?? '';
            const bName = members?.find(m => m.id === b.member_id)?.name ?? '';
            return aName < bName ? -1 : 1
        },
        email: (a: User, b: User) => a.email < b.email ? -1 : 1,
        level: (a: User, b: User) => a.level > b.level ? -1 : 1,
    }

    const filterColumns = (user: User) => {
        const memberName = members?.find(m => m.id === user.member_id)?.name ?? '';
        return (user.first_name + ' ' + user.last_name).toLowerCase().indexOf(filter.toLowerCase()) !== -1
            || user.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            || user.username.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            || memberName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            || filter === '';
    }

    const filterUsers = (users: User[], structure: any, filter: (u: User) => boolean) => {
        const r = users.filter(filter)
            .filter(user => user.team_id === team || team === 0)
            .filter(filterColumns)
            .sort(sort[sortOn])
            .map(acc => <UserRow user={acc} structure={structure} key={acc.id} />);

        return isSortingAsc ? r : r.slice(0).reverse();
    }

    return (
        <>
            <div className="flex justify-start">
                <input
                    type="text"
                    className="form-control w-96 mr-2"
                    placeholder="Filter gebruikers"
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                />
                {(isCompanyManager(user) || isGeneralManager(user) || isAdmin(user)) &&
                    <TeamFilter teamId={team} setTeamId={setTeam} />
                }
            </div>
            {(isCompanyManager(user) || isGeneralManager(user) || isAdmin(user)) &&
            <Table>
                {tableHead}
                {filterUsers(data, structure, (u: User) => isAdmin(u) || isGeneralManager(u) || isCompanyManager(u))}
            </Table>
            }
                <Table>
                    {tableHead}
                    {filterUsers(data, structure, (u: User) => isTeamManager(u) || isMember(u))}
                </Table>
            {isAdmin(user) &&
                <Table>
                    {tableHead}
                    {filterUsers(data, structure, isCoAccount)}
                </Table>
            }
        </>
    );
}

