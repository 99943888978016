import React from 'react';
import CompanySelectorTabs from "./CompanySelectorTabs";
import CompanySelectorInfo from "./CompanySelectorInfo";
import {useSelector} from "react-redux";
import {isAllowed} from "../../../../functions/authorization/authorization";
import {STRUCTURE_SEE_OTHER_COMPANIES} from "../../../../functions/authorization/actions";

export default function CompanySelector ({companyId, setCompanyId }) {

    const canSeeOtherCompanies = useSelector(state => isAllowed(state, STRUCTURE_SEE_OTHER_COMPANIES))

    return (
        <div className="bg-white shadow-sm border rounded-lg overflow-hidden">
            { canSeeOtherCompanies && <CompanySelectorTabs companyId={companyId} setCompanyId={setCompanyId} /> }
            <CompanySelectorInfo companyId={companyId} />
        </div>
    );

}