import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface StructurePanelState {
    addGroupModal: {
        active: boolean,
        teamId: number,
    },
    groupSettingsModal: {
        active: boolean,
        groupId: number,
    },
    addTeamModal: {
        active: boolean,
    }
    teamSettingsModal: {
        active: boolean,
        teamId: number,
    }
    addCompanyModal: {
        active: boolean,
    },
    companySettingsModal: {
        active: boolean,
        companyId: number,
    },
}

const initialState: StructurePanelState = {
    addGroupModal: {
        active: false,
        teamId: 0,
    },
    groupSettingsModal: {
        active: false,
        groupId: 0,
    },
    addTeamModal: {
        active: false,
    },
    teamSettingsModal: {
        active: false,
        teamId: 0,
    },
    addCompanyModal: {
        active: false,
    },
    companySettingsModal: {
        active: false,
        companyId: 0,
    },
}

const structurePanelSlice = createSlice({
    name: 'structurePanel',
    initialState,
    reducers: {
        // group
        openAddGroupModal(state, action: PayloadAction<{teamId: number}>){
            state.addGroupModal.active = true;
            state.addGroupModal.teamId = action.payload.teamId;
        },
        closeAddGroupModal(state) {
            state.addGroupModal.active = false;
            state.addGroupModal.teamId = 0;
        },
        openGroupSettingsModal(state, action: PayloadAction<{groupId: number}>) {
            state.groupSettingsModal.active = true;
            state.groupSettingsModal.groupId = action.payload.groupId;
        },
        closeGroupSettingsModal(state) {
            state.groupSettingsModal.active = false;
            state.groupSettingsModal.groupId = 0;
        },
        // team
        openAddTeamModal(state) {
            state.addTeamModal.active = true;
        },
        closeAddTeamModal(state) {
            state.addTeamModal.active = false;
        },
        openTeamSettingsModal(state, action: PayloadAction<{teamId: number}>) {
            state.teamSettingsModal.active = true;
            state.teamSettingsModal.teamId = action.payload.teamId;
        },
        closeTeamSettingsModal(state) {
            state.teamSettingsModal.active = false;
            state.teamSettingsModal.teamId = 0;
        },
        // company
        openAddCompanyModal(state) {
            state.addCompanyModal.active = true;
        },
        closeAddCompanyModal(state) {
            state.addCompanyModal.active = false;
        },
        openCompanySettingsModal(state, action: PayloadAction<{companyId: number}>) {
            state.companySettingsModal.active = true
            state.companySettingsModal.companyId = action.payload.companyId;
        },
        closeCompanySettingsModal(state) {
            state.companySettingsModal.active = false;
            state.companySettingsModal.companyId = 0;
        },

    }
});

export const {
    openAddGroupModal,
    closeAddGroupModal,
    openGroupSettingsModal,
    closeGroupSettingsModal,

    openAddTeamModal,
    closeAddTeamModal,
    openTeamSettingsModal,
    closeTeamSettingsModal,

    openAddCompanyModal,
    closeAddCompanyModal,
    openCompanySettingsModal,
    closeCompanySettingsModal,
} = structurePanelSlice.actions;

export default structurePanelSlice.reducer;