import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL, INACTIVITY_LIMIT, LAST_ACTIVITY} from "../../../constants";
import {login} from "../../../redux/actions/userActions";
import {
    changeCompany as calendarChangeCompany,
    changeTeam as calendarChangeTeam, resetExpired
} from "../../../main/actions/appActions";
import {
    changeCompany as memberlistChangeCompany,
    changeTeam as memberlistChangeTeam
} from "../../../dashboard/actions/membersListActions";
import {navigate} from "@reach/router";
import getUrl from "../../../main/functions/getUrl";
import {useDispatch, useSelector} from "react-redux";
import {selectAppState} from "../../../main/reducers/selectors";
import Error from "./Error";
import {onEnter} from "../../functions/onEnter";
import {selectUserState} from "../../../redux/reducers/selectors";
import Button from "../../../components/buttons/Button";
import {api} from "../../../redux/api";
import UsernameInput from "./UsernameInput";
import PasswordInput from "./PasswordInput";

export default function  Login ({username, setUsername}) {

    const appState = useSelector(selectAppState);
    const user = useSelector(selectUserState);
    const loggedIn = user.logged_in;
    const dispatch = useDispatch();
    const inactive = Date.now() - localStorage.getItem(LAST_ACTIVITY) > INACTIVITY_LIMIT;
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const doRefetchTeams = api.usePrefetch('getTeams', {force: true});

    // navigate to calendar if already logged in
    useEffect(() => {
        if(loggedIn){
            navigate(getUrl(appState));
        }
    }, [loggedIn, appState]);

    const handleLogin = () => {

        setError(false);

        if(!username || !password){
            return;
        }

        axios.defaults.withCredentials = true;
        axios.get(`${API_URL}/sanctum/csrf-cookie`).then(response => {
            axios.post(`${API_URL}/spa-login`, {
                username: username,
                email: username,
                password: password,
            }).then(response => {
                if(response.status === 200){
                    doRefetchTeams();

                    dispatch(login(response.data));
                    // same dispatches as in syncUserApp
                    dispatch(calendarChangeCompany(response.data.company_id));
                    dispatch(calendarChangeTeam(response.data.team_id));
                    dispatch(memberlistChangeCompany(response.data.company_id));
                    dispatch(memberlistChangeTeam(response.data.team_id));
                    dispatch(resetExpired());
                    navigate(getUrl(appState, {team_id:response.data.team_id}));
                }
            }).catch(response => {
                setError(true);
            });

        }).catch(response => {
            setError(true);
        });
    };

    return (
        <>

            <UsernameInput
                onChange={e => setUsername(e.target.value)}
                value={username}
                autoFocus={true}
                onKeyUp={onEnter(handleLogin)}
            />

            <PasswordInput
                onChange={e => setPassword(e.target.value)}
                value={password}
                onKeyUp={onEnter(handleLogin)}
            />

            {(!error && !(appState.expired && !inactive)) &&
                <div className="invisible">
                    <Error>just an invisible placeholder</Error>
                </div>
            }

            {error &&
            <Error>
                Uw gegevens zijn incorrect.
            </Error>
            }
            {/* AppState expired is set to true if the app receives a 401 or
            419 from the backend meaning the session has expired. In this case
            we want to show the notification but only when the tab is still
            open. This means the activity timer cannot be expired yet.*/}
            {(appState.expired && !inactive && !error) &&
            <Error>
                Uw sessie is verlopen.
            </Error>
            }
            <div className="text-center py-2">
                <Button color="amber-700 " onClick={handleLogin} >Aanmelden</Button>
            </div>
        </>
    );
}