import React from 'react';
import Group from "../group/Group";
import {useDispatch, useSelector} from "react-redux";
import {
    useSelectAssistantTeamLeader, useSelectTeamLeader
} from "../selectStructure";
import {selectMembersState} from "../../../reducers/selectors";
import {openAddGroupModal, openTeamSettingsModal} from '../structurePanelSlice';
import {Team as TeamType} from "./types"
import {useGetGroupsByTeamQuery} from "../group/selectors";
import {isAllowed} from "../../../../functions/authorization/authorization";
import {
    STRUCTURE_CREATE_GROUP,
    STRUCTURE_EDIT_TEAM
} from "../../../../functions/authorization/actions";

type props = {
    team: TeamType,
    membersByGroup: any,
}

export default function Team ({ team, membersByGroup } : props) {

    const groups = useGetGroupsByTeamQuery(team.id);

    const teamLeaderId = useSelectTeamLeader(team.id);
    const assistantTeamLeaderId = useSelectAssistantTeamLeader(team.id);
    const members = useSelector(selectMembersState);
    const dispatch = useDispatch();

    const groupIds = groups?.map(group => group.id);
    const filteredEntries = Object.entries(membersByGroup).filter(e => groupIds?.includes(parseInt(e[0])));
    const memberCount = filteredEntries.reduce((prev, curr: any) =>  prev + curr[1].length, 0);
    const canCreateGroup = useSelector(state => isAllowed(state, STRUCTURE_CREATE_GROUP))
    const canEditTeam = useSelector(state => isAllowed(state, STRUCTURE_EDIT_TEAM))

    if(Object.keys(members).length === 0){ return null }

    const searchTeamLeader : any = Object.values(members).filter( (member:any) => member.id === teamLeaderId);

    const teamLeaderName = searchTeamLeader.length === 1 ? searchTeamLeader[0].name : null;

    const searchAssistantTeamLeader = Object.values(members).filter( (member:any) => member.id === assistantTeamLeaderId);
    // @ts-ignore
    const assistantTeamLeaderName = searchAssistantTeamLeader.length === 1 ? searchAssistantTeamLeader[0].name : null;

    return (
        <div className="bg-white shadow-sm border rounded-lg mt-3 overflow-hidden">

            {/* header */}
            <div className={"bg-coolgray-100 border-l-8 border-" + team.theme + "-300"}>
                <div className="w-6/12 inline-block">
                    <div className="p-3">
                        <div className="text-xl text-gray-800 pr-3 font-semibold inline-block">{team.name}</div>
                        <div className="inline-block text-gray-600">{teamLeaderName ?? '-'} | {assistantTeamLeaderName ?? '-'}</div>
                        <div className="mt-2 text-gray-600">
                            <div className="inline-block mr-3">{memberCount} leden</div>
                            <div className="inline-block mr-3">|</div>
                            <div className="inline-block">{groups?.length} groepen</div>
                        </div>
                    </div>
                </div>
                <div className="w-6/12 inline-block text-right">
                    { canEditTeam &&
                        <i className="material-icons mx-3 cursor-pointer" onClick={e => dispatch(openTeamSettingsModal({teamId: team.id}))}>tune</i>
                    }
                </div>
            </div>

            {/* groups listing */}
            {/* TODO: extract group cell layout into component */}
            <div className="p-3 grid grid-cols-4 gap-8 self-start items-center">
                {groups?.map( group => <Group key={group.id} groupId={group.id} members={membersByGroup[group.id]} />)}
                <div
                    className={"p-3 rounded col-span-1 border h-full inline-flex justify-center items-center cursor-pointer hover:bg-coolgray-100"
                    + (canCreateGroup ? '' : ' invisible')}
                    onClick={e => dispatch(openAddGroupModal({teamId: team.id}))}
                >
                    <i className="material-icons text-5xl text-coolgray-400">add</i>
                </div>
            </div>
        </div>
    );
}