import React from "react";
import IconButton from "./IconButton";

type props = {
    onClick: () => void,
}

export default function CancelButton({onClick} : props) {

    return (
        <IconButton
            icon="close"
            color="gray"
            onClick={onClick}>
            Annuleer
        </IconButton>
    );

}