import {navigate} from "@reach/router";
import {receivedExpired} from "../main/actions/appActions";
import {logout} from "../redux/actions/userActions";

/**
 * This function can serve as argument for a .then function in a promise chain
 * of a fetch request. If the response contains an 401 or 419 http status code
 * (Unauthorized), it redirects to the login page and sets the correct error
 * through the expired field in appState.
 *
 * @param response
 * @param dispatch
 */
export default function catchAuthError (response, dispatch) {
    // 401 Unauthorized, 419 Page Expired (unofficial Laravel code)
    if (response.status === 401 || response.status === 419) {
        // Only considered session expired if not on login page
        if(window.location.href.split('/').reverse()[0] !== 'login'){
            dispatch(receivedExpired());
            dispatch(logout());
            navigate('/login');
            // TODO: only do this when the app is still logged in
        }
    }
    return response;
}