import {selectUserState} from "../../redux/reducers/selectors";
import {
    ADMIN_LEVEL, CO_ACCOUNT_LEVEL,
    COMPANY_MANAGEMENT_LEVEL,
    GENERAL_MANAGEMENT_LEVEL,
    MEMBER_LEVEL,
    TEAM_MANAGEMENT_LEVEL
} from "../../constants";

export let actions = {};

export function isAllowed(state, action, params={}){

    const user = selectUserState(state);

    // admins have no restrictions
    if(user.level >= ADMIN_LEVEL){
        return true;
    }

    if(Object.keys(params).length === 0) {
        return actions[action](user, state);
    }else{
        return actions[action](user, state, params);
    }
}

export function register(action, verification){

    actions[action] = verification;
}

export function levelRequired(level){
    return function(user){
        if(level <= user.level){
            return true;
        }
        return false;
    }
}

export function isBetween(user, a, b){

    const lvl = user.level;
    return ((a <= lvl && lvl < b) || (b <= lvl && lvl < a))
}

export function isAdmin(user) { return user.level >= ADMIN_LEVEL; }
export function isGeneralManager(user) { return isBetween(user, GENERAL_MANAGEMENT_LEVEL, ADMIN_LEVEL) }
export function isCompanyManager(user) { return isBetween(user, COMPANY_MANAGEMENT_LEVEL, GENERAL_MANAGEMENT_LEVEL) }
export function isTeamManager(user) { return isBetween(user, TEAM_MANAGEMENT_LEVEL, COMPANY_MANAGEMENT_LEVEL) }
export function isMember(user) { return isBetween(user, MEMBER_LEVEL, TEAM_MANAGEMENT_LEVEL) }
export function isCoAccount(user) { return isBetween(user, CO_ACCOUNT_LEVEL, MEMBER_LEVEL) }

/*
* Returns true if the given user can edit all rows in a team, false if user can only edit his own row
**/
export function canEditAll(user){
    return (user.level % 10 === 1);
}

