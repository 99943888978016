import React from "react";
import NavList from "./NavList";
import {useGetCompaniesQuery} from "../../../../dashboard/components/structure/company/companyApi";
import {useGetTeamsQuery} from "../../../../dashboard/components/structure/team/teamApi"

export default function CompanyList() {

    const {data: companies, isLoading: isLoadingCompanies} = useGetCompaniesQuery();
    const {data: teams, isLoading: isLoadingTeams} = useGetTeamsQuery();


    if(isLoadingCompanies || isLoadingTeams)
        return null;


    return (
        // company_id cannot be set in url
        // we switch company be redirecting to the first team of another company
        <NavList type="team_id" title="Bedrijf" items={companies?.map(company => [
            company.name,
            teams?.find(team => team.company_id === company.id)?.id,
        ])} />
    );

}