import {RECEIVE_CAREERS} from "../actions/careersActions";

export default function careersReducer(state={}, action){

    switch(action.type){

        case RECEIVE_CAREERS:
            return action.careers;

        default:
            return state;

    }
}