import React from "react";

export default function Done ({children}) {

    return (
        <div className="bg-lime-200 p-3 rounded-lg text-lime-800 border-lime-800" style={{borderWidth: "1px"}}>
            {children}
        </div>
    );

}