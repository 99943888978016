import React from "react";

type props = {
    children: any,
}

export default function ModalTitle({children}: props) {

    return (
        <div className="font-extrabold text-gray-600 text-base">
            {children}
        </div>
    );
}