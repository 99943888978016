import React from "react";
import {navigate, Router} from "@reach/router";
import Dashboard from "./dashboard/components/Dashboard";
import Welcome from "./welcome/components/Welcome";
import {NoPathWrapper} from "./welcome/components/NoPathWrapper";
import Register from "./welcome/components/Register";
import Main from "./main/components/Main";
import AccountWrapper from "./main/components/account/AccountWrapper";
import {useSelector} from "react-redux";
import {selectUserState} from "./redux/reducers/selectors";

export default function IndexRouter () {

    const user = useSelector(selectUserState);

    // This prevents trying to load the calendar when the user is not logged in.
    // It would result in a 401 response from the backend which would also cause
    // redirect to the login page. However this would also set the expired flag.
    if(!user.logged_in
        && window.location.pathname.search("reset-password") !== -1
        && window.location.pathname.search("register") !== -1 ) {
        navigate('/login');
    }

    return (
        <Router>
            <Dashboard path="/dashboard/*" />
            <AccountWrapper path="/account"/>
            <Welcome path="/:loginType" />
            <Welcome path="/reset-password/:token/:email" />
            <Register path="/register/:token" />
            <NoPathWrapper path="/"/>
            <Main path="/*" />
            <NoPathWrapper default/>
        </Router>
    );
}