import React from "react";
import getUrl from "../../../main/functions/getUrl";
import {useDispatch, useSelector} from "react-redux";
import {selectAppState} from "../../../main/reducers/selectors";
import NavLink from "./NavLink";
import {selectUserState} from "../../../redux/reducers/selectors";
import {logout} from "../../../redux/actions/userActions";
import {API_URL} from "../../../constants";
import {getLevelName} from "../../../functions/levels";
import {isAllowed} from "../../../functions/authorization/authorization";
import {
    SEE_MEMBER_PANEL,
    SEE_SCHEMES_PANEL, SEE_USERS_PANEL
} from "../../../functions/authorization/actions";

export default function Navigation(){

    const appState = useSelector(selectAppState);
    const team_id = useSelector(selectUserState).team_id;
    const user = useSelector(selectUserState);
    const dispatch = useDispatch();
    const canSeeMemberPanel = useSelector(state => isAllowed(state, SEE_MEMBER_PANEL));
    const canSeeSchemesPanel = useSelector(state => isAllowed(state, SEE_SCHEMES_PANEL));
    const canSeeUsersPanel = useSelector(state => isAllowed(state, SEE_USERS_PANEL));

    const handleLogout = () => {
        dispatch(logout());
        fetch(`${API_URL}/spa-logout`,
            {
                credentials: "include",
            }).then(() => {
            dispatch(logout());
        });
    }

    return (
        <nav id="dashboard-nav" className="bg-coolgray-100 min-h-screen border-r">
            <header className="flex border-b items-center p-2">
                <i className="material-icons p-2 text-4xl text-coolgray-700">account_circle</i>
                <div>
                    <div className="text-xl text-coolgray-700">{user.first_name + " " + user.last_name}</div>
                    <div className="text-coolgray-500 capitalize">{getLevelName(user.level)}</div>
                </div>
            </header>
            <NavLink to={getUrl(appState, {team_id:team_id})} name="Terug naar kalender" icon="arrow_back"/>
            {/*<NavLink to="general" name="Overzicht" icon="apps" />*/}
            { canSeeMemberPanel && <NavLink to="members" name="Teamleden" icon="person" /> }
            { canSeeSchemesPanel && <NavLink to="schemes" name="Schema's" icon="today" /> }
            { canSeeUsersPanel && <NavLink to="users" name="Gebruikers" icon="account_box"/> }
            <NavLink to="structure" name="Structuur" icon="device_hub" />
            <div className="hover:text-black px-2 mx-2 py-3 mb-1 rounded-lg overflow-hidden hover:bg-coolgray-300 cursor-pointer" onClick={handleLogout}>
                <div className="flex content-center">
                    <i className="material-icons mr-2">exit_to_app</i>
                    <span>Logout</span>
                </div>
            </div>
        </nav>
    );
}