import React, {useEffect, useState} from "react";
import Layout from "./Layout";
import {useRegisterMutation} from "../../dashboard/components/users/usersApi"
import {navigate} from "@reach/router";
import SpinnerButton from "../../components/buttons/SpinnerButton";
import {useDispatch} from "react-redux";
import {logout} from "../../redux/actions/userActions";
import InputWithIcon from "./form/InputWithIcon";
import {onEnter} from "../functions/onEnter";
import InputField from "./form/InputField";

type props = {
    token: string,
}

export default function Register({token}: props) {

    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [username, setUsername] = useState('');

    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [registerTrigger, {isLoading, isSuccess, isError}] = useRegisterMutation();

    const passwordsEqual = password === repeatPassword;
    const showError = !passwordsEqual || isError;

    const handleSubmit = () => {

        registerTrigger({
            first_name: firstName,
            last_name: lastName,
            username: username,
            password: password,
            invite_token: token,
        });
        dispatch(logout());
    }

    useEffect(() => {
        if(isSuccess){
            navigate('/login');
        }
    }, [isSuccess]);

    return (
        <Layout>
            {/*breakpoint widts are different for this page compared to other
            pages in the Welcome section because firstname and lastname input
            fields are set on the same line*/}
            <div className="mx-auto bg-white w-full sm:w-8/12 lg:w-10/12 px-2 py-3 transition-all">
                <div className="text-xl text-center text-coolgray-600">Registreren</div>

                    <div className="flex">
                        <div className="inline-block w-1/2 pr-2">
                            <InputWithIcon
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                icon="person"
                                placeholder="Voornaam"
                            />
                        </div>

                        <div className="inline-block w-1/2 pl-2">
                            <InputField
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                placeholder="Achternaam"
                            />
                        </div>
                    </div>

                    <InputWithIcon
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                        icon="sell"
                        placeholder="Gebruikersnaam"
                    />

                    <InputWithIcon
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        icon="lock"
                        placeholder="Wachtwoord"
                        type="password"
                    />

                    <InputWithIcon
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        value={repeatPassword}
                        icon="lock"
                        placeholder="Herhaal wachtwoord"
                        type="password"
                        onKeyUp={onEnter(handleSubmit)}
                    />

                { !showError && <div className="alert alert-danger mx-24 invisible">placeholder</div> }
                { !passwordsEqual &&
                <div className="alert alert-danger mx-2 sm:mx-24">
                    Wachtwoorden zijn niet identiek.
                </div>
                }
                { (isError && passwordsEqual) &&
                <div className="alert alert-danger mx-2 md:mx-24">
                    Uw gegevens zijn incorrect, we konden u niet registreren.
                </div>
                }
                <div className={"mt-3 mb-5 flex justify-center"}>
                    <SpinnerButton onClick={() => handleSubmit()} isLoading={isLoading} color="amber-700 ">Registreren</SpinnerButton>
                </div>
            </div>
        </Layout>
    );

}