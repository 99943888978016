import React, {useState} from "react";
import Modal from "../../modal/Modal";
import ModalHeader from "../../modal/ModalHeader";
import ModalBody from "../../modal/ModalBody";
import {useDispatch, useSelector} from "react-redux";
import {closeAddCompanyModal} from '../structurePanelSlice';
import {selectStructurePanelState} from "../../../reducers/selectors";
import ModalTitle from "../../modal/ModalTitle";
import ConfirmButton from "../../../../components/buttons/ConfirmButton";
import {useAddCompanyMutation} from "./companyApi";

export default function AddCompanyModal() {

    const dispatch = useDispatch();
    const modalState = useSelector(state => selectStructurePanelState(state).addCompanyModal);
    const [name, setName] = useState('');
    const [triggerCreateCompany] = useAddCompanyMutation();

    if(modalState.active === false){
        return null;
    }

    const confirm = () => {
        triggerCreateCompany({
            'name': name,
        });
        setName('');
        dispatch(closeAddCompanyModal());
    }

    return (
        <Modal>
            <ModalHeader onClose={e => dispatch(closeAddCompanyModal())}>
                <ModalTitle>Voeg bedrijf toe</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <label>Naam</label>
                    <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
                <div className="text-right">
                    <ConfirmButton onClick={confirm} />
                </div>
            </ModalBody>
        </Modal>
    );

}