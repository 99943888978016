import React from "react";

type props = {
    type?: string,
    placeholder?: string,
    onKeyUp?: () => void,
    onChange: (e: any) => void,
    value: string,
    autoFocus?: boolean,
};

export default function InputField({
      type = 'text',
      placeholder = '',
      onKeyUp = () => null,
      onChange,
      value,
      autoFocus = true,
  } : props) {

    return (
        <div className="flex pt-2 py-2">
            <input
                type={type}
                placeholder={placeholder}
                onKeyUp={onKeyUp}
                onChange={onChange}
                value={value}
                autoFocus={autoFocus}
                required={false}
                className="focus:border-lime-700 focus:border-2 inline-flex
                  w-full h-10 px-3 py-3 border rounded-r-lg rounded-l-lg
                  placeholder-coolgray-500 text-lg outline-none "
            />
        </div>
    );
}