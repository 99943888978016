import React from "react";
import {
    LaravelErrors
} from "../../../components/inputerrror/catchLaravelErrors";
import InputError from "../../../components/inputerrror/InputError";

type props = {
    firstName: string,
    setFirstName: (s:string) => void,
    lastName: string,
    setLastName: (s:string) => void,
    email: string,
    setEmail: (s:string) => void,
    errors?: LaravelErrors,
}

export default function UserInformation(
    {
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        errors = {}
    }: props) {

    return (
        <>
            <h3 className="text-center">Personal Information</h3>
            <div className="form-row mb-3">
                <label>Voornaam</label>
                <input type="text" className="form-control" placeholder="Alain" value={firstName} onChange={e => setFirstName(e.target.value)}/>
                <InputError name="first_name" errors={errors} />
            </div>
            <div className="form-row mb-3">
                <label>Achternaam</label>
                <input type="text" className="form-control" placeholder="Vandamme" value={lastName} onChange={e => setLastName(e.target.value)}/>
                <InputError name="last_name" errors={errors} />
            </div>
            <div className="form-row mb-3">
                    <label>E-mail</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroupPrepend">@</span>
                        </div>
                        <input type="email" className="form-control" id="email" placeholder="alain@hotmail.com" value={email} onChange={e => setEmail(e.target.value)} required />
                        <InputError name="email" errors={errors} />
                    </div>
            </div>
        </>
    );

}