import React from "react";
import Tabs, {Tab} from "./Tabs";
import Box from "../box/Box";

type props = {
    children: any,
    tabs: Tab[] | null,
}

export default function ActionBar({children, tabs}: props) {

    return (
        <Box>
            {tabs !== null && <Tabs tabs={tabs}/> }
            <div className="p-3">
                {children}
            </div>
        </Box>
    );
}