import React from "react";
import {LaravelErrors} from "./catchLaravelErrors";

type props = {
    name: string,
    errors: LaravelErrors | undefined,
}

export default function InputError({name, errors} : props) {

    // In case the error response is malformed
    if(typeof errors !== "object"
        || !(name in errors)
        || errors[name].length === 0)
    {
        return null;
    }

    // Extract message
    const message = errors?.[name][0];

    return (
        <div className={"text-red-600 font-bold mt-2 transition-all w-full"
            + (errors?.[name] ? " max-h-3": " max-h-0")}>
            {message.charAt(0).toUpperCase() + message.slice(1)}
        </div>
    );

}