
import {SET_COMMENT, SET_HOLIDAY, SET_OCCUPATION, SET_OOR, SET_VUIL} from "../actions/recordActions";
import formatDate from "../functions/formatDate";
import {RECEIVE_TEAM_RECORDS} from "../actions/recordActions";

export default function recordsReducer(state={}, action) {

    let cel = {};

    switch(action.type){
        case SET_HOLIDAY:

            cel = addToDefault({holiday:action.holiday}, action);

            return insertIntoState(cel, state, action)

        case SET_COMMENT:

            cel = addToDefault({comment: action.comment}, action);

            return insertIntoState(cel, state, action);

        case SET_OCCUPATION:

            cel = addToDefault({occupation: action.occupation}, action);

            return insertIntoState(cel, state, action);

        case SET_OOR:

            cel = addToDefault({oor: action.oor}, action);

            return insertIntoState(cel, state, action);

        case SET_VUIL:

            cel = addToDefault({vuil: action.vuil}, action);

            return insertIntoState(cel, state, action);

        case RECEIVE_TEAM_RECORDS:

            let state_copy = Object.assign({}, state);
            state_copy = action.records;
            return state_copy;

        default:
            return state
    }

}

function insertIntoState(default_cel, state, action){

    let cel = {holiday:null}

    //make state copy to return after mutation
    let state_copy = Object.assign({}, state)

    // if cel is already in state, load content
    if(formatDate(action.date) in state){

        if(action.member.member_id in state[formatDate(action.date)]){

            cel = state[formatDate(action.date)][action.member.member_id]
        }
    }else{
        state_copy[formatDate(action.date)] = {}
    }

    //update cel
    const new_cel = Object.assign(cel, default_cel)

    //insert at correct place in state
    state_copy[formatDate(action.date)][action.member.member_id] = new_cel
    return state_copy

}

function addToDefault(values, action) {
    return Object.assign(
        {member_id: action.member.member_id},
        values
    )
}