import React from "react";
import Account from "./Account";
import {useGetUserQuery} from "../../../dashboard/components/users/selectors";
import {useGetTeamQuery} from "../../../dashboard/components/structure/team/selectors";
import {useGetMemberQuery} from "../../../dashboard/components/members/selectors";
import {useSelector} from "react-redux";
import {selectUserState} from "../../../redux/reducers/selectors";
import {selectAppState} from "../../reducers/selectors";

export default function AccountWrapper() {

    const appState = useSelector(selectAppState);
    const auth_user = useSelector(selectUserState);
    const user = useGetUserQuery(auth_user.id);
    const team = useGetTeamQuery(appState.team_id);
    const member = useGetMemberQuery(user?.member_id ?? 0);

    if(user === undefined || team === undefined || member === undefined)
        return (<div>loading ...</div>);

    return (
        <Account user={user} team={team} member={member} />
    )

}