import appReducer from "./appReducer";
import membersReducer from "./membersReducer";
import recordsReducer from "./recordsReducer";
import statesReducer from "./statesReducer";

export const rootReducer = ( state={}, action, fullState ) => {
    return {
        app: appReducer(state.app, action),
        records: recordsReducer(state.records, action),
        states: statesReducer(state.states, action),
        members: membersReducer(state.members, action),
    }
};
