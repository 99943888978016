
export function selectMembersListState(state){
    return state.dashboard.membersList;
}

export function selectCareersState(state){
    return state.dashboard.careers;
}

export function selectMembersState(state){
    return state.dashboard.members;
}

export function selectStructurePanelState(state){
    return state.dashboard.structurePanel;
}