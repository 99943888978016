import React from 'react';
import {API_URL} from "../../../../constants";
import {logout} from "../../../../redux/actions/userActions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {navigate} from "@reach/router";
import {isAllowed} from "../../../../functions/authorization/authorization";
import {getLevelName} from "../../../../functions/levels";
import {selectUserState} from "../../../../redux/reducers/selectors";
import IconButton from "../../../../components/buttons/IconButton";
import {useGetTeamQuery} from "../../../../dashboard/components/structure/team/selectors";
import {selectAppStateWithoutMenu} from "../../../reducers/selectors";

export default function ProfileSection () {

    const dispatch = useDispatch();
    const appState = useSelector(selectAppStateWithoutMenu, shallowEqual);
    const team = useGetTeamQuery(appState.team_id);

    const handleLogout = () => {
        fetch(`${API_URL}/spa-logout`,
            {
                credentials: "include",
            }).then(() => {
                dispatch(logout());
        });
    };

    const user = useSelector(selectUserState);
    const canSeeDashboard = useSelector(state => isAllowed(state, "dashboard"));

    return (

        <section className="text-center pb-40 lg:pb-0 w-1/4 border-l">
            <div className="w-full text-3xl pt-5 text-coolgray-600">{user.first_name} {user.last_name}</div>
            <div className="w-full text-xl py-3 text-coolgray-700">{getLevelName(user.level)}</div>
            {process.env.REACT_APP_STAGE !== 'PRODUCTION' &&
                <div className="pb-3">{process.env.REACT_APP_STAGE}</div>
            }
            <div className="w-full">
                <IconButton icon="exit_to_app" color="coolgray" onClick={handleLogout}>Log uit</IconButton>
                {canSeeDashboard &&
                    <IconButton icon="build" color={team?.theme} onClick={() => navigate('/dashboard')} >Dashboard</IconButton>
                }
            </div>
        </section>
    );
}
