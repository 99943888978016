import React, {useState} from "react";
import {useParams} from "@reach/router";
import {onEnter} from "../../functions/onEnter";
import {postToApi} from "../../functions/postToApi";
import Error from "./Error";
import Button from "../../../components/buttons/Button";
import Done from "./Done";

export default function ResetPassword () {

    const {token, email} = useParams();

    const [password, setPassword] = useState("");
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = () => {

        postToApi(
            "reset-password",
            {
                token,
                email,
                password,
                password_confirmation: password,
            },
            () => {
                setDone(true);
                setError(false);
            },
            () => {
                setError(true);
                setDone(false);
            });

    };

    return (
        <>
                <>
                    <label className="sr-only" htmlFor="inlineFormInputGroup">Password</label>
                    <div className="input-group py-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">
                                <i className="material-icons">lock</i>
                            </div>
                        </div>
                        <input type="password"
                               className="form-control password"
                               id="inlineFormInputGroup"
                               placeholder="Nieuw wachtwoord"
                               onKeyUp={onEnter(handleSubmit)}
                               onChange={(e) => setPassword(e.target.value)}
                               autoFocus
                        />
                    </div>

                    {/* Placeholder */}
                    <div className="input-group invisible py-2">
                        <input type="text" className="form-control"/>
                    </div>

                    {(!error && !done) &&
                        <div className="invisible">
                            <Error>just a placeholder</Error>
                        </div>
                    }

                    {error && <Error>Er liep iets fout.</Error>}

                    {done && <Done>Wachtwoord ingesteld!</Done>}

                    <div className="text-center py-2">
                        <Button color="amber-700 " onClick={handleSubmit}>Reset</Button>
                    </div>
                </>
        </>

    );

}