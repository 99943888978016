import React, {useEffect, useState} from "react";

type props = {
    value: number,
    onChange: (value: number) => void,
}

export default function MinTeamOccupation({value, onChange} : props){

    const [custom, setCustom] = useState(false);

    const min = 5;
    const range = 10;

    useEffect( () => {
        if(value < min || value > min + range){
            setCustom(true);
        }
    }, [value]);

    const onSelectChange = (value : string) => {
        setCustom(value === 'custom');
        if(value !== 'custom') {
            onChange(parseInt(value));
        }
    };

    return (
        <div className="mb-3">
            <label>Minimum bezetting</label>
            <select className="custom-select mb-3" id="min-occupation" value={custom ? 'custom' : value} onChange={e => onSelectChange(e.target.value)}>
                {
                    Array(range + 1).fill(0).map((v, i) =>
                        <option key={i+min}>{i+min}</option>
                    )
                }
                <option value="custom">Aangepast</option>
            </select>
            {custom &&
                <input type="number" className="form-control" value={value} onChange={e => onChange(parseInt(e.target.value))}/>
            }
        </div>
    );
}