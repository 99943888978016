import React, {useEffect, useState} from 'react';
import CompanySelector from './company/CompanySelector';
import {useDispatch, useSelector} from 'react-redux';
import Team from "./team/Team";
import {selectMembersByGroup} from "./selectStructure";
import {fetchMembers} from "../../actions/memberActions";
import {fetchCareers} from "../../actions/careersActions";
import {selectStructurePanelState} from "../../reducers/selectors";
import GroupSettingsModal from "./group/GroupSettingsModal";
import AddGroupModal from "./group/AddGroupModal";
import AddCompanyModal from "./company/AddCompanyModal";
import AddTeamModal from "./team/AddTeamModal";
import TeamSettingsModal from "./team/TeamSettingsModal";
import CompanySettingsModal from "./company/CompanySettingsModal";
import {useGetTeamsQuery} from "./team/teamApi"
import {useGetCompaniesQuery} from "./company/companyApi"
import {useGetGroupsQuery} from "./group/groupApi"
import {selectUserState} from "../../../redux/reducers/selectors";
import {
    isAdmin,
    isCompanyManager, isGeneralManager
} from "../../../functions/authorization/authorization";


export default function Structure () {

    const structurePanel = useSelector(selectStructurePanelState);
    const membersByGroup = useSelector(selectMembersByGroup);
    const user = useSelector(selectUserState);
    const {data: allTeams} = useGetTeamsQuery();
    useGetCompaniesQuery();
    const {data: groups} = useGetGroupsQuery();
    const [companyId, setCompanyId] = useState(user.company_id);

    const teams = allTeams?.filter(team => team.company_id === companyId)
        .filter(team => team.id === user.team_id || isCompanyManager(user) || isGeneralManager(user) || isAdmin(user));
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch(fetchMembers());
        dispatch(fetchCareers(123));
    }, [dispatch]);

    if(teams === undefined || groups === undefined)
        return <div>loading ...</div>;

    return (
        <>
            <CompanySelector companyId={companyId} setCompanyId={setCompanyId}/>
            {teams?.sort((a, b) => a.name > b.name ? 1 : -1).map((team) => (
                <Team team={team} key={team.id} membersByGroup={membersByGroup} />
            )) // TODO: add something if no teams found for this company
            }
            {structurePanel.groupSettingsModal.active && <GroupSettingsModal/> }
            {structurePanel.teamSettingsModal.active && <TeamSettingsModal/> }
            <AddGroupModal/>
            <AddCompanyModal/>
            <AddTeamModal companyId={companyId}/>
            {structurePanel.companySettingsModal.active && <CompanySettingsModal/> }
       </>
    );
}