import React from "react";
import {useSelector} from "react-redux";
import {selectCareersState, selectMembersListState} from "../../../reducers/selectors";
import {useMemberState} from "../../../../main/components/calendar/days/cel/Hooks";
import ShowRow from "./ShowRow";
import EditMember from "./EditMember";

export default function Row ({member}) {

    const state = useSelector(selectMembersListState);
    const careers = useSelector(selectCareersState);
    const edit = state.edit_member_id === parseInt(member.member_id);
    const current = useMemberState(state.date, member.member_id, selectCareersState);

    const keys = Object.keys(careers).map(key => parseInt(key)); // TODO: remove this crappy hack after implementing input verification and typescript

    // if for some reason there are no states found for this member
    if(!keys.includes(parseInt(member.member_id))){
        return null;
    }

    return (
        <div className={edit ? "member edit" : "member"}>
            {edit
                ? <EditMember member={member} current={current}/>
                : <ShowRow member={member} current={current}/>
            }
        </div>
    );
}

