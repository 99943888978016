import {
    selectAppStateWithoutMenu,
    selectRecordsState
} from "../../../reducers/selectors";
import getStateDate from "../../../functions/getStateDate";

/**
 * This function extracts the yearly bonus counters from the state three. It
 * sums up all bonus hours for all members over all records in the current
 * month.
 */
export function selectBonusCounters(state) {

    // make sure this data is available for the whole year!
    const app = selectAppStateWithoutMenu(state);
    const records = selectRecordsByMonth(state, app.year, app.month);

    // set the date to january first of the active year
    const date = getStateDate(selectAppStateWithoutMenu(state));
    date.setMonth(0, 1);

    // init
    let counters = {};

    // loop all records
    Object.values(records).forEach( days => {

        Object.values(days).forEach( cel => {

            // init start value
            if (!(cel.member_id in counters)) {
                counters[cel.member_id] = {oor: 0, vuil: 0}
            }

            if(cel.oor !== null && "oor" in cel)
                counters[cel.member_id].oor += parseInt(cel.oor);

            if(cel.vuil !== null && "vuil" in cel)
                counters[cel.member_id].vuil += parseInt(cel.vuil);

        })
    });

    return counters;
}

/**
 * This function selects all records from the state three matching the given
 * year.
 */
function selectRecordsByMonth(state, year, month){

    const records = selectRecordsState(state);

    return Object.fromEntries(
        Object.entries(records)
            .filter( entry => new Date(entry[0]).getFullYear() === year)
            .filter( entry => new Date(entry[0]).getMonth() === (month-1))
    );

}