
export function selectAppState(state){
    return state.main.app;
}

export function selectAppStateWithoutMenu(state){
    const appState = selectAppState(state);
    return {
        mode: appState.mode,
        team_id: appState.team_id,
        company_id: appState.company_id,
        month: appState.month,
        year: appState.year,
        records_fetch_timout: appState.records_fetch_timout,
        last_records_fetch: appState.last_records_fetch,
        expired: appState.expired,
    }
}

export function selectMembersState(state){
    return state.main.members;
}

export function selectRecordsState(state){
    return state.main.records;
}

export function selectStatesStates(state){
    return state.main.states;
}