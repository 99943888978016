import React, {useEffect} from "react";
import Row from "./list/Row";
import {useDispatch, useSelector} from "react-redux";
import {selectMembersListState} from "../../reducers/selectors";
import {changeDate} from "../../actions/membersListActions";
import {format} from "../../../functions/date";
import {getGroups} from "../../../functions/structure";
import {selectStructureState} from "../../../redux/reducers/selectors";
import sortMembersByFunction from "../../../functions/sortMembersByFunction";
import {selectStatesStates} from "../../../main/reducers/selectors";
import {getMemberState} from "../../../functions/state";

export default function List ({ members, teamId }) {

    const listState = useSelector(selectMembersListState);
    const dispatch = useDispatch();
    const structure = useSelector(selectStructureState);
    const states = useSelector(selectStatesStates);
    const date = useSelector(state => new Date(selectMembersListState(state).date));

    useEffect(() => {
        if(listState === undefined){
            dispatch(changeDate(format(new Date())));
        }
    });

    let groups = Object.entries(members)
        .filter( e => getGroups(structure, teamId).includes(parseInt(e[0])) )
        .map(([groupId, group]) =>
         (
            <div className="group" key={groupId}>
                {
                    Object.values(group)
                        .sort(sortMembersByFunction(states, date))
                        .filter(member => getMemberState(date, member.member_id, states).enabled === 1)
                        .map(member => {
                        return <Row member={member} key={member.member_id } />}
                    )
                }
            </div>
         )
    );

    return (
        <div className="member-list">
            <header className="member">
                <div className="name">naam</div>
                <div className="team"></div>
                <div className="group"></div>
                <div className="function">functie</div>
                <div className="education">opleiding</div>
                <div className="scheme">scheme</div>
                <div className="occupation">bezetting</div>
                <div className="holiday">verlof teller</div>
                <div className="holiday-days">verlof dagen</div>
            </header>
            {groups}
        </div>
    );
}