
import React, {useState, useEffect} from 'react';
import {Link, navigate} from "@reach/router";
import {getCompanyOptions, getGroupOptions, getTeamOptions} from "../../../../functions/forms/options";
import {useDispatch, useSelector} from "react-redux";
import {
    selectStructureState,
    selectUserState
} from "../../../../redux/reducers/selectors";
import {getTeams, getGroups} from "../../../../functions/structure";
import {createMember} from "../../../actions/membersListActions";
import {
    isAdmin,
    isGeneralManager
} from "../../../../functions/authorization/authorization";

export default function New()
{

    const structure = useSelector(selectStructureState);
    const user = useSelector(selectUserState);
    const [name, setName] = useState();
    const [companyId, setCompanyId] = useState(user.company_id);
    const [teamId, setTeamId] = useState(user.team_id);
    const [groupId, setGroupId] = useState(getGroups(structure, teamId)[0]);
    const [email, setEmail] = useState('');
    const [addInvite, setAddInvite] = useState(false);
    const dispatch = useDispatch();

    // update default states when companyId changes
    // this is necessary in case a user does only change teamId in the menu
    useEffect(() => {
        setTeamId(getTeams(structure, companyId)[0][0]);
    }, [companyId, structure]);

    useEffect(() => {
        setGroupId(getGroups(structure, teamId)[0]);
    }, [teamId, structure]);

    useEffect(() => {
        setTeamId(user.team_id);
    }, [user]);

    function handleSubmit(){
        // createMember does also create an invite in one go
        // note: it would be cleaner to do two different requests from the
        //      front-end but this is not practical because the member_id is not
        //      available
        dispatch(createMember(name, groupId, email));
        navigate("/dashboard/members");
    }

    return (
        <>
            <section className="actions-bar">
                <div className="w-4/12 inline-block">
                    <Link to={"/dashboard/members/"}>
                        <i className="material-icons custom">arrow_left</i>
                        <span>terug naar members</span>
                    </Link>
                </div>
                <div className="w-4/12 inline-block"></div>
                <div className="w-4/12 inline-block text-right">
                    <button className="btn btn-success" onClick={() => handleSubmit()}>
                        <i className="material-icons custom">done</i>
                        <span>save</span>
                    </button>
                    <Link to={"/dashboard/members"} className="btn btn-secondary">
                        <i className="material-icons custom">clear</i>
                        <span>cancel</span>
                    </Link>
                </div>
            </section>

            <div className="actions-bar">
                <div className="w-1/2 inline-block pr-5">
                    <div className="text-2xl mb-3 text-coolgray-600">Gegevens</div>
                    <div className="form-row">
                        <div className="w-full mb-3">
                            <label>Naam</label>
                            <input type="text" className="form-control" placeholder="Alain" onChange={e => setName(e.target.value)}/>
                        </div>
                    </div>

                    {(isGeneralManager(user) || isAdmin(user)) &&
                    <div className="form-row">
                        <div className="w-full mb-3">
                            <label>Bedrijf</label>
                            <select className="form-control form-control"
                                    onChange={e => {
                                        setCompanyId(parseInt(e.target.value))
                                    }}>
                                {getCompanyOptions(structure)}
                            </select>
                        </div>
                    </div>
                    }

                    {(isGeneralManager(user) || isAdmin(user)) &&
                    <div className="form-row">
                        <div className="w-full mb-3">
                            <label>Ploeg</label>
                            <select className="form-control form-control" onChange={e => setTeamId(parseInt(e.target.value))}>
                                {getTeamOptions(structure, companyId)}
                            </select>
                        </div>
                    </div>
                    }

                    <div className="form-row">
                        <div className="w-full mb-3">
                            <label>Groep</label>
                            <select className="form-control form-control" onChange={e => setGroupId(parseInt(e.target.value))}>
                                {getGroupOptions(structure, teamId)}
                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="w-full mb-3">
                            <label>Verstuur uitnodiging voor persoonlijk account?</label>
                            <select className="form-control form-control" onChange={e => setAddInvite(e.target.value === "1")}>
                                <option value={0}>nee</option>
                                <option value={1}>ja</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="inline-block align-top w-1/2">
                    {addInvite &&
                        <>
                        <div className="text-2xl mb-3 text-coolgray-600">Uitnodigen voor account</div>
                        <div className="form-row">
                            <div className="w-full mb-3">
                                <label>Email</label>
                                <input type="text" className="form-control" placeholder="example@lactam.be" onChange={e => setEmail(e.target.value)}/>
                            </div>
                        </div>
                        </>
                    }
                </div>
            </div>
        </>
    );

}