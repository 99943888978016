
function isLegitDate(date){

    let split = date.split("-");
    let year = split[0];
    let month = split[1];

    if(year.length !== 4){
        return false;
    }

    let yearint = parseInt(year);

    if(yearint < 2015){
        return false;
    }

    if(month.length !== 2){
        return false;
    }

    let monthint = parseInt(month);

    if(monthint > 13){

        return false;
    }


return true;
}

export default isLegitDate;
