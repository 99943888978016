import React from "react";
import NavList from "./NavList";
import {isAllowed} from "../../../../functions/authorization/authorization";
import {useSelector} from "react-redux";

export default function ModeList() {

    const viewHoliday = useSelector(state => isAllowed(state, "view-calendar", {mode: "holiday"}));
    const viewOccupation = useSelector(state => isAllowed(state, "view-calendar", {mode: "occupation"}));
    const viewBonus = useSelector(state => isAllowed(state, "view-calendar", {mode: "bonus"}));

    const allModes = [
        ["Verlof", "holiday", viewHoliday],
        ["Bezetting", "occupation", viewOccupation],
        ["Toeslag", "bonus", viewBonus],
        // ["weekends", "weekends"],
        // ["zomer", "summer"],
    ];

    const modes = allModes.filter((mode) => mode[2]);

    return (
        <NavList type="mode" title="Kalender" items={modes} />
    );
}