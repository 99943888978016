import {getMemberState} from "./state";

/**
 * This function returns a comparator function to sort members (of a group) by
 * their function. The comparator needs the member states and the date to
 * determine the current function value. This function should be used when
 * rendering the members. The sorting is lost when the array is converted back
 * to an object. This makes it difficult to do the sorting on reception.
 *
 * Design decisions: https://github.com/sventorfs/capro-frontend/issues/86
 * Array.sort() docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 *
 * @param states
 * @param date
 * @returns {function(*, *)}
 */
export default function sortMembersByFunction(states : any, date : Date) {
    // TODO: cannot use Member type: new API contains id instead of member_id
    return (a : any, b : any) => {
        let functionA = getMemberState(date, a.id, states).function;
        let functionB = getMemberState(date, b.id, states).function;

        // Sorry, this is hideous and bad.
        // Problem lies in historical decisions made in the
        // numbering of the functions. See constants.js
        if (functionA === 1) {functionA = 2;}
        else if (functionA === 2) {functionA = 1;}
        if (functionB === 1) {functionB = 2;}
        else if (functionB === 2) {functionB = 1;}

        // If A has higher function, negative -> sort A before B
        // if B has higher function, positive -> sort B before A
        return functionB - functionA;
    }
}