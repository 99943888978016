import React, {useState} from 'react';
import Buttons from "./Buttons";
import {setRecord} from "../../../actions/recordActions";
import {closeMenu, openMenu} from "../../../actions/appActions";
import {useDispatch, useSelector} from "react-redux";
import getShift, {selectShiftOffset} from "../../../functions/getShift";
import getMenuDate from "../../../functions/getMenuDate";
import {selectAppState} from "../../../reducers/selectors";
import {useCelData, useIsWeekendShift} from "../days/cel/Hooks";
import {HOLIDAY, HOLIDAY_MAIN_BUTTONS, HOLIDAY_SEC_BUTTONS} from "../../../../constants";
import {useIsCustomSchemeActive} from "../../../../functions/scheme";
import SchemeControl from "./SchemeControl";
import Button from "../../../../components/buttons/Button";
import {useGetTeamQuery} from "../../../../dashboard/components/structure/team/selectors";
import {selectUserState} from "../../../../redux/reducers/selectors";
import {isAdmin, isAllowed, isTeamManager} from "../../../../functions/authorization/authorization";
import {EDIT_CEL_HOLIDAY} from "../../../../functions/authorization/actions";
import {onEnter} from "../../../../welcome/functions/onEnter";

export default function HolidayMenu(){

    const dispatch = useDispatch();
    const appState = useSelector(selectAppState);
    const celData = useCelData(getMenuDate(appState.menu_date), appState.menu_member.member_id);
    const menu_date = getMenuDate(appState.menu_date);
    const customScheme = useIsCustomSchemeActive(appState.menu_member.member_id, menu_date);
    const shiftOffset = useSelector(selectShiftOffset);
    const date = getMenuDate(appState.menu_date);
    const shiftW = useIsWeekendShift(date, shiftOffset);
    const teamId = useSelector(state => selectAppState(state).team_id);
    const theme = useGetTeamQuery(teamId).theme;
    const user = useSelector(selectUserState);
    const canEditCelHoliday = useSelector(state => isAllowed(state, EDIT_CEL_HOLIDAY));
    const [comment, setComment] = useState(celData.comment);

    const main_buttons = HOLIDAY_MAIN_BUTTONS;
    let sec_buttons = HOLIDAY_SEC_BUTTONS;

    if(!isTeamManager(user) && !isAdmin(user)){
        sec_buttons = sec_buttons.filter(button => button !== "8");
    }

    const onSetRecord = (value) =>{

        // save entered value to store
        dispatch(setRecord(HOLIDAY, menu_date, appState.menu_member, value));

        // keep moving one day to skip all W shifts
        let next_date = new Date(menu_date.getTime());
        next_date.setDate(next_date.getDate() + 1);
        while(getShift(next_date, shiftOffset) === "W" ) {
            next_date.setDate(next_date.getDate() + 1);
        }

        // if last day of month, clos menu
        if(next_date.getMonth() !== menu_date.getMonth()){
            dispatch(closeMenu());
        }else {
            dispatch(openMenu(appState.menu_member, next_date, appState.menu_corner));
        }
    }

    return(
        <>
        <div className="px-2">
            {(!shiftW && canEditCelHoliday) &&
                <>
                    <Buttons active={celData.holiday} mainButtons={main_buttons} secButtons={sec_buttons} setRecord={onSetRecord}/>
                    {customScheme ? <SchemeControl celData={celData} appState={appState}/> : null}
                </>
            }

            <textarea
                id="comment-field"
                rows="4"
                placeholder="Opmerking"
                value={comment}
                onChange={e => setComment(e.target.value)}
                // save on enter, but does not feel like a submit because
                // text fields do allow enters?
                onKeyUp={onEnter(() => dispatch(setRecord("comment", menu_date, appState.menu_member, comment)))}
            >
            </textarea>
        </div>
        <div className="float-right pb-2">
            <Button color={theme} onClick={() => {
                    dispatch(setRecord("comment", menu_date, appState.menu_member, comment));
                    dispatch(closeMenu());
                }}
            >Klaar</Button>
            <Button color="gray" onClick={() => {
                    dispatch(setRecord("comment", menu_date, appState.menu_member, ""));/* VERY IMPORTANT TO USE "" INSTEAD OF NULL, if send null server will reject*/
                    dispatch(closeMenu());
                }}
            >Verwijder</Button>
        </div>
        </>
    )
}