import React from "react";

export default function Error ({children}) {

    return (
        <div className="bg-red-200 p-3 rounded-lg text-red-800 border-red-800" style={{borderWidth: "1px"}}>
            {children}
        </div>
    );

}