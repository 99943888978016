import React from "react";
import {useCelData, useIsMemberEnabled, useMemberState} from "./Hooks";
import getShift, {selectShiftOffset} from "../../../../functions/getShift";
import Holiday from "./Holiday";
import Occupation from "./Occupation";
import Bonus from "./Bonus";
import {BONUS, HOLIDAY, OCCUPATION} from "../../../../../constants";
import {isActiveInCurrentMonth, isArbeiderInCurrentMonth} from "../../membercolumn/Group";
import {useSelector} from "react-redux";
import {selectAppState, selectStatesStates} from "../../../../reducers/selectors";

export default function Cel({group_id, member, date, mode, counters, team}){

    const memberState = useMemberState(date, member.member_id);
    const celData = useCelData(date, member.member_id);
    const startDate = new Date(memberState.start_date);
    const memberActive = useIsMemberEnabled(memberState, group_id) && new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) <= date;
    const extraStyles = useSelector(selectExtraBorderStyle(date));
    const isActiveNow = useSelector(state => isActiveInCurrentMonth(selectAppState(state), selectStatesStates(state), member.member_id));
    const isArbeiderNow = useSelector(state => isArbeiderInCurrentMonth(selectAppState(state), selectStatesStates(state), member.member_id));

    if(!isActiveNow)
        return null;

    // render an empty cell while waiting on fetches
    if(memberState === null) {
        return <div className="cel"></div>;
    }

    // if member is inactive, (not when bediende in bonus calendar)
    if(!memberActive && (isArbeiderNow || mode !== BONUS)){
        if(mode === BONUS){
            return (<div>
                        <div className={"cel whitespace" + extraStyles}></div>
                        <div className={"cel whitespace" + extraStyles}></div>
                    </div>
            )
        }
        return <div className={"cel whitespace" + extraStyles}></div>
    }

    if(mode === HOLIDAY){
        return <Holiday date={date}
                        member={member}
                        memberState={memberState}
                        celData={celData}
                        group_id={group_id}
                        counters={counters}
                        team={team}
                        />
    }

    if(mode === OCCUPATION){
        return <Occupation date={date}
                        member={member}
                        memberState={memberState}
                        celData={celData}
                        group_id={group_id}
                        team={team}
        />
    }

    if(mode === BONUS && isArbeiderNow){
        return <Bonus date={date}
                       member={member}
                       memberState={memberState}
                       celData={celData}
                       group_id={group_id}
                       team={team}
        />
    }

    return (<></>);

}

export function selectExtraBorderStyle(date) {
    return (state) => getExtraBorderStyles(date, state);
}

export function getExtraBorderStyles(date, state){

    const shiftOffset = selectShiftOffset(state);
    const shift_today = getShift(date, shiftOffset);

    let style = "";
    let tomorrow = new Date();
    tomorrow.setFullYear(date.getFullYear());
    tomorrow.setMonth(date.getMonth(), 1);
    tomorrow.setDate(date.getDate() + 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);

    let yesterday = new Date();
    yesterday.setFullYear(date.getFullYear());
    yesterday.setMonth(date.getMonth(), 1);
    yesterday.setDate(date.getDate() - 1);
    yesterday.setHours(0);
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);

    const shift_yesterday = getShift(yesterday, shiftOffset);
    const shift_tomorrow = getShift(tomorrow, shiftOffset);


    if(shift_today === "N" && shift_tomorrow === "W" && tomorrow.getDate() !== 1){
        style += " extra-border-right";
    }

    if((shift_today === "V" && shift_yesterday === "W") || (shift_today === "W" && date.getDate() === 1)){
        style += " extra-border-left";
    }

    return style;
}