import React from 'react';
import {wrapInOption} from '../../../functions/forms/options';
import {useGetTeamsQuery} from '../structure/team/teamApi';
import {useGetCompaniesQuery} from '../structure/company/companyApi';

type props = {
    teamId: number,
    setTeamId: (a: number) => void,
}

export default function TeamFilter({ teamId, setTeamId}: props) {

    const {data: teams} = useGetTeamsQuery();
    const {data: companies} = useGetCompaniesQuery();

    return (
        <select className="form-control w-56" value={teamId} onChange={(e) => setTeamId(parseInt(e.target.value))}>
            <option value="0">Filter ploeg</option>
            {teams
                ?.map(team => ([
                    team.id,
                    companies?.find(a => a.id === team.company_id)?.name + " | " + team.name,
                ]))
                .sort((a, b) => a[1] < b[1] ? -1 : 1)
                .map(wrapInOption)}
        </select>
    )

}