import React from 'react';
import Member from './Member';
import {isInPeriod} from "../../../../functions/state";
import {WORKER, BONUS} from "../../../../constants";
import {shallowEqual, useSelector} from "react-redux";
import {
    selectAppStateWithoutMenu,
    selectStatesStates
} from "../../../reducers/selectors";
import getStateDate from "../../../functions/getStateDate";
import sortMembersByFunction from "../../../../functions/sortMembersByFunction";

export default function Group({members}){

    const states = useSelector(selectStatesStates);
    const appState = useSelector(selectAppStateWithoutMenu, shallowEqual);

    // if the states are not ready yet, just return
    if(states.length === 0 || states === undefined){
        return;
    }

    return (
        <div className="group">
            <div className="cel whitespace-small"></div>
            {
                Object.values(members)
                    .sort(sortMembersByFunction(states, getStateDate(appState)))
                    .map(member => {
                    if(
                        (isArbeiderInCurrentMonth(appState, states, member.member_id) || appState.mode !== BONUS)
                        && isActiveInCurrentMonth(appState, states, member.member_id)
                    ) {
                        return (<Member key={member.member_id} member={member}/>)
                    }
                    return null;
                })
            }
        </div>
    );
}

export function isArbeiderInCurrentMonth(appState, states, memberId){

    const stateDate = getStateDate(appState);
    const start = new Date(stateDate);
    const end = new Date(stateDate.setMonth(stateDate.getMonth()+1, 0));

    // this happens when members fetch is faster then states fetch
    if(!(memberId in states)){
        return true;
    }

    return isInPeriod(state => parseInt(state.function) === WORKER, start, end, states[memberId]);
}

export function isActiveInCurrentMonth(appState, states, memberId){

    const stateDate = getStateDate(appState);
    const start = new Date(stateDate);
    const end = new Date(stateDate.getFullYear(), stateDate.getMonth() + 1, 0);

    // this happens when members fetch is faster then states fetch
    if(!(memberId in states)){
        return true;
    }

    return isInPeriod(s => parseInt(s.enabled) === 1, start, end, states[memberId]);
}