import React, {useEffect} from "react";
import NotFound from "./NotFound";
import {useSelector} from "react-redux";
import {navigate, Router} from "@reach/router"
import {selectStatesStates} from "../reducers/selectors";
import TeamList from "./teamlist/TeamList";
import {
    selectSchemesState,
    selectStructureState,
    selectUserState
} from "../../redux/reducers/selectors";
import "./main.css";
import Navigation from "./navigation/Navigation";
import Calendar from "./calendar/Calendar";
import {
    useGetTeamsQuery
} from "../../dashboard/components/structure/team/teamApi";
import MainLoading from "./MainLoading";
import {
    useGetGroupsQuery
} from "../../dashboard/components/structure/group/groupApi";

export default function Main () {

    const logged_in = useSelector(selectUserState).logged_in;
    const structure = useSelector(selectStructureState);
    const schemes = useSelector(selectSchemesState);
    const states = useSelector(selectStatesStates);
    const {isLoading: isLoadingTeams} = useGetTeamsQuery();
    const {isLoading: isLoadingGroups} = useGetGroupsQuery();

    // if not logged in, always display welcome screen -------------------------
    useEffect(() => {
        if(logged_in !== true){
            navigate("/login");
        }
    }, [logged_in]);

    useEffect(() => {
        document.getElementById("body").className = "theme-light";
    }, []);


    // TODO: do we need to wait for all of these?
    if(isEmptyObj(structure)
        || isEmptyObj(schemes)
        || isEmptyObj(states)
        || isLoadingTeams
        || isLoadingGroups
    ){
        return <MainLoading />;
    }

    return (
        <div className="w-300 sm:w-200 lg:w-full pb-96 lg:pb-0 min-h-screen">
            {logged_in === true &&
                <div>
                <Navigation/>
                    <Router>
                        <Calendar path=":team_id/:mode/:date"/>
                        <TeamList path=":team_id/list"/>
                        <NotFound default/>
                    </Router>
                </div>
                    }
        </div>
    );

}

function isEmptyObj(obj) {
    return (JSON.stringify(obj) === JSON.stringify({}));
}