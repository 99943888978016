import pad from "../../functions/pad";

export default function formatDate(date){

    let year = date.getFullYear()

    //month id from 0-11
    let month_id = date.getMonth() + 1;
    month_id = pad(month_id,2)

    let month = ("00" + month_id).slice(-2)

    return year + "-" + month + "-" + pad(date.getDate(), 2);
}