import React from "react";
import Footer from "../../main/components/footer/footer";

export default function Layout({ children }){

    return (
        <>
            <div className="lg:bg-coolgray-200 min-h-screen lg:flex lg:justify-center lg:items-center pb-10">
                <div className="grid grid-cols-3 max-w-screen-lg lg:w-8/12 w-full h-2/3 lg:shadow-xl lg:rounded-lg overflow-hidden">

                    <div className="bg-welcome bg-cover hidden lg:inline"> </div>

                    <div className="col-span-3 lg:col-span-2 bg-white">
                        <div className="mx-auto w-1/3 py-3 mt-4">
                            <img src="/img/icons/factory-128.png" className="mx-auto lg:w-4/12 w-8/12" alt="background"/>
                        </div>
                        <div className="mx-auto text-center py-3 text-gray-600 text-4xl lg:text-5xl tracking-widest" style={{fontVariant:"small-caps"}}>
                            lactam
                        </div>
                        { children }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}