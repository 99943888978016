import React, {useState} from "react";
import {selectStructurePanelState} from "../../../reducers/selectors";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../../modal/Modal";
import ModalHeader from "../../modal/ModalHeader";
import ModalBody from "../../modal/ModalBody";
import {closeCompanySettingsModal} from '../structurePanelSlice';
import ModalTitle from "../../modal/ModalTitle";
import DeleteSection from "../../modal/DeleteSection";
import ConfirmButton from "../../../../components/buttons/ConfirmButton";
import {useUpdateCompanyMutation, useDeleteCompanyMutation, useGetCompaniesQuery} from "./companyApi";

export default function CompanySettingsModal() {

    const menuState = useSelector(state => selectStructurePanelState(state).companySettingsModal);
    const dispatch = useDispatch();
    const [updateTrigger] = useUpdateCompanyMutation();
    const [deleteTrigger] = useDeleteCompanyMutation();
    
    const { company } = useGetCompaniesQuery(undefined, {
        selectFromResult: ({ data: companies }) => ({
            company: companies?.find((company) => company.id === menuState.companyId),
        })
    });

    const [name, setName] = useState(company !== undefined ? company.name : '');

    if(menuState.active === false || company === undefined){ // TODO: remove undefined checks if getCompany gets a proper selector with types
        return null;
    }

    const doUpdate = () => {
        updateTrigger({
            'id': company.id,
            'name': name,
        });
        dispatch(closeCompanySettingsModal());
    }

    const doDelete = () => {
        deleteTrigger(company.id);
        dispatch(closeCompanySettingsModal())
    }

    // TODO: https://redux.js.org/usage/usage-with-typescript#define-typed-hooks

    return (
        <Modal>
            <ModalHeader onClose={e => dispatch(closeCompanySettingsModal())}>
                <ModalTitle>Bedrijf instellingen</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <div className="mb-3">
                    <label>Naam</label>
                    <input value={name} onChange={e => setName(e.target.value)} type="text" className="form-control" />
                </div>

                <DeleteSection onDelete={doDelete}>
                    Bedrijven kunnen enkel verwijderd worden als ze
                    geen ploegen bevatten.
                </DeleteSection>

                <div className="text-right">
                    <ConfirmButton onClick={doUpdate} />
                </div>
            </ModalBody>

        </Modal>
    )

}