import React, {useEffect, useState} from "react";
import {getCompanyOptions, getTeamOptions, wrapInOption} from "../../../functions/forms/options";
import {getGroups, getTeams} from "../../../functions/structure";
import {useSetOptionDefault} from "../../../functions/forms/controls";
import {fetchMembersList} from "../../actions/membersListActions";
import {useDispatch, useSelector} from "react-redux";
import {selectStructureState, selectUserState} from "../../../redux/reducers/selectors";
import {selectMembersListState} from "../../reducers/selectors";
import {selectStatesStates} from "../../../main/reducers/selectors";
import {isInPeriod} from "../../../functions/state";
import {
    isCompanyManager,
    isGeneralManager,
    isAdmin, isTeamManager,
} from "../../../functions/authorization/authorization";
import LevelSelector from "./LevelSelector";

type props = {
    member: number,
    setMember: (a: number) => void,
    level: number,
    setLevel: (a: number) => void,
}

export default function MemberSelector({member, setMember, level, setLevel}: props) {

    // TODO: clean this file up, lots of broken types
    const dispatch = useDispatch();

    //deps
    const structure = useSelector(selectStructureState);
    const user = useSelector(selectUserState);
    const states = useSelector(selectStatesStates);

    const [company, setCompany] = useState(user.company_id);
    useSetOptionDefault("company", company);
    const [team, setTeam] = useState(user.team_id);
    useSetOptionDefault("team", team);

    let members = useSelector(state => selectMembersListState(state)).members[team];
    const groups = getGroups(structure, team);

    const [membersList, setMembersList] = useState<Array<any>>([]);

    useEffect(() => {

        if(members == null) {
            return;
        }

        //getting members
        let list : Array<any> = [];
        Object.values(members).map((group : any) => {
            Object.values(group).map((member : any) => list.push([parseInt(member.member_id), member.name]));
            return null;
        });

        list = list.filter(member => isInPeriod((state : any) => groups.includes(state.group_id), start, end, states[member[0]]))
        setMembersList(list);

        setMember(list[0][0])

    }, [members]); // eslint-disable-line
    // NOTE: do not put other suggested variables back in here

    // If the company changes
    // - admin: set the team to the first team of the company as default
    // - team manager: set the team to his own team, because team managers
    //   cannot choose teams
    useEffect(() => {
        if(isTeamManager(user)) {
            setTeam(user.team_id);
        }else{
            setTeam(getTeams(structure, company)[0][0]);
        }
    }, [company, setTeam, structure, user]);

    useEffect(() => {
        dispatch(fetchMembersList(company, new Date()));
    }, [team, dispatch, company]);

    useEffect(() => {
        if(membersList.length > 0) {
            setMember(membersList[0][0]);
        }
    }, [team, membersList, setMember]);

    const start = new Date(new Date().getFullYear()-1, 0, 1);
    const end = new Date(new Date().getFullYear()+1, 11, 31);

    return (
    <div className="block">
        <h3 className="text-center">Authorisatie</h3>
        <LevelSelector level={level} setLevel={setLevel} />

        { (isGeneralManager(user) || isAdmin(user)) &&
            <>
                <h3 className="text-center">Gekoppeld teamlid</h3>
                <div className="mb-3 form-row">
                    <label>Bedrijf</label>
                    <select id="company" className="form-control" onChange={e => setCompany(e.target.value)}>
                        {getCompanyOptions(structure)}
                    </select>
                </div>
            </>
        }

        { (isCompanyManager(user) || isAdmin(user)) &&
            <>
                <div className="mb-3 form-row">
                    <label>Ploeg</label>
                    <select id="team" className="form-control" onChange={e => {
                        setTeam(e.target.value)
                    }}>
                        {getTeamOptions(structure, company)}
                    </select>
                </div>
            </>
        }

        <div className="mb-3 form-row">
            <label>Teamlid</label>
            <select id="member" className="form-control" value={member} onChange={e => setMember(parseInt(e.target.value))}>
                {membersList
                    .filter(member => isInPeriod((state : any) => groups.includes(state.group_id), start, end, states[member[0]]))
                    .map(wrapInOption)}
                {/* TODO: improve member selection here */}
            </select>
        </div>
    </div>
    );
}