import React, {useState} from "react";
import Navigation from "../navigation/Navigation";
import {useSelector} from "react-redux";
import {selectAppState} from "../../reducers/selectors";
import getUrl from "../../functions/getUrl";
import Table from "../../../components/table/Table";
import Head from "../../../components/table/Head";
import Row from "../../../components/table/Row";
import GoBackLink from "../../../components/table/GoBackLink";
import {useUpdateUserMutation} from "../../../dashboard/components/users/usersApi";
import {useUpdateMemberMutation} from "../../../dashboard/components/members/memberApi";
import SpinnerButton from "../../../components/buttons/SpinnerButton";
import CancelButton from "../../../components/buttons/CancelButton";
import EditRow from "./EditRow";
import IconButton from "../../../components/buttons/IconButton";
import {User} from "../../../dashboard/components/users/types";
import {Team} from "../../../dashboard/components/structure/team/types";
import {Member} from "../../../dashboard/components/members/types";
import {isAdmin, isMember, isTeamManager} from "../../../functions/authorization/authorization";
import {useMemberState} from "../calendar/days/cel/Hooks";

type props = {
    user: User,
    team: Team,
    member: Member,
}

export default function Account({ user, team, member } : props) {

    // deps
    const appState = useSelector(selectAppState);

    // form states
    const [edit, setEdit] = useState(false);
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);

    const [memberName, setMemberName] = useState(member?.name);
    const [memberEmail, setMemberEmail] = useState(member.email);
    const [memberPhone, setMemberPhone] = useState(member.phone);

    const [updateUserTrigger, {isLoading}] = useUpdateUserMutation();
    const [updateMemberTrigger] = useUpdateMemberMutation();

    const memberState = useMemberState(new Date(), member.id);

    const handleSubmit = () => {
        if(email !== user.email){
            updateUserTrigger({
                id: user.id,
                first_name: firstName,
                last_name: lastName,
                email: email,
            });
        }else{
            updateUserTrigger({
                id: user.id,
                first_name: firstName,
                last_name: lastName,
            });
        }

        updateMemberTrigger({
            id: member.id,
            name: memberName,
            email: memberEmail,
            phone: memberPhone,
        });
    }

    const handleReset = () => {
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setEmail(user.email);
        setMemberName(member.name);
        setMemberEmail(member.email);
        setMemberPhone(member.phone);
        setEdit(false);
    }

    return (
        <>
            <Navigation />
            <section className="mx-auto mt-5 mb-3 xl:w-1200">
                <Table>
                    <Head>
                        <GoBackLink to={getUrl(appState)} title="Terug naar kalender" className="w-3/4 inline-block"/>
                        <div className="w-1/2 inline-block"></div>
                        {!edit &&
                        <div className="w-1/4 inline-flex justify-end">
                            <IconButton icon="edit" color={team?.theme ?? ''} onClick={() => setEdit(true)}>Bewerken</IconButton>
                        </div>
                        }
                        {edit &&
                        <div className="w-1/4 inline-flex justify-end">
                            <SpinnerButton onClick={() => handleSubmit()} isLoading={isLoading}>Opslaan</SpinnerButton>
                            <CancelButton onClick={handleReset} />
                        </div>
                        }
                    </Head>
                    <div className="p-3 pt-0">
                        <Row>
                            <div>
                                <div className="text-2xl text-gray-800 pb-2">Mijn account</div>
                                <div className="text-gray-700 w-9/12">
                                    Deze gegevens behoren tot uw persoonlijk account. Het email
                                    adres wordt bijvoorbeeld gebruikt om uw wachtwoord
                                    opnieuw in te stellen. De gegevens zijn voor niemand
                                    zichtbaar.
                                </div>
                            </div>
                        </Row>

                        <EditRow label="Voornaam" isEdit={edit} value={firstName} setValue={setFirstName} />
                        <EditRow label="Achternaam" isEdit={edit} value={lastName} setValue={setLastName} />
                        <EditRow label="Email" isEdit={edit} value={email} setValue={setEmail} />
                        <EditRow label="Username" isEdit={false} value={user.username} setValue={() => null} />

                        {(isMember(user) || isTeamManager(user) || isAdmin(user)) &&
                        <>
                            <Row>
                                <div>
                                    <div className="text-2xl text-gray-800 pb-2">Gekoppeld teamlid ({member?.name})</div>
                                    <div className="text-gray-700 w-9/12">
                                        Deze gegevens dienen als contactinformatie voor collega's in uw ploeg. Ze
                                        zijn niet verplicht en worden niet gebruikt door het systeem.
                                    </div>
                                </div>
                            </Row>

                            <EditRow label="Naam" isEdit={edit} value={memberName} setValue={setMemberName} />
                            <EditRow label="Email" isEdit={edit} value={memberEmail} setValue={setMemberEmail} />
                            <EditRow label="Telefoon" isEdit={edit} value={memberPhone} setValue={setMemberPhone} />
                            <Row>
                                <div className="w-2/5 lg:w-1/4 inline-block font-bold text-coolgray-800 pt-2 pb-3">Contingenten</div>
                                    <div className="w-1/4 p-2 inline-block border border-white">{memberState.holiday}</div>
                            </Row>                        </>
                        }
                    </div>
                </Table>
            </section>
        </>
    );

}