/* export functions to create options lists --------------------------------------------------------------------------------*/
import {getMonthId, MONTH_NAMES} from "../date";
import {getCompanies, getGroups, getTeams} from "../structure";
import React from "react";

export function getYearOptions(down=10, up=25){
    const d = new Date();
    const y = d.getFullYear();
    const init_year = y - down;
    let years = [];

    for(let i = init_year; i< init_year+up; i++){
        years.push(i);
    }

    return years.map(wrapInOption);
}

export function getDateOptions(year, month){
    const max = new Date(year, month, 0).getDate();
    let days = [];
    for(let i=1; i<max+1; i++){
        days.push(i)
    }

    return days.map(wrapInOption);
}

export function getMonthOptions(){
    return MONTH_NAMES.map((month_name) => {
        return (<option key={getMonthId(month_name)+1} value={getMonthId(month_name)+1}>{month_name}</option>);
    });
}

export function getCompanyOptions(structure)
{
    return getCompanies(structure).map(wrapInOption);
}

export function getTeamOptions(structure, company_id){

    return getTeams(structure, company_id).map(wrapInOption);
}

export function getGroupOptions(structure, team_id){

    return getGroups(structure, team_id)
        // .sort((a, b) => parseInt(a) > parseInt(b)) // sort() does alphabetical
        .map((teamId, i) => [teamId, "groep " + i])
        .map(wrapInOption);

}

export function wrapInOption(value){

    if(Array.isArray(value)){
        return (<option key={value[0]} value={value[0]}>{value[1]}</option>);
    }else {
        return (<option key={value}>{value}</option>);
    }
}