import React from "react";

type props = {
    children: any,
    onClose: (e: any) => void, // TODO: should be click event
}

export default function ModalHeader({children, onClose}: props) {

    return (
        <div className="bg-coolgray-100 p-3">
            <div className="w-10/12 inline-block">
                {children}
            </div>
            <div className="w-2/12 inline-block text-right">
                <i className="material-icons cursor-pointer" onClick={onClose}>close</i>
            </div>
        </div>
    );

}