import React, {useState} from 'react';

import Login from "./form/Login";
import ResetPassword from "./form/ResetPassword";
import ForgotPassword from "./form/ForgotPassword";
import {navigate} from "@reach/router";
import Layout from "./Layout";

export default function Welcome ({loginType, noPath}){

      const [username, setUsername] = useState("");

      let form;
      switch (loginType){
            case "login":
                  form = <Login path="login" setUsername={setUsername} username={username} />;
                  break;

            case "forgot-password":
                  form = <ForgotPassword path="forgot-password" setUsername={setUsername} username={username} />;
                  break;

            case undefined:
                  form = <ResetPassword path="reset-password/:token/:email" setUsername={setUsername} username={username} />;
                  break;

            default:
                  form = <Login path="login" setUsername={setUsername} username={username} />;
                  break;
      }

      if(noPath){
            form = <Login path="login" setUsername={setUsername} username={username} />;
      }

      return (
            <Layout>
                  <div className="mx-auto bg-white w-full sm:w-1/2 lg:w-8/12 xl:w-7/12 px-2 py-3 transition-all">
                        {form}
                  </div>
                  <div className="mx-auto w-full lg:w-8/12 xl:w-7/12 pb-3 lg:px-0 text-md lg:text-lg text-gray-600">
                        <div className="text-center">
                              <div>
                                    {loginType === "login" || noPath
                                        ? <span className="hover:underline hover:text-gray-900" onClick={() => navigate("/forgot-password")}>Wachtwoord vergeten?</span>
                                        : <span className="hover:underline hover:text-gray-900" onClick={() => navigate("/login")}>Terug naar login</span>
                                    }
                              </div>
                        </div>
                  </div>
            </Layout>
      );
}

