import {RegisterRequest, UpdateUserRequest, User} from "./types";
import {api} from "../../../redux/api";

export const usersApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<User[], void>({
            query: () => 'user',
            providesTags: ['Users'],
        }),
        register: builder.mutation<void, RegisterRequest>({
            query: (body) => ({
                url: '/user/register',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Users']
        }),
        create: builder.mutation<void, RegisterRequest>({
            query: (body) => ({
                url: '/user',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Users']
        }),
        updateUser: builder.mutation<void, UpdateUserRequest>({
            query: (body) => ({
                url: '/user/' + body.id,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: ['Users'],
        }),
        deleteUser: builder.mutation<void, number>({
            query: (id) => ({
                url: '/user/' + id,
                method: 'DELETE',
            }),
            invalidatesTags: ['Users'],
        }),
    }),
});

export const {
    useGetUsersQuery,
    useRegisterMutation,
    useCreateMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
} = usersApi;