import axios from "axios";
import {API_URL} from "../../constants";

/**
 * Functions executes POST request to server
 *
 */
export function postToApi (url, data, thenCallback, catchCallback) {

    axios.defaults.withCredentials = true;
    axios.post(`${API_URL}/${url}`, data)
        .then(thenCallback)
        .catch(catchCallback);
}