import React from "react";
import {
    isAdmin,
    isCompanyManager, isGeneralManager, isTeamManager
} from "../../../functions/authorization/authorization";
import {
    ADMIN_LEVEL, CO_ACCOUNT_LEVEL, COMPANY_MANAGEMENT_LEVEL,
    GENERAL_MANAGEMENT_LEVEL, LEVEL_NAMES, MEMBER_LEVEL, TEAM_MANAGEMENT_LEVEL
} from "../../../constants";
import {useSelector} from "react-redux";
import {selectUserState} from "../../../redux/reducers/selectors";

type props = {
    level: number,
    setLevel: (level : number) => void,
}

export default function LevelSelector({level, setLevel} : props) {

    const user = useSelector(selectUserState);

    return (
        <>
        <div className="mb-3 form-row">
            <label>Level</label>
            <select value={level - level%10}
                    className="form-control"
                    onChange={e => setLevel(parseInt(e.target.value))}
            >
                { isAdmin(user)
                    && wrapInOption(CO_ACCOUNT_LEVEL)}

                {wrapInOption(MEMBER_LEVEL)}

                { (isCompanyManager(user) || isAdmin(user) || isTeamManager(user))
                    && wrapInOption(TEAM_MANAGEMENT_LEVEL) }

                { (isGeneralManager(user) || isAdmin(user))
                    && wrapInOption(COMPANY_MANAGEMENT_LEVEL) }

                { isAdmin(user)
                    && wrapInOption(GENERAL_MANAGEMENT_LEVEL) }

                { isAdmin(user)
                    && wrapInOption(ADMIN_LEVEL) }

                {/* note: currently inviting people is only permitted for
                team managers, they can only invite members or co-accounts
                TODO: replace this with centralized auth system
                */}
            </select>
        </div>
        {/* General- or CompanyManagers should never edit rows*/}
        {((MEMBER_LEVEL <= level && level < COMPANY_MANAGEMENT_LEVEL)
            || level >= ADMIN_LEVEL) &&
        <div className="mb-3 form-row">
            <label>Kan rijen van andere teamleden bewerken?</label>
            <select className="form-control" onChange={e => {
                setLevel(level - level%10 + parseInt(e.target.value))
            }}>
                <option value={1} selected={level%10 === 1}>Ja</option>
                <option value={0} selected={level%10 === 0}>Nee</option>
            </select>
        </div>
        }
        </>
    );
}

function wrapInOption(level: number) {

    return (
        <option value={level}>
                {LEVEL_NAMES[level - level%10]}
        </option>
    );

}