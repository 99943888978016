import fetch from "cross-fetch";
import {API_URL} from "../../constants";
import axios from "axios";
import catchAuthError from "../../functions/catchAuthError";

export function fetchSchemes(){
    return function(dispatch){

        return fetch(`${API_URL}/scheme`,
            {
                credentials:"include",
                headers:{
                    "Accept": "application/json",
                }
            })
            .then(response => catchAuthError(response, dispatch))
            .then(
                response => response.json(),
                error => console.log("error eccored when fetching schemes", error)
            )
            .then(
                json => dispatch(receiveSchemes(json))
            );
    }
}

export function createScheme(name, scheme){
    return function (dispatch, getState) {

        const data = {
            name,
            scheme,
        }

        axios.defaults.withCredentials = true;
        axios.post(`${API_URL}/scheme`, data
        ).then((response) => {
            dispatch(fetchSchemes());
        }).catch((response) => {
            console.log(response);
        });
    }
}

export const RECEIVE_SCHEMES = "RECEIVE_SCHEMES";

export function receiveSchemes(schemes){
    return {
        type: RECEIVE_SCHEMES,
        schemes,
    }
}