import {useGetGroupsQuery} from "./groupApi";

export const useGetGroupQuery = (groupId: number) => {
    const {team} = useGetGroupsQuery(undefined, {
        selectFromResult: (({data}) => ({team: data?.find(group => group.id === groupId)}))
    });

    return team;
}

export const useGetGroupsByTeamQuery = (teamId: number) => {
    const {groups} = useGetGroupsQuery(undefined, {
        selectFromResult: (({data}) => ({groups: data?.filter(group => group.team_id === teamId)}))
    });

    return groups;
}