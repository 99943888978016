import React from "react";
import {Link, useLocation} from "@reach/router";

export default function NavLink({to, icon, name})
{

    const match = useLocation();
    const parts = match.pathname.split('/');
    const active = parts[1] === 'dashboard' && parts[2] === to;

    return (
        <Link to={to}>
            <div className={"hover:text-black px-2 mx-2 py-3 mb-1 rounded-lg overflow-hidden hover:bg-coolgray-300" + (active ? "active bg-coolgray-300" : "")}>
                <div className="flex content-center">
                    <i className="material-icons pr-2">{icon}</i>
                    <span>{name}</span>
                </div>
            </div>
        </Link>

    );

}