
export function selectUserState(state){
    return state.user;
}

export function selectStructureState(state){
    return state.structure;
}

export function selectSchemesState(state){
    return state.schemes;
}