import {
    canEditAll, isAdmin,
    isBetween,
    isGeneralManager,
    isMember,
    isTeamManager,
    levelRequired,
    register
} from "./authorization";
import {
    ADMIN_LEVEL, BONUS,
    COMPANY_MANAGEMENT_LEVEL,
    GENERAL_MANAGEMENT_LEVEL, HOLIDAY,
    MEMBER_LEVEL, OCCUPATION, TEAM_MANAGEMENT_LEVEL
} from "../../constants";
import {
    selectAppState,
    selectStatesStates
} from "../../main/reducers/selectors";
import getMenuDate from "../../main/functions/getMenuDate";
import {getMemberState} from "../state";
import {getGroup} from "../structure";
import {selectStructureState} from "../../redux/reducers/selectors";

export const EDIT_CEL_HOLIDAY = "edit-cel-holiday";
export const EDIT_CEL_COMMENT = "edit-cel-comment";
export const EDIT_CEL_BONUS = "edit-cel-bonus";
export const EDIT_CEL_OCCUPATION = "edit-cel-occupation";

export const SEE_TEAM_LIST = "see-team-list";

export const INVITE = "INVITE";
export const ASSIGN_FOR_INVITE = "ASSIGN_FOR_INVITE";
export const CREATE_USER = "CREATE_USER";

export const SEE_COMPANY = "SEE_COMPANY";

export const STRUCTURE_SEE_OTHER_COMPANIES = "STRUCTURE_SEE_OTHER_COMPANIES";
export const STRUCTURE_MANAGE_COMPANIES = "STRUCTURE_MANAGE_COMPANIES";
export const STRUCTURE_CREATE_GROUP = "STRUCTURE_CREATE_GROUP";
export const STRUCTURE_EDIT_TEAM = "STRUCTURE_EDIT_TEAM";

export const SEE_MEMBER_PANEL = "SEE_MEMBER_PANEL";
export const SEE_SCHEMES_PANEL = "SEE_SCHEMES_PANEL";
export const SEE_USERS_PANEL = "SEE_USERS_PANEL";

export const LIST_ALL_COMPANIES = "LIST_ALL_COMPANIES";

export default function registerActions() {

    register("dashboard", levelRequired(TEAM_MANAGEMENT_LEVEL));
    register("approve-holiday", levelRequired(TEAM_MANAGEMENT_LEVEL)); // TODO: only allowed to approve holiday of own team

    // members  can view all holiday and occupation calendars in their company
    //          can only see bonus in own team
    // company/general management can see all calendars
    // mode params must be supplied
    register("view-calendar", (user, state, params) => {

        const ownTeam = parseInt(user.team_id) === parseInt(selectAppState(state).team_id);
        const ownCompany = parseInt(user.company_id) === parseInt(selectAppState(state).company_id);

        // mode not given, false
        if(!params.hasOwnProperty("mode")){
            return false;
        }

        // if invalid mode, false
        if(![HOLIDAY, OCCUPATION, BONUS].includes(params.mode)){
            return false;
        }

        // company management, can see all in own company
        if(isBetween(user, COMPANY_MANAGEMENT_LEVEL, GENERAL_MANAGEMENT_LEVEL) && ownCompany){
            return true;
        }

        // general management can see all
        if(isBetween(user, GENERAL_MANAGEMENT_LEVEL, ADMIN_LEVEL)){
            return true;
        }

        // all other levels must be members

        // if holiday or occupation, true
        if(([HOLIDAY, OCCUPATION].includes(params.mode) && ownCompany)){
            return true;
        }

        // bonus and own team, true
        if(params.mode === BONUS && ownTeam){
            return true;
        }

        // none of above, false
        return false;
    });

    const canEditHolidayOrBonus = (user, state) => {

        // only members can edit
        if(!isBetween(user, MEMBER_LEVEL, COMPANY_MANAGEMENT_LEVEL)){
            return false
        }

        // all normal members can only update their own holiday
        if(user.level % 10 === 0){
            if(parseInt(user.member_id) === parseInt(selectAppState(state).menu_member.member_id)){
                return true;
            }
            return false;
        }

        // super-members can edit all holiday in their team
        if(user.level % 10 === 1){

            // Members can edit all comments in the team they belong to at the date
            // for which the menu was opened
            const menuDate = getMenuDate(selectAppState(state).menu_date);
            const stateAtMenu = getMemberState(menuDate, user.member_id, selectStatesStates(state));
            const group = getGroup(selectStructureState(state), stateAtMenu.group_id);

            if(parseInt(group.team_id) === selectAppState(state).team_id){
                return true;
            }
        }
        return false;
    }

    // for holiday cells, opening menu depends in the first place on the ability
    // to write a comment for that cell
    register(EDIT_CEL_HOLIDAY, canEditHolidayOrBonus);
    register(EDIT_CEL_BONUS, canEditHolidayOrBonus);
    register(EDIT_CEL_OCCUPATION, (user, state) => {

        // Members
        if(isMember(user)){
            // No all-member permissions
            if(user.level % 10 === 0) {
                // Can only edit their own records
                if(parseInt(user.member_id) === parseInt(selectAppState(state).menu_member.member_id)){
                    return true;
                }
            }

            // All-member permissions
            if(user.level % 10 === 1){

                // Members can edit all comments in the team they belong to at the date
                // for which the menu was opened
                const menuDate = getMenuDate(selectAppState(state).menu_date);
                const stateAtMenu = getMemberState(menuDate, user.member_id, selectStatesStates(state));
                const group = getGroup(selectStructureState(state), stateAtMenu.group_id);

                if(parseInt(group.team_id) === selectAppState(state).team_id){
                    return true;
                }
            }
        }

        // Team managers
        if(isTeamManager(user)) {
            // Can edit all records of their own team by default
            // TODO: make this dynamic by reading team_id from state
            if(parseInt(user.team_id) === selectAppState(state).team_id){
                return true;
            }
        }

        return false;
    });

    register(SEE_TEAM_LIST, (user, state) =>
        parseInt(user.team_id) === parseInt(selectAppState(state).team_id)
    );

    register(EDIT_CEL_COMMENT, (user, state) => {

        // only members can edit
        if(!isBetween(user, MEMBER_LEVEL, COMPANY_MANAGEMENT_LEVEL)){
            return false
        }

        // Members can edit all comments in the team they belong to at the date
        // for which the menu was opened
        const menuDate = getMenuDate(selectAppState(state).menu_date);
        const stateAtMenu = getMemberState(menuDate, user.member_id, selectStatesStates(state));
        const group = getGroup(selectStructureState(state), stateAtMenu.group_id);

        if(parseInt(group.team_id) === selectAppState(state).team_id){
            return true;
        }

        return false;
    });

    register("memberlist-companies", levelRequired(GENERAL_MANAGEMENT_LEVEL));
    register("memberlist-teams", levelRequired(COMPANY_MANAGEMENT_LEVEL));
    register("accounts", levelRequired(ADMIN_LEVEL));

    register("approve-month", (user, state) => {
        return (parseInt(user.team_id) === selectAppState(state).team_id && user.level >= TEAM_MANAGEMENT_LEVEL && user.level < COMPANY_MANAGEMENT_LEVEL);

    });

    register("edit-profile", (user, state) =>{
        // only team members are allowed to edit profiles
        if(isBetween(user, MEMBER_LEVEL, COMPANY_MANAGEMENT_LEVEL)){
            if(canEditAll(user) ||  state.edit_member_id === user.member_id){
                return true;
            }
        }
        return false;
    });

    // members can see profile info from all members in their own team
    // company and general management can see all info
    register("see-profile", (user, state) => {
        if(user.level >= COMPANY_MANAGEMENT_LEVEL || parseInt(user.team_id) === selectAppState(state).team_id){
            return true;
        }
        return false;
    });

    // team managers can invite people
    // this auth function is used to decide whether a user can access the create-invite menu, for determining
    // which rights a user can assign, use ASSIGN_FOR_INVITE
    register(INVITE, (user, state) => isTeamManager(user));

    // team managers can invite people for their own team
    // company mangers cannot invite people (question papa)
    // users can invite people only for levels lower or equal to their own
    // rights to cancel an invitations are equal
    register(ASSIGN_FOR_INVITE, (user, state, {levelToAssign, teamToAssign}) => {
        // recommended approach, deny by default, only allow in specified cases
        return mustAllBeTrue([
            (isBetween(user, TEAM_MANAGEMENT_LEVEL, COMPANY_MANAGEMENT_LEVEL)), // admins allowed by default?
            (levelToAssign === null || levelToAssign < user.level),
            (teamToAssign === null || teamToAssign === user.team_id),
        ]);
    });

    // only allow admins to create users manual, managers should use invites
    register(CREATE_USER, (user, state) => {
        return user.level >= ADMIN_LEVEL;
    })

    // general mangers can see all companies
    // team- and company managers can see their own company
    register(SEE_COMPANY, (user, state, {companyId}) => {
        return (
            (isTeamManager(user) && user.company_id === companyId) ||
            (user.level >= GENERAL_MANAGEMENT_LEVEL)
        );
    });

    // only general managers and admins can see other companies and create companies
    register(STRUCTURE_SEE_OTHER_COMPANIES, (user) => {
        return isGeneralManager(user) || isAdmin(user);
    });

    // only general managers and admins can use company actions
    register(STRUCTURE_MANAGE_COMPANIES, (user) => isGeneralManager(user));

    // only team managers can create groups in their own
    register(STRUCTURE_CREATE_GROUP, (user) => isTeamManager(user));

    // only team managers can edit teams
    register(STRUCTURE_EDIT_TEAM, (user) => isTeamManager(user));

    // only team managers can manage schemes
    register(SEE_SCHEMES_PANEL, (user) => isTeamManager(user))

    // only team managers can manage members
    register(SEE_MEMBER_PANEL, (user) => isTeamManager(user))

    // only team managers can manage users
    // TODO: refine this policy to match backend api?
    register(SEE_USERS_PANEL, user => isTeamManager(user));

    // only general managers can list all companies
    register(LIST_ALL_COMPANIES, user => isGeneralManager(user))

}

/**
 * Helper function that requires a list of conditions. It returns true if they all are true.
 * @param conditions
 * @returns {boolean}
 */
export function mustAllBeTrue(conditions) {
    return conditions.filter(cond => cond !== true).length === 0;
}