import {logout} from "../redux/actions/userActions";
import {
    ACTIVITY_CHECK_INTERVAL,
    INACTIVITY_LIMIT,
    LAST_ACTIVITY
} from "../constants";

/**
 * General purpose
 *
 * Functions below are intended to allow a user to manual refresh the page,
 * without losing their logged_in state. Secondly, the user must be logged out
 * if the tab is definitely closed. This is accomplished by using a timer, and
 * an inactivity limit. These settings can be found in constant.js. lastActivity
 * can be saved in cookies or sessions. Sessions cannot be manipulated by the
 * user but are cleared on tab close. The current approach of choice is cookies,
 * in the end the user access is limited by the server, which also has an
 * expiration.
*/


/**
 * Function checks the lastActivity field in the sessionStorage. If it has
 * expired, the current user is logged out. Time settings can be found in
 * constants.js. This function should be called on every page load.
 */
export function logoutIfExpired(store){
    // logout if too long inactive
    if(Date.now() - localStorage.getItem(LAST_ACTIVITY) > INACTIVITY_LIMIT){
        store.dispatch(logout());
    }
}

/**
 * This function sets a repeating timeout for the activityChecker. As long as
 * the site is open the function is called in constant intervals. It just
 * updates the lastActivity field and writes it to the session.
 */
export function setupActivityChecker(){

    const lastActivityUpdater = () => {
        localStorage.setItem("lastActivity", Date.now());
        setTimeout(lastActivityUpdater, ACTIVITY_CHECK_INTERVAL)
    };

    setTimeout(lastActivityUpdater, ACTIVITY_CHECK_INTERVAL)
}

