import {getSchemeIndex} from "../../functions/scheme";
import {SHIFT_SCHEME} from "../../constants";
import {selectStructureState} from "../../redux/reducers/selectors";
import {selectAppState} from "../reducers/selectors";
import {getTeam} from "../../functions/structure";

/**
 *
 * Function returns the shift on the given date in the given scheme based on the given offset. If given scheme is
 * null, default shift_scheme is used.
 */
export default function getShift(date, offset, scheme = null){
    if(scheme == null) {
        scheme = SHIFT_SCHEME;
    }

    return scheme[getSchemeIndex(date, offset)];
}

/**
 *  Returns the offset for the current active team based on the given state.
 *
 * @param state     The current state redux state
 * @returns {*}
 */
export function selectShiftOffset(state){

    const teamId = selectAppState(state).team_id;

    return getTeam(selectStructureState(state), teamId).shift_offset;

}