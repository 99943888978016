import React from "react";
import WarningButton from "../../../components/buttons/WarningButton";

type props = {
    children: any,
    onDelete: () => void,
}

export default function DeleteSection({ children, onDelete}: props) {

    return (
        <section className="p-3 bg-coolgray-100 rounded mb-3">
            <div className="flex justify-center items-center">
                <div className="inline-block">
                    <WarningButton icon="delete" onClick={onDelete}>Verwijder</WarningButton>
                </div>
                <div className="inline-block text-coolgray-600 ml-3">
                    <span className="font-bold">Let op! </span>
                    {children}
                </div>
            </div>
        </section>
    );

}