import React from "react";

type props = {
    children: any,
    color: string,
    onClick: () => void,
}

export default function Button({children, color, onClick} : props) {

    return (
        <div
            className={"py-2 px-3 m-1 rounded text-white font-bold items-center inline-flex cursor-pointer bg-"
                            + color + "-500 hover:bg-" + color + "-600"}
            onClick={onClick}
        >
            {children}
        </div>

    );

}