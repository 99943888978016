import React from "react";
import Row from "../../../components/table/Row";

type props = {
    label: string,
    isEdit: boolean,
    value: string,
    setValue: (value: string) => void,
}

export default function EditRow({label, isEdit, value, setValue} : props) {

    return (
        <Row>
            <div className="w-2/5 lg:w-1/4 inline-block font-bold text-coolgray-800 pt-2 pb-3">{label}</div>
            {isEdit
                ? <input
                    className="border rounded-lg p-2 w-3/5 lg:w-1/3"
                    value={value}
                    onChange={e => setValue(e.target.value)}/>
                : <div className="w-1/4 p-2 inline-block border border-white">{value}</div>
            }
        </Row>
    );

}