import React from "react";
import {selectExtraBorderStyle} from "./Cel";
import {
    selectIsCelActive,
    useIsMemberEnabled,
    useIsWeekendShift
} from "./Hooks";
import {openMenu} from "../../../../actions/appActions";
import {useDispatch, useSelector} from "react-redux";
import {IGNORE_SCHEME_TOKEN, MIN_BEDIENDES} from "../../../../../constants";
import {selectSchemesState} from "../../../../../redux/reducers/selectors";
import {getSchemeIndex} from "../../../../../functions/scheme";
import {selectShiftOffset} from "../../../../functions/getShift";
import {isOperator as isOperatorInState} from "../../../../../functions/state";

export default function Holiday({member, date, memberState, celData, group_id, team, counters}){

    const enabled = useIsMemberEnabled(memberState, group_id);
    const shiftOffset = useSelector(selectShiftOffset);
    const shiftW = useIsWeekendShift(date, shiftOffset);
    const extraStyles = useSelector(selectExtraBorderStyle(date));
    const dispatch = useDispatch();
    const celActive = useSelector(selectIsCelActive(date, member.member_id));
    const weekendStyle = shiftW ? "w-day " : "";
    const activeStyle = celActive ? "bg-" + team.theme + "-200" : "";
    const today = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
    ).getTime() === date.getTime();

    // Operator presence
    const operatorsPresent = counters.bediendes >= MIN_BEDIENDES;
    const isOperator = isOperatorInState(memberState);

    const schemes = useSelector(selectSchemesState);

    if(memberState === undefined){
        return null;
    }

    let content = null;
    let comment;
    let ignore;

    if(isCommentSet(celData) && enabled === true){
        comment = <>
            <span>{ celData.comment }</span>
            <div className="commentmarker"></div>
        </>
    }

    if(!shiftW){
        content = celData.holiday;
        ignore = (content === IGNORE_SCHEME_TOKEN);
    }

    // load custom scheme if no holiday cell in database is empty
    if((celData.holiday === null || celData.holiday === "") && !shiftW && memberState.scheme_id > 1){
        content = schemes[memberState.scheme_id]["scheme"][getSchemeIndex(date, shiftOffset)];
    }

    const handleClick = (e) => {
        const left = e.target.getBoundingClientRect().left;
        const top = e.target.getBoundingClientRect().top;
        dispatch(openMenu(member, date, [left, top]))
    };

    return (
        <div className={
            "cel hover:bg-" + team.theme + "-200"
            + extraStyles + " "
            + weekendStyle + " "
            + ((shiftW && !today) ? 'bg-coolgray-200 ' : '')
            + activeStyle + " "
            + ((isOperator && !operatorsPresent) ? "bg-red-300 " : "")
            }
             onClick={enabled ? handleClick : null}
        >
            {ignore ? "" : content}
            {comment}
        </div>
    );
}

function isCommentSet(celData){

    if(Object.keys(celData).includes("comment")){
        const comment = celData.comment;
        if(comment !== "" && comment !== null){
            return true;
        }
    }
    return false;
}