import React, {useEffect, useState} from "react";
import {useGetUsersQuery, useUpdateUserMutation} from "./usersApi";
import Modal from "../modal/Modal";
import ModalHeader from "../modal/ModalHeader";
import ModalTitle from "../modal/ModalTitle";
import ModalBody from "../modal/ModalBody";
import UserInformation from "./UserInformation";
import SpinnerButton from "../../../components/buttons/SpinnerButton";
import catchLaravelErrors, {
    LaravelErrors
} from "../../../components/inputerrror/catchLaravelErrors";
import LevelSelector from "./LevelSelector";

type props = {
    isOpen: boolean,
    onClose: () => void,
    userId: number,
}

export default function EditUserModal({isOpen, onClose, userId} : props) {

    const {data} = useGetUsersQuery();
    const [updateTrigger, {isLoading, reset}] = useUpdateUserMutation();

    const [errors, setErrors] = useState<LaravelErrors>();

    const user = (data !== undefined) ? data.filter(user => user.id === userId)[0] : null;

    const [firstName, setFirstName] = useState(user !== null ? user.first_name : '');
    const [lastName, setLastName] = useState(user !== null ? user.last_name : '');
    const [email, setEmail] = useState(user !== null ? user.email : '');
    const [level, setLevel] = useState(user !== null ? user.level : 0);

    // state initialisation is not redone when user data comes in ?!
    useEffect(() => {
        if(user !== null) {
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setEmail(user.email);
            setLevel(user.level);
        }
    },[user]);

    const handleSubmit = () => {
        if(user === null) return;

        updateTrigger({
            id: user.id,
            first_name: firstName,
            last_name: lastName,
            level: level,
            // Only send email to backend if it has changed, submitting the
            // same email will result in error because it is not unique
            email: email === user.email ? undefined : email,
        }).unwrap()
        .then(closeAndReset)
        .catch(catchLaravelErrors(setErrors));
    }

    const closeAndReset = () => {
        onClose();
        reset();
        setErrors({});
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={onClose}>
                <ModalTitle>Gebruiker bewerken</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <UserInformation
                    firstName={firstName} setFirstName={setFirstName}
                    lastName={lastName} setLastName={setLastName}
                    email={email} setEmail={setEmail}
                    errors={errors}
                />
                <LevelSelector level={level} setLevel={setLevel} />
                <div className="text-right">
                    <SpinnerButton isLoading={isLoading} onClick={handleSubmit}>Opslaan</SpinnerButton>
                </div>
            </ModalBody>
        </Modal>
    )

}