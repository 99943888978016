import React from "react";
import NavList from "./NavList";
import {useGetTeamsByCompanyQuery} from "../../../../dashboard/components/structure/team/selectors";
import {useSelector} from "react-redux";
import {selectAppState} from "../../../reducers/selectors";

export default function TeamList() {

    const companyId = useSelector(state => selectAppState(state).company_id);
    // this query causes unnecessary rerenders
    const rawTeams = useGetTeamsByCompanyQuery(companyId);
    const teams = rawTeams?.map(team => [team.name, team.id]);

    if(rawTeams === undefined)
        return null;

    return (
        <NavList type="team_id" title="Ploeg" items={teams} />
    )
}