
/**
 * Returns a new function that executes the given callback when enter is pressed. This should be used in a form context
 * where the e param is automatically passed.
 */
export function onEnter (callback) {

    return (e) => {
        if (e.keyCode === 13) {
            callback();
        }
    }

}