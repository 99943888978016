
export type LaravelErrors = {
    [key: string]: string
}

export default function catchLaravelErrors(setErrors: (errors: any) => void) {

    return (response: any) => {
        if(response.status === 422) {
            setErrors(response.data.errors);
        }
    }

}