import pad from "./pad";

export const MONTH_NAMES = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"];
export const MONTH_NAMES_SHORT = ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sept", "okt", "nov", "dec"];

export const DAY_NAMES_SHORT = ["zon", "ma", "di", "woe", "do", "vrij", "zat"];

export function getMonthName(id){
    return MONTH_NAMES[id];
}

export function getMonthId(name){

    for(let i = 0; i < 13; i++){
        if(MONTH_NAMES[i] === name){
            return i;
        }
    }
    return false;
}

export function plusOneMonth(date){
    let result = new Date(date.getTime());
    result.setMonth(result.getMonth() + 1);
    return result;
}

export function plusOndeDay(date){
    let result = new Date(date.getTime());
    result.setDate(result.getDate() + 1);
    return result;
}

export function formatUrl(date){
    return date.getFullYear() + "-" + pad(date.getMonth()+1, 2) + "-" + pad(date.getDate(), 2);
}

export function format(date){
    return date.getFullYear() + "-" + pad(date.getMonth()+1, 2) + "-" + pad(date.getDate(), 2);
}

export function formatHumanShort(date){
    return date.getDate() + " " + MONTH_NAMES_SHORT[date.getMonth()] + " " + date.getFullYear();
}

export function getTomorrow(date){

    let nextDate = new Date(date.getTime());
    nextDate.setDate(date.getDate() + 1);
    return nextDate;
}