import React from "react";

export type Tab = {
    value: string,
    action: () => void,
    active: boolean,
}

type props = {
    tabs: Tab[],
}

export default function Tabs({tabs}: props) {

    return (
        <div className="h-11 bg-coolgray-100 ">
            {tabs.map(({value, action, active}) =>
                <div
                    className={"w-28 align-top h-full rounded-tl rounded-tr cursor-pointer justify-center items-center inline-flex hover:white text-gray-600 font-semibold" + (active ? " bg-white" : "")}
                    onClick={action}
                    key={value}
                >
                    <span>{value}</span>
                </div>
            )}
        </div>
    );
}