import React from "react";
import {Link} from "@reach/router";
import InsertDate from "./InsertDate";
import {openEditRow} from "../../../actions/membersListActions";
import {useDispatch} from "react-redux";

export default function EditHeader({member}){

    const dispatch = useDispatch();

    return (
        <div className="edit-header">
            <div className="name">
                <Link to={"/dashboard/member/" + member.member_id}>{member.name}</Link>
            </div>
            <InsertDate member={member}/>
            <div className="icons actions children-right ">
                <i className={"material-icons"} onClick={() => dispatch(openEditRow(member.null))}>close</i>
            </div>
        </div>
    );

}