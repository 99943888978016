import React from "react";
import {Link} from "@reach/router";

type props = {
    to: string,
    title: string,
    className: string,
}

export default function GoBackLink({to, title, className}: props) {

    return (
        <div className={className}>
            <Link to={to}>
                <div className="flex items-center text-coolgray-600">
                    <i className="material-icons">arrow_left</i>
                    <span>{title}</span>
                </div>
            </Link>
        </div>
    );

}