import React from 'react';
import {useSelector} from "react-redux";
import {selectAppState, selectRecordsState} from "../../../reducers/selectors";
import Day from "./Day";
import pad from "../../../../functions/pad";
import {useGetTeamQuery} from "../../../../dashboard/components/structure/team/selectors";

export default function Days () {

    const year = useSelector(state => selectAppState(state).year);
    const month = useSelector(state => selectAppState(state).month);
    const date =  new Date(Date.UTC(year, parseInt(month)-1, 1));
    let days_in_month = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
    const records = useSelector(selectRecordsState);

    // calculate once here for performance
    const teamId = useSelector(state => selectAppState(state).team_id)
    const team = useGetTeamQuery(teamId);

    let days = new Array(days_in_month).fill(1);

    if(team === undefined) {
        return null;
    }

    return (
        <>
        {days.map( (value, i) => {

                i+=1;
                let date_obj = new Date(date.getFullYear(), date.getMonth(), i);
                let datestring = year + "-" + pad(month, 2) + "-" + pad(i, 2);

                const input_data = (datestring in records) ? records[datestring] : [];

                return (<Day key={i}
                             date={date_obj}
                             data={input_data}
                             team={team}
                        />
                );
        })}
        </>
    );
}