import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectMembersListState} from "../../../reducers/selectors";
import {
    getCompanyOptions,
    getDateOptions,
    getMonthOptions,
    getTeamOptions,
    getYearOptions
} from "../../../../functions/forms/options";
import {selectStructureState, selectUserState} from "../../../../redux/reducers/selectors";
import {changeCompany, changeDate, changeInsertDate, changeTeam} from "../../../actions/membersListActions";
import {useSetOptionDefault} from "../../../../functions/forms/controls";
import {getTeams} from "../../../../functions/structure";
import {Link} from "@reach/router";
import {isAllowed} from "../../../../functions/authorization/authorization";

export default function ControlBar(){

    const completeState = useSelector(state => state);
    const state = useSelector(selectMembersListState);
    const user = useSelector(selectUserState);
    const structure = useSelector(selectStructureState);
    const dispatch = useDispatch();
    const className = "form-control form-control-sm date";

    const date = new Date(state.date);

    useSetOptionDefault("control-bar-year", date.getFullYear());
    useSetOptionDefault("control-bar-month", date.getMonth() + 1);
    useSetOptionDefault("control-bar-date", date.getDate());
    // keep state, otherwise after every rerender, the selectors are reset
    useSetOptionDefault("company", state.company_id ?? user.company_id);
    useSetOptionDefault("team", state.team_id ??user.team_id);

    const getFirstTeam = (company_id) => {
        const teams = getTeams(structure, company_id);
        if(teams.length === 0){
            return null
        }
        return teams[0][0];
    };

    const onChangeDate = (e) => {
        dispatch(changeDate(date.getFullYear(), date.getMonth()+1, e.target.value));
        dispatch(changeInsertDate(date.getFullYear(), date.getMonth()+1, e.target.value));
    };

    const onChangeMonth = (e) => {
        dispatch(changeDate(date.getFullYear(), e.target.value, date.getDate()));
        dispatch(changeInsertDate(date.getFullYear(), e.target.value, date.getDate()));
    };

    const onChangeYear = (e) => {
        dispatch(changeDate(e.target.value, date.getMonth()+1, date.getDate()));
        dispatch(changeInsertDate(e.target.value, date.getMonth()+1, date.getDate()));
    };

    return (
        <section className="box" id="member-control-bar">
            {isAllowed(completeState, "memberlist-companies") ?
                <>
                <div className="w-1/12 inline-block label">company</div>
                <div className="w-2/12 inline-block">
                         <select className="form-control form-control-sm"
                                id="company"
                                onChange={e => {
                                    dispatch(changeCompany(e.target.value));
                                    dispatch(changeTeam(getFirstTeam(e.target.value)));
                                }
                                }
                        >
                            {getCompanyOptions(structure)}
                        </select>
                </div>
                </>
                : <div className="w-3/12 inline-block"></div>
                }
            {isAllowed(completeState, "memberlist-teams") ?
                <>
                <div className="w-1/12 inline-block label">team</div>
                <div className="w-2/12 inline-block">
                    <select className="form-control form-control-sm"
                            id="team"
                            onChange={e => dispatch(changeTeam(e.target.value))}
                    >
                        {getTeamOptions(structure, state.company_id)}
                    </select>
                </div>
                </>
                : <div className="w-3/12 inline-block"></div>
            }

            <div className="w-1/12 inline-block label">date</div>
            <div className="w-4/12 inline-block">
                    <select className={className} id="control-bar-date" onChange={onChangeDate}>
                        {getDateOptions(date.getFullYear(), date.getMonth()+1)}
                    </select>
                    <select className={className} id="control-bar-month" onChange={onChangeMonth}>
                        {getMonthOptions()}
                    </select>
                    <select className={className} id="control-bar-year" onChange={onChangeYear}>
                        {getYearOptions()}
                    </select>
            </div>

            <div className="w-1/12 inline-block children-right">
                <Link className="btn btn-success" to="new">
                    <i className="material-icons custom">add</i>
                    <span>new</span>
                </Link>
            </div>

        </section>
    );
}