import fetch from "cross-fetch";
import {API_URL} from "../../constants";

export function fetchMembers(){

    return function(dispatch){

        // this is crappy, but it selects quite effectively all states, to much work in the backend to support period-less fetching for now
        return fetch(`${API_URL}/member-all`,
            {
                credentials:"include",
                headers:{
                    "Accept": "application/json",
                }
            })
            .then(
                response => response.json(),
                error => console.log("error occurred when fetching members", error)
            )
            .then(
                json => dispatch(receiveMembers(json))
            );
    }

}

export const RECEIVE_MEMBERS = "RECEIVE_MEMBERS_DASH";

export function receiveMembers(members){

    return {
        type: RECEIVE_MEMBERS,
        members,
    }
}