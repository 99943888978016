import {useGetTeamsQuery} from "./teamApi";

export const useGetTeamQuery = (teamId: number) => {
    const {team} = useGetTeamsQuery(undefined, {
        selectFromResult: (({data}) => ({team: data?.find(team => team.id === teamId)}))
    });

    return team;
}

export const useGetTeamsByCompanyQuery = (companyId: number) => {
    const {teams} = useGetTeamsQuery(undefined, {
        selectFromResult: ({data}) => ({
            // the .filter and .sort mutate the data and trigger rerenders
            // see issue #
            teams: data?.filter(team => team.company_id === companyId)
                 .sort((a, b) => a.name > b.name ? 1 : -1)
        })
    });

    return teams;
}