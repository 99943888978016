import React from "react";
import {useSelector} from "react-redux";
import {selectSchemesState} from "../../../../redux/reducers/selectors";
import {getSchemeAbbr} from "../../../../functions/scheme";

export default function StateFields({state}){

    const functions = [null, "bediende", "arbeider", "assistent", "ploegleider"];
    const toggleIcons = ["close", "check"];

    const schemes = useSelector(selectSchemesState);
    return (
        <>
            <div className="function">
                {functions[state.function]}
            </div>
            <div className={"education " + state.education}>
                {state.education}
            </div>
            <div className="scheme">
                {state.scheme_id > 1 ? getSchemeAbbr(schemes[state.scheme_id]["scheme"]) : "" }
            </div>
            <div className="icons occupation">
                <i className={"material-icons " + toggleIcons[state.occupation_counter]}>
                    {toggleIcons[state.occupation_counter]}
                </i>
            </div>
            <div className="icons holiday">
                <i className={"material-icons " + toggleIcons[state.holiday_counter]}>
                    {toggleIcons[state.holiday_counter]}
                </i>
            </div>
            <div className="holiday-days">
                {state.holiday}
            </div>
        </>
    );
}