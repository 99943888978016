import fetch from "cross-fetch";
import {API_URL} from "../../constants";
import axios from "axios";
import catchAuthError from "../../functions/catchAuthError";
import {fetchStates} from "../../main/actions/stateActions";
import {selectMembersListState} from "../reducers/selectors";
import {fetchCareers} from "./careersActions";

export const CHANGE_INSERT_DATE = "CHANGE_INSERT_DATE";

export function changeInsertDate(year, month, day){
    return {
        type: CHANGE_INSERT_DATE,
        date: year + "-" + (parseInt(month)).pad(2) + "-" + parseInt(day).pad(2),
    };
}

export const OPEN_EDIT_ROW = "OPEN_EDIT_ROW";

export function openEditRow(member_id){
    return {
      type: OPEN_EDIT_ROW,
      member_id: parseInt(member_id),
    };
}

export const CHANGE_DATE = "MEMBERLIST_CHANGE_DATE";

export function changeDate(year, month, day){
    return {
        type:CHANGE_DATE,
        date:year + "-" + (parseInt(month)).pad(2) + "-" + parseInt(day).pad(2),
    };
}

export const CHANGE_COMPANY = "MEMBERLIST_CHANGE_COMPANY";

export function changeCompany(company_id){

    return {
        type: CHANGE_COMPANY,
        company_id,
    };
}

export const CHANGE_TEAM = "MEMBERLIST_CHANGE_TEAM";

export function changeTeam(team_id){
    return {
        type: CHANGE_TEAM,
        team_id,
    }
}


export const RECEIVE_MEMBERS_LIST = "RECEIVE_MEMBERS_LIST";

export function receiveMembersList(members)
{
    return {
        type: RECEIVE_MEMBERS_LIST,
        members,
    }
}

export function fetchMembersList(company_id, date)
{

    return function(dispatch){

        const today = new Date(date);
        const tomorrow = new Date();
        tomorrow.setFullYear(today.getFullYear());
        tomorrow.setMonth(today.getMonth());
        tomorrow.setDate(today.getDate()+1);

        return fetch(`${API_URL}/member?company_id=${company_id}&start=${today.getFullYear()}-${(today.getMonth()+1).pad(2)}-${today.getDate().pad(2)}&end=${tomorrow.getFullYear()}-${(tomorrow.getMonth()+1).pad(2)}-${tomorrow.getDate().pad(2)}`,
            {
                credentials:"include",
                headers:{
                    "Accept": "application/json",
                }
            })
            .then(response => catchAuthError(response, dispatch))
            .then(
                (response) => {
                    if(response.status === 200) {
                        return response.json();
                    }else {
                        return false;
                    }
                },
                error => console.log("error occurred when fetching members", error)
            )
            .then(
                (json) => {
                    if(json !== false) {
                        dispatch(receiveMembersList(json));
                    }else{
                    }
                },
                error => console.log("error occurred when fetching members", error)

            )
            .catch(
                (error) => console.log(error)
            )
    }
}

export function createMember(name, groupId, inviteEmail){

    return function(dispatch, getState) {
        axios.defaults.withCredentials = true;

        const data = {
            name:name,
            group_id: groupId,
            email: inviteEmail,
        }

        axios.post(`${API_URL}/member`, data
        ).then((response) => {
            dispatch(fetchMembersList(selectMembersListState(getState()).company_id, selectMembersListState(getState()).date));
            dispatch(fetchStates(selectMembersListState(getState()).team_id, new Date(selectMembersListState(getState()).date).getFullYear(), null));
            dispatch(fetchCareers(selectMembersListState(getState()).team_id));
            console.log("posted");
        }).catch((response) => {
            console.log(response);
        });
    }
}