import React from "react";
import {
    LaravelErrors
} from "../../../components/inputerrror/catchLaravelErrors";
import InputError from "../../../components/inputerrror/InputError";

type props = {
    username: string,
    setUsername: (s:string) => void,
    password: string,
    setPassword: (s:string) => void,
    errors?: LaravelErrors,
}

export default function UserCredentials(
    {
        username, setUsername,
        password, setPassword,
        errors = {}
    }: props) {

    return (
        <>
            <div className="form-row mb-3">
                <label>Gebruikersnaam</label>
                <div className="input-group">
                    <input type="text" className="form-control" id="username" placeholder="alaindv12" value={username} onChange={e => setUsername(e.target.value)} required/>
                    <InputError name="username" errors={errors} />
                </div>
            </div>
            <div className="form-row mb-3">
                <label>Wachtwoord</label>
                <div className="input-group">
                    <input type="password" className="form-control" id="password" value={password} onChange={e => setPassword(e.target.value)} required/>
                    <InputError name="password" errors={errors} />
                </div>
            </div>
        </>
    );

}