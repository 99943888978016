import {selectAppState, selectMembersState, selectRecordsState, selectStatesStates} from "../../../reducers/selectors";
import {selectStructureState} from "../../../../redux/reducers/selectors";
import {getMemberState, isOperator} from "../../../../functions/state";
import {getGroups} from "../../../../functions/structure";
import {getCelData} from "./cel/Hooks";
import {
    HOLIDAY_MAIN_BUTTONS,
    HOLIDAY_SEC_BUTTONS,
    IGNORE_SCHEME_TOKEN,
} from "../../../../constants";
import {isCustomSchemeActive} from "../../../../functions/scheme";

export default function selectCounters(date) {

    return function (state) {

        const structure = selectStructureState(state);
        const teamId = selectAppState(state).team_id;
        const records = selectRecordsState(state);
        const members = selectMembersState(state);

        let groups = {};
        let dayTotal = 0;
        let bediendes = 0;
        let bediendesTotal = 0;

        // Init groups
        // Note: this has to be done explicitly in advance because it is
        // possible a group does not get initiated by the loop below. For
        // example when it only contains moved members.
        Object.entries(members).forEach(([groupId, group]) => {
            groups[groupId] = {present: 0, total: 0};
        });

        // Loop groups for this month
        Object.entries(members).forEach(([groupId, group]) => {
            Object.values(group).forEach(member => {

                const mId = member.member_id;
                const states = selectStatesStates(state);
                const memberState = getMemberState(date, mId, states);
                const belongsToGroup = parseInt(memberState.group_id) === parseInt(groupId);
                const belongsToTeam = getGroups(structure, teamId).includes(memberState.group_id);
                const enabled = parseInt(memberState.enabled) === 1
                const occupationCounterEnabled = parseInt(memberState.occupation_counter) === 1;

                // Only enabled members, with enabled occupation counting and
                // currently belonging to this group are considered
                if (!belongsToTeam
                    || !belongsToGroup
                    || !enabled
                    || !occupationCounterEnabled
                ) {
                    return;
                }

                // MemberState can be null if records of old members are
                // floating around in the database
                if (memberState !== null) {

                    const currentGroup = memberState.group_id;

                    // Always increment totals
                    groups[currentGroup].total += 1;
                    if (isOperator(memberState)) {
                        bediendesTotal += 1;
                    }

                    const record = getCelData(records, new Date(date), mId);

                    // If present, increment present counters
                    if (
                        !HOLIDAY_MAIN_BUTTONS.concat(HOLIDAY_SEC_BUTTONS).includes(record.holiday)
                        && (!isCustomSchemeActive(state, mId, new Date(date))
                            || record.holiday === IGNORE_SCHEME_TOKEN)
                    ) {

                        // Update present counters
                        groups[currentGroup].present += 1;
                        dayTotal += 1;

                        if (isOperator(memberState)) {
                            bediendes += 1;
                        }
                    }
                }
            });
        });

        return {
            ...groups,
            total: dayTotal, // Total present (TODO: rename this)
            bediendes: bediendes, // Total present bediendes
            bediendesTotal: bediendesTotal, // Total active bediendes in team
        };
    }
}