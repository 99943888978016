import {api} from "../../../../redux/api";
import {Group, CreateGroupRequest, UpdateGroupRequest} from "./types";

export const groupApi = api.injectEndpoints({
    endpoints: (build) => ({
        getGroups: build.query<Group[], void>({
            query: () => 'group',
            providesTags: ['Groups'],
        }),
        addGroup: build.mutation<void, CreateGroupRequest>({
            query: (group) => ({
                url: '/group',
                method: 'POST',
                body: group,
            }),
            invalidatesTags: ['Groups']
        }),
        updateGroup: build.mutation<void, UpdateGroupRequest>({
            query: (group) => ({
                url: '/group/' + group.id,
                method: 'PUT',
                body: group,
            }),
            invalidatesTags: ['Groups']
        }),
        deleteGroup: build.mutation<void, number>({
            query: (id) => ({
                url: '/group/' + id,
                method: 'DELETE',
            }),
            invalidatesTags: ['Groups'],
        }),
    })
});

export const {
    useGetGroupsQuery,
    useAddGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
} = groupApi;