import React from "react";
import {LEVEL_NAMES} from "../../../constants";
import Row from "../../../components/table/Row";
import {Invite} from "./types";
import {useDeleteInviteMutation} from "./invitesApi";
import {useGetMemberQuery} from "../members/selectors";

type props = {
    invite: Invite,
}

export default function InviteRow({invite}: props){

    const [deleteTrigger, {isLoading}] = useDeleteInviteMutation();
    const member = useGetMemberQuery(invite.member_id)

    return (
        <Row key={invite.id}>
            <div className="w-1/5 inline-block">{invite.email}</div>
            <div className="w-1/5 inline-block">{member?.name}</div>
            <div className="w-1/5 inline-block">{LEVEL_NAMES[parseInt((invite.level / 10).toString()) * 10]}</div>
            <div className="w-1/5 inline-block">{invite.date}</div>
            <div className="w-1/10 inline-block">
                {invite.accepted
                    ? <span className="block rounded-full px-2 py-1 bg-lime-200">Aanvaard</span>
                    : <span className="block rounded-full px-2 py-1 bg-coolgray-200">In afwachting</span>
                }
            </div>
            <div className="w-1/10 inline-block mx-auto">
                {!isLoading && <i className="material-icons cursor-pointer text-gray-600 opacity-0 group-hover:opacity-100" onClick={() => deleteTrigger(invite.id)}>delete</i>}
                {isLoading &&
                <div style={{width: '24px', height: '24px'}}>
                            <span style={{width: '20px', height: '20px'}}
                                  className="spinner-border spinner-border-sm mr-2"
                                  role="status" aria-hidden="true"> </span>
                </div>
                }
            </div>
        </Row>
    );
}