import fetch from "cross-fetch";
import {API_URL} from "../../constants";
import {formatUrl, plusOneMonth} from "../../functions/date";
import catchAuthError from "../../functions/catchAuthError";

export const RECEIVE_MEMBERS = "RECEIVE_MEMBERS";

export function receiveMembers(team_id, members)
{
    return {
        type: RECEIVE_MEMBERS,
        team_id,
        members,
    }
}

export function fetchMembers(team_id, year, month)
{

    return function(dispatch){

        const start = new Date(year, month-1, 1);
        const end = plusOneMonth(start);

        return fetch(`${API_URL}/member?team_id=${team_id}&start=${formatUrl(start)}&end=${formatUrl(end)}`,
            {
              credentials:"include",
                headers:{
                    "Accept": "application/json",
                }
            })
            .then(response => catchAuthError(response, dispatch))
            .then(
                (response) => {
                    if(response.status === 200) {
                        return response.json();
                    }else {
                        return false;
                    }
                },
                error => console.log("error occurred when fetching members", error)
            )
            .then(
                (json) => {
                    if(json !== false) {
                        dispatch(receiveMembers(team_id, json));
                    }else{
                    }
                },
                error => console.log("error occurred when fetching members", error)

           )
            .catch(
                (error) => console.log(error)
            )
    }
}