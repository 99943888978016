import React from "react";

type props = {
    children: any,
    padding?: boolean,
}

export default function Box({children, padding}: props) {
    return (
        <section className={"shadow-sm overflow-hidden rounded-lg bg-white mb-3 border" + (padding ? " p-3" : "")}>{children}</section>
    );
}