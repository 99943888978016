import {RECEIVE_SCHEMES} from "../actions/schemeActions";

export default function schemeReducer(state={}, action){

    switch(action.type){

        case RECEIVE_SCHEMES:
            return action.schemes;

        default:
            return state;
    }
}