import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ActionBar from "../../../components/actionbar/ActionBar";
import UserList from "./UserList";
import InviteList from "./InviteList";
import {CREATE_USER, INVITE} from "../../../functions/authorization/actions";
import {isAllowed} from "../../../functions/authorization/authorization";
import IconButton from "../../../components/buttons/IconButton";
import {fetchMembers} from "../../actions/memberActions";
import AddInviteModal from "./AddInviteModal";
import AddUserModal from "./AddUserModal";

export const USERS = "USERS";
export const INVITES = "INVITES"

export default function Accounts({activeTab} : {activeTab: string}){

    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [tab, setTab] = useState(activeTab === undefined ? USERS : activeTab);

    const [addInviteModalOpen, setAddInviteModalOpen] = useState(false);
    const [addUserModalOpen, setAddUserModalOpen] = useState(false);

    const tabs = [
        {value:'Accounts', action: () => setTab(USERS), active: (tab === USERS),},
        {value:'Uitnodigingen', action: () => setTab(INVITES), active: (tab === INVITES),},
    ];

    useEffect(() => {
        dispatch(fetchMembers());
    }, [dispatch]);

    return (
        <>
        <ActionBar tabs={tabs}>
            {isAllowed(state, CREATE_USER) &&
            <IconButton icon="add" color="lime" onClick={() => setAddUserModalOpen(true)}>Account</IconButton>
            }
            {isAllowed(state, INVITE) &&
            <IconButton icon="add" color="lime" onClick={() => setAddInviteModalOpen(true)}>Uitnodiging</IconButton>
            }
        </ActionBar>

        <AddInviteModal isOpen={addInviteModalOpen} onClose={() => setAddInviteModalOpen(false)} />
        <AddUserModal isOpen={addUserModalOpen} onClose={() => setAddUserModalOpen(false)} />

        <div className="mb-5">
            {tab === USERS && <UserList /> }
            {tab === INVITES && <InviteList /> }
            {/*TODO: replace this with router?*/}
        </div>
        </>
    );
}
