import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {API_URL} from "../constants";

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        credentials: "include",
        mode: "cors",
        prepareHeaders: (headers) => {
            // IMPORTANT for laravel, is this is not set, weird cors errors appear
            headers.set('Content-Type', 'application/json');
            headers.set('Accept', 'application/json');
            return headers;
        },
    }),
    endpoints: () => ({}),
    tagTypes: ['Invites', 'Users', 'Companies', 'Teams', 'Groups', 'Members']
})