import fetch from "cross-fetch";
import {API_URL} from "../../constants";
import axios from "axios";
import {selectMembersListState} from "../reducers/selectors";
import catchAuthError from "../../functions/catchAuthError";
import {fetchStates} from "../../main/actions/stateActions";
import {fetchMembersList} from "./membersListActions";

export function fetchCareers(team_id){

    return function(dispatch){

        // this is crappy, but it selects quite effectively all states, to much work in the backend to support period-less fetching for now
        return fetch(`${API_URL}/state?team_id=${team_id}&start=2010-01-01&end=2100-01-01`,
            {
                credentials:"include",
                headers:{
                    "Accept": "application/json",
                }
            })
            .then(response => catchAuthError(response, dispatch))
            .then(
                response => response.json(),
                error => console.log("error occurred when fetching states", error)
            )
            .then(
                json => dispatch(receiveCareers(json))
            );
    }

}

export const RECEIVE_CAREERS = "RECEIVE_CAREERS";

export function receiveCareers(careers){

    return {
        type: RECEIVE_CAREERS,
        careers,
    }
}

export const ADD_STATE = "ADD_STATE";

export function addState(member_id, data){
    return function (dispatch, getState) {
        axios.defaults.withCredentials = true;

        axios.post(`${API_URL}/state`, data
        ).then((response) => {
            // These are needed to keep member-panel in sync
            dispatch(fetchCareers(selectMembersListState(getState()).team_id));
            dispatch(fetchStates(selectMembersListState(getState()).team_id, new Date(selectMembersListState(getState()).date).getFullYear(), null));
            dispatch(fetchMembersList(selectMembersListState(getState()).company_id, selectMembersListState(getState()).date));

            console.log("posted");
        }).catch((response) => {
            console.log(response);
        });
    }
}