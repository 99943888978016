import React from 'react';
import {useSelector} from "react-redux";
import {selectAppState} from "../../../reducers/selectors";
import {BONUS} from "../../../../constants";
import {isAllowed} from "../../../../functions/authorization/authorization";

export default function Member({member}) {

    const canSeeProfile = useSelector(state => isAllowed(state, "see-profile"));
    const mode = useSelector(state => selectAppState(state).mode);

    let empty = ""
    if (mode === BONUS) {
        empty = <div className="cel user"></div>
    }

    if (canSeeProfile) {
        return (
            <>
                <div className="cel user overflow-hidden">{member.name}</div>
                {empty}
            </>
        );
    }else{
        return (
            <>
                <div className="cel user">{member.name}</div>
                {empty}
            </>
        );
    }

}
