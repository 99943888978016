import {WORKER, ASSISTANT_TEAM_LEADER, OPERATOR, TEAM_LEADER} from "../constants";
/**
 * Function to quickly get a date object with time, and timezones set to zero. It only contains year, month and date.
 */
export function getCleanDate(date){

    const d = new Date(date);
    return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
}

/**
 * Function takes date, memberId and the list of all states, it returns the active state for that member on the given
 * date. Note: A state is already active on it's start_date.
 */
export function getMemberState(date, memberId, states) {

    if(!Object.keys(states).includes(memberId.toString())){
        return false;
    }

    let memberStates = Object.values(states[memberId]);

    // keep all states that start before given date (starting on given date is also allowed)
    const preStates = memberStates.filter(state => getCleanDate(state.start_date) <= getCleanDate(date.toString()));

    // sort descending on start date
    preStates.sort((a, b) => (getCleanDate(a.start_date) < getCleanDate(b.start_date)) ? 1 : -1);
    memberStates.sort((a, b) => (getCleanDate(a.start_date) > getCleanDate(b.start_date)) ? 1 : -1);

    // first state in list now is the last started state before given date, which we want to return
    // if start state is in future, return it as the current state
    return preStates[0] ?? memberStates[0];
}

/**
 * Function checks if a given condition is true for some state in the period defined by start and end parameters.
 *
 * @param condition     A function that receives a memberState as argument and returns true if the condition is met,
 *                      false, otherwise.
 * @param start
 * @param end
 * @param memberStates
 */
export function isInPeriod(condition, start, end, memberStates){

    const states = Object.values(memberStates);
    let enteringState = false;

    for(let i = 0; i < states.length; i++){

        // make sure no timezones are added
        const current = states[i];
        const d = new Date(current.start_date);
        const currentStart = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

        // if state start before period, update enteringState
        // note: first day of period counts as entering state
        if(currentStart <= start){
            enteringState = condition(current);

        // if state starts in period
        // note: it is allowed to start on the last day of the period
        }else if(currentStart <= end){
            if (condition(current)) return true;
        }
    }

    // no matching states found that start in the period
    // entering state is our last chance
    return enteringState;
}

export function isArbeiderInPeriod(start, end, memberStates){

    return isInPeriod(state => parseInt(state.function) === WORKER, start, end, memberStates);
}

/**
 * Functions returns true if member is operator(bediende) in given state, false otherwise.
 *
 * @param state
 */
export function isOperator(state) {

    return [OPERATOR, ASSISTANT_TEAM_LEADER, TEAM_LEADER].includes(parseInt(state.function));

}