import React from "react";
import Table from "../../../components/table/Table";
import Row from "../../../components/table/Row";
import Head from "../../../components/table/Head";
import {useGetMembersByTeamQuery} from "../../../dashboard/components/members/selectors";
import {useSelector} from "react-redux";
import {selectUserState} from "../../../redux/reducers/selectors";

export default function TeamList(){

    const user = useSelector(selectUserState);
    const members = useGetMembersByTeamQuery(user.team_id);

    return (
        <>
            <main>

                <section className="mx-auto mt-5 mb-3" style={{width: '1200px'}}>
                    <Table>
                        <Head>
                            <div className="w-1/4">Naam</div>
                            <div className="w-1/4">Email</div>
                            <div className="w-1/4">Telefoon</div>
                        </Head>
                        {members?.map((member) => (
                            <Row key={member?.id}>
                                <div className="w-1/4">{member?.name}</div>
                                <div className="w-1/4">{member?.email}</div>
                                <div className="w-1/4">{member?.phone}</div>
                            </Row>
                        ))}
                    </Table>
                </section>

            </main>

        </>
    );
}
