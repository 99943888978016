import React from "react";
import {useDispatch} from "react-redux";
import {openAddCompanyModal} from "../structurePanelSlice";
import { useGetCompaniesQuery} from "./companyApi";

type props = {
    companyId: number,
    setCompanyId: (id: number) => void,
}

export default function CompanySelectorTabs({ companyId, setCompanyId }: props) {

    const dispatch = useDispatch();

    const {data, isLoading} = useGetCompaniesQuery();

    if(isLoading || data === undefined)
        return (<div>loading ... </div>);

    return (
        <div className="h-11 bg-coolgray-100 ">
            {data.map(({id, name}) =>
                <div
                    className={"w-36 align-top h-full rounded-tl rounded-tr cursor-pointer justify-center items-center inline-flex hover:bg-white text-gray-600 font-semibold" + ((id === companyId) ? " bg-white" : "")}
                    onClick={e => setCompanyId(id)}
                    key={id}
                >
                    <span>{name}</span>
                </div>
            )}
            <div
                className="align-top h-full rounded-tl rounded-tr justify-center cursor-pointer items-center px-4 inline-flex hover:bg-white text-gray-600 font-semibold"
                onClick={e => dispatch(openAddCompanyModal())}
            >
                <i className="material-icons">add</i>
            </div>
        </div>
    );

}