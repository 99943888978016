import React from "react";
import IconButton from "./IconButton";

type props = {
    children: any,
    icon: string,
    onClick: () => void,
}

export default function WarningButton({children, icon, onClick}: props) {

    return (
        <IconButton icon={icon} color="red" onClick={onClick}>
            {children}
        </IconButton>
    );

}