import React, {useState} from "react";
import Modal from "../modal/Modal";
import ModalHeader from "../modal/ModalHeader";
import ModalTitle from "../modal/ModalTitle";
import ModalBody from "../modal/ModalBody";
import {useAddInviteMutation} from "./invitesApi";
import MemberSelector from "./MemberSelector";
import SpinnerButton from "../../../components/buttons/SpinnerButton";
import InputError from "../../../components/inputerrror/InputError";
import catchLaravelErrors, {
    LaravelErrors
} from "../../../components/inputerrror/catchLaravelErrors";
import {MEMBER_LEVEL} from "../../../constants";

type props = {
    isOpen: boolean,
    onClose: () => void,
}

export default function AddInviteModal({isOpen, onClose} : props) {

    const [email, setEmail] = useState('');
    const [memberId, setMemberId] = useState<number>(0);
    const [level, setLevel] = useState<number>(MEMBER_LEVEL);

    const [errors, setErrors] = useState<LaravelErrors>();

    const [addInviteTrigger, {isLoading, reset}] = useAddInviteMutation();

    const  handleSubmit = () => {

        addInviteTrigger({
            email,
            member_id: memberId,
            level,
        }).unwrap()
        .then(() => closeAndReset())
        .catch(catchLaravelErrors(setErrors));
    }

    const closeAndReset = () => {
        onClose();
        reset();
        setErrors({});
        setEmail('');
        setMemberId(0);
        setLevel(MEMBER_LEVEL);
    }

    return (
        <Modal size="small" isOpen={isOpen} onClose={onClose}>
            <ModalHeader onClose={closeAndReset}>
                <ModalTitle>Nieuwe uitnodiging</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className="clear-both">
                    <div className="mb-3 form-row">
                        <label>Email</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">@</span>
                            </div>
                            <input type="email" className="form-control" id="email" placeholder="alain@hotmail.com" onChange={e => setEmail(e.target.value)} required />
                            <InputError name={"email"} errors={errors} />
                        </div>
                    </div>
                </div>
                <div className="block">
                    <MemberSelector member={memberId} setMember={setMemberId} level={level} setLevel={setLevel} />
                </div>
                <div className="text-right">
                    <SpinnerButton isLoading={isLoading} onClick={handleSubmit}>Verzenden</SpinnerButton>
                </div>
            </ModalBody>

        </Modal>
    )

}