import {api} from "../../../../redux/api";
import {Company, CreateCompanyRequest} from "./types";

export const companyApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCompanies: build.query<Company[], void>({
            query: () => 'company',
            providesTags: ['Companies'],
        }),
        addCompany: build.mutation<void, CreateCompanyRequest>({
            query: (company) => ({
                url: '/company',
                method: 'POST',
                body: company,
            }),
            invalidatesTags: ['Companies']
        }),
        updateCompany: build.mutation<void, Company>({
            query: (company) => ({ // TODO: how to get a second parameter here
                url: '/company/' + company.id,
                method: 'PUT',
                body: company,
            }),
            invalidatesTags: ['Companies']
        }),
        deleteCompany: build.mutation<void, number>({
            query: (id) => ({
                url: '/company/' + id,
                method: 'DELETE',
            }),
            invalidatesTags: ['Companies'],
        }),
    })
});

export const {
    useGetCompaniesQuery,
    useAddCompanyMutation,
    useUpdateCompanyMutation,
    useDeleteCompanyMutation,
} = companyApi;