import React, {useState} from "react";
import Modal from "../../modal/Modal";
import {useDispatch, useSelector} from "react-redux";
import ModalHeader from "../../modal/ModalHeader";
import ModalBody from "../../modal/ModalBody";
import {selectStructurePanelState} from "../../../reducers/selectors";
import {closeAddGroupModal} from '../structurePanelSlice';
import ModalTitle from "../../modal/ModalTitle";
import MinGroupOccupation from "./MinGroupOccupation";
import ConfirmButton from "../../../../components/buttons/ConfirmButton";
import {useGetTeamQuery} from "../team/selectors";
import {useAddGroupMutation} from "./groupApi"
import {fetchStructure} from "../../../../redux/actions/structureActions";

export default function AddGroupModal() {

    const modalState = useSelector(state => selectStructurePanelState(state).addGroupModal);
    const [occupation, setOccupation] = useState(2);
    const [addTrigger] = useAddGroupMutation();
    const dispatch = useDispatch();

    const team = useGetTeamQuery(modalState.teamId);

    if(modalState.active === false){
        return null;
    }

    const doCreate = () => {
        addTrigger({
            team_id: modalState.teamId,
            min_occupation: occupation,
        }).then(() => {
            // TODO: remove this when member-panel is not dependant on
            // structure anymore
            dispatch(fetchStructure());
        });
        dispatch(closeAddGroupModal());
    }

    return (
        <Modal>
            <ModalHeader onClose={e => dispatch(closeAddGroupModal())}>
                <ModalTitle>Voeg groep toe aan <span className="italic">{team?.name}</span></ModalTitle>
            </ModalHeader>
            <ModalBody>
                <MinGroupOccupation value={occupation} setValue={setOccupation} />
                <div className="text-right">
                    <ConfirmButton onClick={doCreate} />
                </div>
            </ModalBody>
        </Modal>
    );

}