import fetch from "cross-fetch";
import {API_URL} from "../../constants";
import catchAuthError from "../../functions/catchAuthError";

export const RECEIVE_STRUCTURE = "RECEIVE_STRUCTURE";

export function receiveStructure(structure){

    return {
        type:RECEIVE_STRUCTURE,
        structure,
    }
}

export function fetchStructure(){

    return function(dispatch){

        return fetch(`${API_URL}/structure`,
            {
                credentials:"include",
                headers:{
                    "Accept": "application/json",
                }
            })
            .then(response => catchAuthError(response, dispatch))
            .then(
                response => response.json(),
                error => console.log("error eccored when fetching structure", error)
            )
            .then(
                json => dispatch(receiveStructure(json))
            );
    }


}