import {
    selectAppState, selectRecordsState, selectStatesStates
} from "../../../reducers/selectors";
import getStateDate from "../../../functions/getStateDate";
import {getMemberState} from "../../../../functions/state";

/**
 * This function extracts the yearly counters from the state three. It counts
 * all 8's and x's for all members over all records and subtracts this amount
 * from the holiday value in the career state that was active on january first,
 * of the current active year.
 */
export function selectHolidayCounters(state) {

    // make sure this data is available for the whole year!
    const records = selectRecordsByYear(state, selectAppState(state).year);
    const memberStates = selectStatesStates(state);

    // set the date to january first of the active year
    const date = getStateDate(selectAppState(state));
    date.setMonth(0, 1);

    // init
    let counters = {};

    // loop all records
    Object.values(records).forEach( days => {

        Object.values(days).forEach( cel => {

            const holiday = cel.holiday !== null ? cel.holiday.replace(' ', '') : '';

            // init start value
            if (!(cel.member_id in counters)) {
                const memberState = getMemberState(date, cel.member_id, memberStates);
                if(memberState !== null) {
                    // TODO: make this impossible by checks on incoming data please
                    counters[cel.member_id] = memberState.holiday ?? 78;
                }
            }

            // decrement by one if holiday taken
            if(["x", "8"].includes(holiday)) {
                counters[cel.member_id] -= 1;
            }

        })
    });

    return counters;
}

/**
 * This function selects all records from the state three matching the given
 * year.
 */
function selectRecordsByYear(state, year){

    const records = selectRecordsState(state);

    return Object.fromEntries(
        Object.entries(records)
            .filter( entry => new Date(entry[0]).getFullYear() === year)
    );

}