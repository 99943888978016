import React from "react";

type props = {
    children: any,
}

export default function Table({children}: props) {

    return (
        <div className="bg-white mt-3 border shadow-sm rounded-lg overflow-hidden">
            {children}
        </div>
    );

}
