import React from "react";
import {
    selectIsCelActive,
    useIsPresent,
    useIsWeekendShift
} from "./Hooks";
import {getExtraBorderStyles} from "./Cel";
import {useDispatch, useSelector} from "react-redux";
import {openMenu} from "../../../../actions/appActions";
import {selectShiftOffset} from "../../../../functions/getShift";

export default function Bonus({member, date, celData, team}){

    const state = useSelector(state => state);
    const shiftOffset = useSelector(selectShiftOffset)
    const shiftW = useIsWeekendShift(date, shiftOffset);
    const extraStyles = getExtraBorderStyles(date, state);
    const dispatch = useDispatch();
    const celActive = useSelector(selectIsCelActive(date, member.member_id));
    const weekendStyle = shiftW ? " w-day " : "";
    const activeStyle = celActive ? " bg-" + team.theme + "-200" : "";
    const isPresent = useIsPresent(celData, member.member_id, date);
    const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime() === date.getTime();

    let oor;
    let vuil;
    if(!shiftW) {
        if (celData !== null) {
            if(!isPresent) {
                return (<div>
                        <div className={"cel whitespace " + extraStyles}></div>
                        <div className={"cel whitespace " + extraStyles}></div>
                    </div>)
            }else{
                oor = parseInt(celData.oor) !== 0 ? celData.oor : null;
                vuil = parseInt(celData.vuil) !== 0 ? celData.vuil : null;
            }
        }
    }

    const handleClick = (e) => {
        const left = e.target.getBoundingClientRect().left;
        const top = e.target.getBoundingClientRect().top;
        dispatch(openMenu(member, date, [left, top]))
    };

    return (
        <div
            className={"hover:bg-" + team.theme + "-200"}
             onClick={isPresent && !shiftW ? handleClick : null}
        >

            <div className={"cel " + extraStyles +  weekendStyle + ((shiftW && !today) ? ' bg-coolgray-200 ' : '') + activeStyle}>{vuil}</div>
            <div className={"cel " + extraStyles +  weekendStyle + ((shiftW && !today) ? ' bg-coolgray-200 ' : '') + activeStyle}>{oor}</div>
        </div>
    );
}