import {
    CHANGE_COMPANY,
    OPEN_EDIT_ROW,
    RECEIVE_MEMBERS_LIST,
    CHANGE_TEAM,
    CHANGE_DATE,
    CHANGE_INSERT_DATE
} from "../actions/membersListActions";
import {format} from "../../functions/date"

export default function membersListReducer(state=null, action){

    if(state === null){
        return {
            members: {},
            date: format(new Date()),
            view_date: format(new Date()),
            team_id:null,
            company_id:null,
            edit_member_id:null,
            insert_date:format(new Date()),
        };
    }

    switch(action.type){

        case RECEIVE_MEMBERS_LIST:
            return Object.assign({}, state, {members: action.members});

        case OPEN_EDIT_ROW:
            return Object.assign({}, state, {edit_member_id:parseInt(action.member_id)});

        case CHANGE_DATE:
            return Object.assign({}, state, {date: action.date});

        case CHANGE_TEAM:
            return Object.assign({}, state, {team_id: parseInt(action.team_id)});

        case CHANGE_COMPANY:
            return Object.assign({}, state, {company_id: parseInt(action.company_id), team_id:null})

        case CHANGE_INSERT_DATE:
            return Object.assign({}, state, {insert_date: action.date})
        default:
            return state;
    }

}

