import {useSelector} from "react-redux";
import {
    useGetTeamQuery
} from "../../../../dashboard/components/structure/team/selectors";
import {
    selectAppStateWithoutMenu,
    selectMembersState
} from "../../../reducers/selectors";

export function useSelectMembers(){

    const teamId = useSelector(state => selectAppStateWithoutMenu(state).team_id)
    const team = useGetTeamQuery(teamId);
    const members = useSelector(selectMembersState);

    if(team === undefined)
        return [];

    // Filter out General Member group
    return Object.fromEntries(
        Object.entries(members).filter(([groupId, members]) => {

            // Does this group contains the General Member?
            const isGeneralMemberGroup = Object.values(members)
                .some(member => member.id === team.general_member_id);

            // If info_row disabled, filter out
            if(team.info_row === 0
                && Object.values(members).length > 0
                && isGeneralMemberGroup
            ){
                return false;
            }

            return true;
        })
    );
}