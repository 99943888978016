import React from 'react'
import Cel from './cel/Cel'
import {useSelector} from "react-redux";
import {selectAppState, selectStatesStates} from "../../../reducers/selectors";
import {HOLIDAY} from "../../../../constants";
import sortMembersByFunction from "../../../../functions/sortMembersByFunction";
import getStateDate from "../../../functions/getStateDate";
import {
    useGetGroupQuery
} from "../../../../dashboard/components/structure/group/selectors";

export default function CelGroup({members, date, group_id, counters, team}){

    const mode = useSelector(state => selectAppState(state).mode);
    const group = useGetGroupQuery(parseInt(group_id));
    const states = useSelector(selectStatesStates);
    const appDate = useSelector(state => getStateDate(selectAppState(state)),
        (a, b) => a.getFullYear() === b.getFullYear()
                                && a.getMonth() === b.getMonth()
                                && a.getDate() === b.getDate());

    // If data is not yet available, assume present
    let isAbsent = false;

    if(group_id in counters) {
        isAbsent = counters[group_id].present < group?.min_occupation;
    }

    const absentStyle = isAbsent && mode === HOLIDAY ? "absent-group": null;
    return (
        <div className={"group " + absentStyle}>
            <div className="cel whitespace-small"></div>
            {Object.values(members)
                .sort(sortMembersByFunction(states, appDate))
                .map(member => {
                return (<Cel key={member.member_id}
                             group_id={group_id}
                             member={member}
                             date={date}
                             mode={mode}
                             counters={counters}
                             team={team}
                />)
            })}
        </div>
    );
}
