import {selectCareersState} from "../../reducers/selectors";
import {getMemberState} from "../../../functions/state";
import getStateDate from "../../../main/functions/getStateDate";
import {selectAppState} from "../../../main/reducers/selectors";
import {ASSISTANT_TEAM_LEADER, TEAM_LEADER} from "../../../constants";
import {useGetGroupsByTeamQuery} from "./group/selectors";
import {useSelector} from "react-redux";

export function selectMembersByGroup(state) {

    const groups = {};
    const date = getStateDate(selectAppState(state));

    Object.keys(selectCareersState(state)).forEach( memberId => {

        const current = getMemberState(date, memberId, selectCareersState(state));

        if(current === null || current.enabled === 0){
            return;
        }

        if(!(current.group_id in groups)) {
            groups[current.group_id] = [];
        }

        groups[current.group_id].push(memberId);

    })

    return groups;
}


export function useSelectAssistantTeamLeader(teamId) {
    return useSelectFirstX(teamId, current => current.function === ASSISTANT_TEAM_LEADER);
}

export function useSelectTeamLeader(teamId) {
    return useSelectFirstX(teamId, current => current.function === TEAM_LEADER);
}

function useSelectFirstX(teamId, check) {

    const state = useSelector(state => state);
    const groups = useGetGroupsByTeamQuery(teamId);
    const groupIds = groups.map(group => group.id);

    // loop all members by their memberId
    for(const memberId in selectCareersState(state)){

        // find current state, active today
        const current = getMemberState(new Date(), memberId, selectCareersState(state));

        // if state valid and check matches -> return memberId
        if(current !== null && current !== undefined) { // TODO: remove selectStructureState call because the old state is not updated on CreateTeam which causes errors
            if (check(current) && groupIds.includes(current.group_id)) {
                return current.member_id;
            }
        }
    }
}




