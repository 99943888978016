
// api
export const API_URL = process.env.REACT_APP_API_URL;

// permissions
export const CO_ACCOUNT_LEVEL = 10;
export const MEMBER_LEVEL = 20;
export const TEAM_MANAGEMENT_LEVEL = 30;
export const COMPANY_MANAGEMENT_LEVEL = 40;
export const GENERAL_MANAGEMENT_LEVEL = 50;
export const ADMIN_LEVEL = 60;

export const LEVEL_NAMES : {[level: number]: string} = {
    10: "Co-account",
    20: "Ploeglid",
    30: "Ploegleiding",
    40: "Bedrijfsmanagement",
    50: "Algemeen management",
    60: "Administator"
};

// syncing records settings
export const SYNC = process.env.REACT_APP_RECORDS_SYNC === 'true';
export const SYNC_INTERVAL = 15000;

// inactivity settings
export const ACTIVITY_CHECK_INTERVAL = 1*1000
export const INACTIVITY_LIMIT = 2*60*1000
export const LAST_ACTIVITY = "lastActivity";

// modes
export const HOLIDAY = "holiday";
export const OCCUPATION = "occupation";
export const BONUS = "bonus";
export const VUIL = "vuil";
export const OOR = "oor";

// education
export const EDUCATIONS = [
    "PL", "APL", // ploegleiding
    "SC", "RC", "PC", // cordinator
    "sb", "rb", "pb", // bord
    "su", "ru", "pu", // buiten
    "so", "ro", "po", // opleiding/overschot
    "-",
];

// occupation
// Note: see also OccupationMenu.js to include these buttons
export const OCCUPATIONS = [
    "PL", "APL", // ploegleiding
    "SC", "RC", "PC", // cordinator
    "SVC", "RVC", "PVC", // vervanger
    "SB", "RB", "PB", // bord
    "su", "ru", "pu", // buiten
    "so", "ro", "po", // opleiding/overschot
    "sbo", 'rbo', 'pbo',
];

// holiday
export const HOLIDAY_MAIN_BUTTONS = ["x", "L", "Z",  "SW"]
export const HOLIDAY_SEC_BUTTONS = ["h1", "h2", "BAO", "VAP", "8", "VWB", "EOP", "HOV", "KV", "AZC", "MPD"];

// functions
export const OPERATOR = 1;
export const WORKER = 2;
export const ASSISTANT_TEAM_LEADER = 3;
export const TEAM_LEADER = 4;

export const MIN_BEDIENDES = 2;

// schemes
export const IGNORE_SCHEME_TOKEN = "-";

export const SHIFT_SCHEME = [
    'V', 'V', 'L', 'L', 'N', 'N', 'N', 'W', 'W',
    'V', 'V', 'L', 'L', 'L', 'N', 'N', 'W', 'W',
    'V', 'V', 'V', 'L', 'L', 'N', 'N', 'W', 'W', 'W'
];