import React, {useState} from "react";
import Modal from "../modal/Modal";
import ModalTitle from "../modal/ModalTitle";
import ModalBody from "../modal/ModalBody";
import UserInformation from "./UserInformation";
import UserCredentials from "./UserCredentials";
import MemberSelector from "./MemberSelector";
import {getDefaultMember} from "../../../functions/structure";
import {useCreateMutation} from "./usersApi";
import SpinnerButton from "../../../components/buttons/SpinnerButton";
import catchLaravelErrors, {
    LaravelErrors
} from "../../../components/inputerrror/catchLaravelErrors";
import ModalHeader from "../modal/ModalHeader";
import {MEMBER_LEVEL} from "../../../constants";

type props = {
    isOpen: boolean,
    onClose: () => void,
}

export default function AddUserModal({isOpen, onClose} : props) {

    // Component states
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [member, setMember] = useState(getDefaultMember);
    const [level, setLevel] = useState(MEMBER_LEVEL);

    const [errors, setErrors] = useState<LaravelErrors>();

    const [triggerCreateMutation, {isLoading, reset}] = useCreateMutation();

    const handleSubmit = () => {
        triggerCreateMutation({
            first_name: firstName,
            last_name: lastName,
            email: email,
            username: username,
            password: password,
            member_id: member,
            level: level,
        }).unwrap()
        .then(() => closeAndReset())
        .catch(catchLaravelErrors(setErrors));
    }

    const closeAndReset = () => {
        onClose();
        reset();
        setErrors({});
        setFirstName('');
        setLastName('');
        setEmail('');
        setUsername('');
        setPassword('');
        setMember(getDefaultMember)
        setLevel(MEMBER_LEVEL)
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="medium">
            <ModalHeader onClose={onClose} >
                <ModalTitle>Nieuwe gebruiker</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <div className="inline-block pr-5 w-1/2">
                    <UserInformation
                        firstName={firstName} setFirstName={setFirstName}
                        lastName={lastName} setLastName={setLastName}
                        email={email} setEmail={setEmail}
                        errors={errors}
                    />
                    <UserCredentials
                        username={username}
                        setUsername={setUsername}
                        password={password}
                        setPassword={setPassword}
                        errors={errors}
                    />
                </div>

                <div className="inline-block w-1/2">
                    <MemberSelector
                        member={member}
                        setMember={setMember}
                        level={level}
                        setLevel={setLevel}
                    />
                </div>
                <div className="text-right">
                    <SpinnerButton isLoading={isLoading} onClick={handleSubmit}>Opslaan</SpinnerButton>
                </div>
            </ModalBody>
        </Modal>
    );

}