import React from "react";
import packageJson from "../../../../package.json";
import "./footer.css";

export default function Footer(){

    return (
        <footer className="bg-coolgray-100 border-t z-50 hidden xl:inline">
            <div className="w-1/3 inline-block">v{packageJson.version + " " + packageJson.versionName}</div>
            <div className="w-1/3 inline-block"></div>
            <div className="w-1/3 inline-block text-right">webmaster@lactam.be</div>
        </footer>
    )
}