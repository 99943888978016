import React from "react";

type props = {
    value: number,
    setValue: (value: number) => void,
}

export default function MinGroupOccupation({value, setValue} : props){

    return (
        <div className="mb-3">
            <label>Minimum bezetting</label>
            <select className="custom-select" id="min-occupation" defaultValue={value} onChange={e => setValue(parseInt(e.target.value))}>
                {
                    Array(10).fill(0).map((v, i) =>
                        <option key={i}>{i}</option>
                    )
                }
            </select>
        </div>
    );
}