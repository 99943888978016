import {CreateMemberRequest, UpdateMemberRequest, Member} from "./types";
import {api} from "../../../redux/api";

export const memberApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getMembers: builder.query<Member[], void>({
            query: () => 'member-all', // TODO
            providesTags: ['Members'],
        }),
        createMember: builder.mutation<void, CreateMemberRequest>({
            query: (body) => ({
                url: '/member',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Members']
        }),
        updateMember: builder.mutation<void, UpdateMemberRequest>({
            query: (body) => ({
                url: '/member/' + body.id,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: ['Members'],
        }),
    }),
});

export const {
    useGetMembersQuery,
    useCreateMemberMutation,
    useUpdateMemberMutation,
} = memberApi;