import React from 'react';
import { Link } from "@reach/router";
import getUrl from "../../../functions/getUrl";
import {selectAppStateWithoutMenu} from "../../../reducers/selectors";
import {shallowEqual, useSelector} from "react-redux";
import NavListWrapper from "./NavListWrapper";
import NavListTitle from "./NavListTitle";
import NavListItem from "./NavListItem";

export default function NavList({type, title, items}) {

    const appState = useSelector(selectAppStateWithoutMenu, shallowEqual);
    const currentState = appState[type];

    return (
        <>
            <NavListWrapper>
                <NavListTitle>{title}</NavListTitle>
                    {
                        Object.values(items).map(value => (
                            <Link
                                to={getUrl(appState, {[type]: value[1]})}
                                key={value}
                            >
                                <NavListItem active={value[1] === currentState}>{value[0]}</NavListItem>
                            </Link>
                        ))
                    }
            </NavListWrapper>
        </>
    )

}
