import {createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import {rootReducer as mainReducer } from '../../main/reducers/rootReducer'
import {rootReducer as dashboardReducer} from "../../dashboard/reducers/rootReducer";
import { composeWithDevTools } from 'redux-devtools-extension';
import userReducer from "../reducers/userReducer";
import structureRedcucer from "../reducers/structureReducer";
import schemeReducer from "../reducers/schemeReducer";
import {api} from "../api";

const rootReducer = ( state={}, action ) => {
    return {
        main: mainReducer(state.main, action, state),
        dashboard: dashboardReducer(state.dashboard, action),
        user: userReducer(state.user, action),
        structure: structureRedcucer(state.structure, action),
        schemes: schemeReducer(state.schemes, action),
        api: api.reducer(state.api, action),
    }
};

export  function configureStore(preloadedState={}) {
    return createStore(rootReducer,
                        preloadedState,
                        composeWithDevTools(applyMiddleware(thunkMiddleware, api.middleware)),
                    );
}