import {CreateInviteRequest, Invite} from "./types";
import {api} from "../../../redux/api";

export const invitesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getInvites: builder.query<Invite[], void>({
            query: () => 'invite',
            providesTags: ['Invites'],
        }),
        addInvite: builder.mutation<void, CreateInviteRequest>({
            query: (invite) => ({
                url: '/invite',
                method: 'POST',
                body: invite,
            }),
            invalidatesTags: ['Invites'],
        }),
        deleteInvite: builder.mutation<void, number>({
            query: (id) => ({
                url: '/invite/' + id,
                method: 'DELETE',
            }),
            invalidatesTags: ['Invites'],
        }),
    }),
});

export const {
    useGetInvitesQuery,
    useAddInviteMutation,
    useDeleteInviteMutation,
} = invitesApi;