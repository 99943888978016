import React from "react";
import {useDispatch} from "react-redux";
import {HOLIDAY, IGNORE_SCHEME_TOKEN} from "../../../../constants";
import {useSetCheckBoxDefault} from "../../../../functions/forms/controls";
import {setRecord} from "../../../actions/recordActions";
import getMenuDate from "../../../functions/getMenuDate";
import {getSchemeAbbr, useGetScheme} from "../../../../functions/scheme";

export default function SchemeControl({appState, celData}){

    const dispatch = useDispatch();
    const scheme = useGetScheme(appState.menu_member.member_id, getMenuDate(appState.menu_date));

    // a custom scheme can only be visible (=active) when their is noting set in the database for holiday field
    let toggle = (celData.holiday === "" || celData.holiday === null);
    useSetCheckBoxDefault("scheme", toggle);

    const onToggle = (e) => {

        let record;
        if(!toggle){
            record = ""; // PROBLEM, sending empty strings does not come throug on server side
                            // should implement beter system to set a cel as empty
        }else{
            record = IGNORE_SCHEME_TOKEN;
        }
        toggle = !toggle;
        dispatch(setRecord(HOLIDAY, getMenuDate(appState.menu_date), appState.menu_member, record));
    };

    return (
        <div className="w-full flex content-center p-2" id="scheme-control">
            <div className="w-1/2 inline-block">{scheme["name"]} ({getSchemeAbbr(scheme["scheme"])})</div>
            <div className="w-1/2 inline-block  toggle-wrapper">
                <input className={"toggle"} type="checkbox" id={"scheme"} onChange={e => onToggle(e)} />
                <div className={"toggle"}>
                    <label htmlFor={"scheme"}></label>
                </div>
            </div>
        </div>
    );
}