import React from "react";

export default function MainLoading () {

    return (
        <div className="flex justify-center items-center w-full h-screen">

            <section className="mx-auto w-full md:w-8/12 lg:w-6/12 xl:w-2/12">
                <img src="/img/icons/factory-192.png" className="px-16 mx-auto" alt="background"/>
                <div className="text-4xl text-center mt-5 text-coolgray-600">Bijna klaar.</div>
            </section>

        </div>
    );

}