import {RECEIVE_STATES} from "../actions/stateActions";

export default function statesReducer(state={}, action){

    switch(action.type){
        case RECEIVE_STATES:
            return action.states;

        default:
            return state;
    }

}