import React from "react";
import Button from "./Button";

type props = {
    onClick: () => void,
    isLoading: boolean,
    children: any,
    color?: string,
}

export default function SpinnerButton ({onClick, isLoading, children, color="lime"} : props){

    return (
        <Button onClick={onClick} color={color}>
            { !isLoading && <i className="material-icons mr-2 text-white">done</i>}
            { isLoading && <span style={{width: '23px', height: '23px'}} className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
            <span>{children}</span>
        </Button>
    );

}