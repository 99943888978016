import React from 'react';
import Group from './Group';
import {useSelector} from 'react-redux'
import {selectAppState} from "../../../reducers/selectors";
import {BONUS} from "../../../../constants";
import {getGroup} from "../../../../functions/structure";
import {selectStructureState} from "../../../../redux/reducers/selectors";
import {MONTH_NAMES} from "../../../../functions/date";
import {useSelectMembers} from "../days/useSelectMembers";

export default function MemberColumn(){

    const structure = useSelector(selectStructureState);
    const members = useSelectMembers();
    const team_id = useSelector(state => selectAppState(state).team_id);
    const mode = useSelector(state => selectAppState(state).mode);
    const month = useSelector(state => selectAppState(state).month);

    let list = [];
    //loop groups
    for(const [id, group] of Object.entries(members)){

        if(parseInt(getGroup(structure, id).team_id) === parseInt(team_id)) {
            list.push(
                <Group key={id} members={group}/>
            );
        }
    }

    let team_occupation = "";
    if(mode !== BONUS){
        team_occupation = (
            <div className="group">
                <div className="cel whitespace-small"></div>
                <div className="cel user overflow-hidden">Ploegbezetting</div>
            </div>
        );
    }

    return (
        <>
            <div className="member-column column bg-white sticky lg:relative left-0 z-30">
                <div className="cel head bg-white sticky lg:relative top-0 z-50 flex items-center justify-center font-bold">
                    <div>{MONTH_NAMES[month-1]}</div>
                </div>
                {list}
                {team_occupation}
            </div>
        </>
    );
}

