import React from 'react';
import {saveState, syncUserApp} from "./localStorage";
import {fetchStructure} from "../actions/structureActions";
import {fetchSchemes} from "../actions/schemeActions";
import registerActions from "../../functions/authorization/actions";
import {selectSchemesState, selectStructureState} from "../reducers/selectors";
import {selectCareersState} from "../../dashboard/reducers/selectors";
import {logoutIfExpired, setupActivityChecker} from "../../functions/activity";
import {Provider} from "react-redux";
import {store} from "./store";

export default function StoreProvider ({ children }) {

    // init store
    syncUserApp(store);

    // fetch some general data
    store.dispatch(fetchStructure());
    store.dispatch(fetchSchemes());

    // register auth actions
    registerActions();

    store.subscribe(() => {
        saveState({
            user:store.getState().user, // comment this line in order to logout when closing or reloading the page
            structure: selectStructureState(store.getState()),
            schemes:selectSchemesState(store.getState()),
            dashboard:{
                careers: selectCareersState(store.getState()),
            },
            // api: {
            //     queries: {
            //         'getTeams(undefined)': {
            //             data: store.getState().api.queries['getTeams(undefined)'].data,
            //         }
            //     }
            // }
        });
    });

    // inactivity system
    logoutIfExpired(store);
    setupActivityChecker();

    return (
        <Provider store={store} >
            { children }
        </Provider>
    );

}