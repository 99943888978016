import {fetchTeamRecords} from "./recordActions";
import {fetchStates} from "./stateActions";
import {fetchMembers} from "./memberActions";
import {selectAppState} from "../reducers/selectors";

export const CHANGE_DATE = "CHANGE_DATE";
export const CHANGE_TEAM = "CHANGE_TEAM";
export const CHANGE_MODE = "CHANGE_MODE";
export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";
export const CHANGE_COMPANY = "CHANGE_COMPANY";
export const RECEIVED_EXPIRED = "RECEIVED_EXPIRED";
export const RESET_EXPIRED = "RESET_EXPIRED";

export function changeDate(year, month, refetch=true)
{
    // this is important
    // otherwise comparing problems will happen like this 12 == "12" -> fail
    // also, we need an int to be able to pad it for printing in urls
    year = parseInt(year);
    month = parseInt(month);

    return function(dispatch, getState){

        const team_id = selectAppState(getState()).team_id;

        dispatch({
            type: CHANGE_DATE,
            year,
            month
        });

        if(selectAppState(getState()).menu_active) {
            dispatch(closeMenu());
        }

        if(refetch) {
            dispatch(fetchStates(team_id, year, month));
            dispatch(fetchMembers(team_id, year, month));
            dispatch(fetchTeamRecords(team_id, year, month));
        }
    }
}

export function changeCompany(company_id, refetch=true)
{

    return function(dispatch, getState){
        dispatch({
            type: CHANGE_COMPANY,
            company_id,
        });

        if(selectAppState(getState()).menu_active) {
            dispatch(closeMenu());
        }

    }
}

export function changeTeam(team_id, refetch=true)
{

    return function(dispatch, getState){

        //first thing, edit app state
        dispatch({
            type: CHANGE_TEAM,
            team_id
        });

        if(selectAppState(getState()).menu_active) {
            dispatch(closeMenu());
        }

        if(refetch) {

            const app = selectAppState(getState());

            dispatch(fetchStates(team_id, app.year, app.month));
            dispatch(fetchMembers(team_id, app.year, app.month));
            dispatch(fetchTeamRecords(team_id, app.year, app.month));
        }
    }
}

export function changeMode(mode){
    return function(dispatch, getState) {
        dispatch( {
            type: CHANGE_MODE,
            mode
        })
        if(selectAppState(getState()).menu_active) {
            dispatch(closeMenu());
        }
    }
}

export function openMenu(member, date, corner){
    return {
        type: OPEN_MENU,
        member,
        date,
        corner,
    }
}

export function closeMenu(){
    return {
        type: CLOSE_MENU
    }
}

export function receivedExpired(){
    return {
        type: RECEIVED_EXPIRED,
    }
}

export function resetExpired(){
    return {
        type: RESET_EXPIRED,
    }
}