import React from "react";

type Sizing = "small" | "medium" | "large";

type props = {
    children: any,
    size?: Sizing,
    isOpen?: boolean,
    onClose?: () => void,
}

// TODO: make isOpoen and onClose mandatory
// need to change the state mechanism of the modals in the structure panel
// onClose is currently located in the params of ModalHeader, not ideal
export default function Modal({
        children,
        isOpen = true, // TODO: should not have a default
        onClose = () => null,
        size="small"
    } : props){

    if(!isOpen)
        return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center
        bg-gray-500/50">
            <div className={"bg-white border shadow z-50 relative rounded " +
                "overflow-hidden " + getSizing(size)}>
                {children}
            </div>
        </div>
    );

}

function getSizing(size: Sizing) {

    // TODO: make sizing more response for large monitors
    if(size === "small") {
        return "w-1/3";
    }

    if(size === "medium") {
        return "w-1/2";
    }

    if(size === "large") {
        return "w-full mx-5"
    }
}