import React from "react";
import getShift, {selectShiftOffset} from "../../../functions/getShift";
import {selectExtraBorderStyle} from "./cel/Cel";
import {useSelector} from "react-redux";
import selectCounters from "./selectCounters";
import {selectAppStateWithoutMenu} from "../../../reducers/selectors";
import {
    useGetTeamQuery
} from "../../../../dashboard/components/structure/team/selectors";

export default function CounterCel({date}){

    const counters = useSelector(selectCounters(date), (a, b) => JSON.stringify(a) === JSON.stringify(b)); // put in top level and pass down to prevent repetitive calculations
    const shiftOffset = useSelector(selectShiftOffset);
    const extraStyles = useSelector(selectExtraBorderStyle(date));
    const teamId = useSelector(state => selectAppStateWithoutMenu(state).team_id);
    const team = useGetTeamQuery(teamId);

    let counter = counters.total;

    let counterStyle = '';
    if(counter === team.occupation){
        counterStyle = " bg-lime-300";
    }
    if(counter < team.occupation && counter > 0){
        counterStyle = "bg-red-300 ";
    }

    return(
      <div className="group">
          <div className="cel whitespace-small"></div>
          {
          getShift(date, shiftOffset) === "W"
              ? <div className="cel whitespace"></div>
              : <div className={"cel day-counter " + counterStyle + extraStyles}>{counter}</div>
          }
      </div>
    );
}