
import fetch from "cross-fetch"
import {API_URL} from "../../constants";
import {formatUrl} from "../../functions/date";
import catchAuthError from "../../functions/catchAuthError";

export const RECEIVE_STATES = "RECEIVE_STATES"

export function receiveStates(team_id, json){

    return {
        type: RECEIVE_STATES,
        team_id,
        states: json
    }
}

// TODO: this function used to fetch the states for a month each time and is called every time the month changes.
// To calculate the year holiday counter we need all states for the whole year, therefore this function fetches for a
// year now. However, it is not necessary to fetch them on every month change, this is now redundant work.
export function fetchStates(team_id, year, month){

    return function(dispatch) {

        //dispatching action to let app know the api call started
        const start = new Date(year-1, 11, 31);
        const end = new Date(year+1, 1, 1);

        return fetch(`${API_URL}/state?team_id=${team_id}&start=${formatUrl(start)}&end=${formatUrl(end)}`,
            {
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                }
            })
            .then(response => catchAuthError(response, dispatch))
            .then(
                response => response.json(),
                error => console.log("error occurred when fetching states", error)
            )
            .then(
                json => dispatch(receiveStates(team_id, json))
            )
            .catch(
                (error) => console.log(error)
            )
    }
}

export function deleteState(stateId, callback=null){

    return function(dispatch){
        return fetch(`${API_URL}/state/delete?id=${stateId}`,
            {
                credentials: "include",
                headers: {
                    "Accept": "application/json",
                }
            })
            .then(
                response => response.json(),
                error => console.log("error occured when delete state", error)
            )
            .then(
                response => {
                    if(callback !== null){
                        callback();
                    }
                }
            )
            .catch(
                (error) => console.log(error)
            )
    }
}