import React, {useEffect, useState} from "react";
import {setOptionField, useSetCheckBoxDefault, useSetOptionDefault} from "../../../../functions/forms/controls";
import {useDispatch, useSelector} from "react-redux";
import {addState} from "../../../actions/careersActions";
import {selectMembersListState} from "../../../reducers/selectors";
import {formatHumanShort} from "../../../../functions/date";
import {getGroupOptions, getTeamOptions, wrapInOption} from "../../../../functions/forms/options";
import {selectSchemesState, selectStructureState} from "../../../../redux/reducers/selectors";
import {getGroup, getGroups} from "../../../../functions/structure";
import {ASSISTANT_TEAM_LEADER, EDUCATIONS, OPERATOR, TEAM_LEADER, WORKER} from "../../../../constants";
import {getSchemeOptions} from "../../../../functions/scheme";
import Button from "../../../../components/buttons/Button";

export default function Insert({member, current}){

    const state = useSelector(selectMembersListState);
    const structure = useSelector(selectStructureState);
    const schemes = useSelector(selectSchemesState);
    const className = "form-control form-control-sm date";
    const dispatch = useDispatch();

    // TODO: check backend for enabled/disabled support!

    // team
    const [teamId, setTeamId] = useState(parseInt(current.enabled) === 1 ? parseInt(getGroup(structure, current.group_id).team_id) : 0) ; // TODO: test this
    useSetOptionDefault("team-insert", parseInt(current.enabled) === 1 ? getGroup(structure, current.group_id).team_id : 0);

    // group
    const [groupId, setGroupId] = useState(current.group_id);
    useSetOptionDefault("group-insert", current.group_id);

    // function
    const [func, setFunction] = useState(current.function);
    useSetOptionDefault("function", func);

    // education
    const [education, setEducation] = useState(current.education);
    useSetOptionDefault("education", education);

    // scheme
    const [scheme, setScheme] = useState(current.scheme_id);
    useSetOptionDefault("scheme", scheme)

    // occupation counter
    const [occupation, setOccupation] = useState(current.occupation_counter)
    useSetCheckBoxDefault("occupation" + member.member_id, parseInt(occupation));

    // holiday counter
    const [holiday, setHoliday] = useState(current.holiday_counter);
    useSetCheckBoxDefault("holiday" + member.member_id, parseInt(holiday));

    // holiday days
    const [holidayDays, setHolidayDays] = useState(current.holiday);
    useSetOptionDefault("holiday-days", holidayDays);

    // update groupId state and form field on teamId update
    useEffect( () => {

        const groupId = parseInt(current.group_id);
        let newGroupId;

        // if current team is selected, set current group as default
        if(teamId === 0 || getGroups(structure, teamId).includes(groupId)){
            newGroupId = groupId;

        }else { // if other team, get first group of that team
            newGroupId = getGroups(structure, teamId)[0];
        }

        // update state and form
        setGroupId(newGroupId)
        setOptionField("group-insert", newGroupId);

    }, [teamId, structure, current]);

    // date
    const insertDate = new Date(state.insert_date);
    const year = insertDate.getFullYear();
    const month = insertDate.getMonth() + 1;
    const date = insertDate.getDate();
    useSetOptionDefault("date" + member.member_id, date);
    useSetOptionDefault("month" + member.member_id, month);
    useSetOptionDefault("year" + member.member_id, year);

    const handleSubmit = () => {

        // const disabled =
        const data = {
            member_id: member.member_id,
            start_date: year + "-" + parseInt(month).pad(2) + "-" + parseInt(date).pad(2),
            group_id: groupId,
            enabled: teamId === 0 ? 0 : 1,
            function: func,
            education:education,
            holiday_counter:holiday,
            occupation_counter:occupation,
            holiday: holidayDays,
            scheme_id:scheme,
        };
        dispatch(addState(member.member_id, data));
    };

    return(
        <>
            <div className="career-row edit-row">
                <div className="name">
                    {formatHumanShort(new Date(state.insert_date))}
                </div>
                <div className="team">
                    <select className={className} id="team-insert" onChange={e => setTeamId(parseInt(e.target.value))}>
                        {getTeamOptions(structure, state.company_id)}
                        <option value="0">- inactief -</option>
                    </select>
                </div>
                <div className="group">
                    <select className={className} id="group-insert" onChange={e => setGroupId(e.target.value)}>
                        {teamId !== 0 ? getGroupOptions(structure, teamId) : null}
                    </select>
                </div>
                <div className="function">
                    <select className={className} id="function" onChange={e => setFunction(e.target.value)}>
                        <option value={WORKER}>arbeider</option>
                        <option value={OPERATOR}>bediende</option>
                        <option value={ASSISTANT_TEAM_LEADER}>assistent</option>
                        <option value={TEAM_LEADER}>ploegleider</option>
                    </select>
                </div>
                <div className="education">
                    <select className={className} id="education" onChange={e => setEducation(e.target.value)}>
                        {EDUCATIONS.map(value => wrapInOption(value))}
                    </select>
                </div>
                <div className="scheme">
                    <select className={className} id="scheme" onChange={e => setScheme(e.target.value)}>
                        <option value={0}>none</option>
                        {getSchemeOptions(schemes)}
                    </select>
                </div>
                <div className="occupation">
                    <input className={"toggle"} type="checkbox" id={"occupation" + member.member_id} onChange={e => setOccupation(e.target.checked)}/>
                    <div className={"toggle"}>
                        <label htmlFor={"occupation" + member.member_id}> </label>
                    </div>
                </div>
                <div className="holiday">
                    <input className={"toggle"} type="checkbox" id={"holiday" + member.member_id} onChange={e => setHoliday(e.target.checked)}/>
                    <div className={"toggle"}>
                        <label htmlFor={"holiday" + member.member_id}> </label>
                    </div>
                </div>
                <div className="holiday-days">
                    <input type="number" id="holiday-days" className="form-control w-9/12" name="holiday-days" onChange={e => setHolidayDays(e.target.value)}/>
                </div>
                <div className="actions children-right">
                    <Button color="lime" onClick={handleSubmit}>Opslaan</Button>
                </div>
            </div>
        </>
    );

}