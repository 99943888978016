import React from "react";
import {Link} from "@reach/router";
import {useDispatch} from "react-redux";
import {openEditRow} from "../../../actions/membersListActions";
import StateFields from "./StateFields";

export default function ShowRow({member, current}){

    const dispatch = useDispatch();


    return (
        <div className="show-row">
            <div className="name">
                <Link to={"/dashboard/member/" + member.member_id}>{member.name}</Link>
            </div>
            <div className="team"></div>
            <div className="group"></div>
            <StateFields state={current} />
            <div className="icons show-on-hover actions">
                <i className="material-icons"
                   onClick={() => dispatch(openEditRow(member.member_id))}
                >edit</i>
            </div>
        </div>
    );
}