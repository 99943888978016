import React from "react";

type props = {
    type?: string,
    placeholder?: string,
    onKeyUp?: () => void,
    onChange: (e: any) => void,
    value: string,
    autoFocus?: boolean,
    icon: string,
};

export default function InputWithIcon({
    type = 'text',
    placeholder = '',
    onKeyUp = () => null,
    onChange,
    value,
    autoFocus = true,
    icon,
  } : props) {

    return (
        <div className="flex pt-2 py-2">
            <div className="inline-block border-2 h-10 px-3 rounded-l-lg
                                    inline-flex items-center bg-coolgray-200">
                <i className="material-icons">{icon}</i>
            </div>
            <input
              type={type}
              placeholder={placeholder}
              onKeyUp={onKeyUp}
              onChange={onChange}
              value={value}
              autoFocus={autoFocus}
              required={false}
              className="inline-flex w-full h-10 px-3 py-3 border-2 rounded-r-lg
               focus:border-amber-700 outline-none placeholder-coolgray-500 text-lg"
            />
        </div>
    );
}