import React from "react";
import {wrapInOption} from "../../../../functions/forms/options";

type props = {
    theme: string,
    setTheme: (theme: string) => void,
}

export default function TeamThemeSelector({ theme, setTheme } : props) {

    const themes : {[key:string]: string} = {
        'red': 'Red',
        'orange': 'Orange',
        'amber': 'Amber',
        'yellow': 'Yellow',
        'lime': 'Lime',
        'green': 'Green',
        'emerald': 'Emerald',
        'teal': 'Teal',
        'cyan': 'Cyan',
        'sky': 'Sky',
        'blue': 'Blue',
        'indigo': 'Indigo',
        'violet': 'Violet',
        'purple': 'Purple',
        'fuchsia': 'Fuchsia',
        'pink': 'Pink',
        'rose': 'Rose',
    };

    return (
        <div className="mb-3">
            <label className="w-full">Thema's</label>

            <select data-testid="theme" className="custom-select mb-3" value={theme} onChange={e => setTheme(e.target.value)}>
                {Object.entries(themes).map(wrapInOption)}
            </select>

            <div className="flex items-center">
                <div className="inline-block w-1/4 pr-1">
                    <span style={{height: '30px'}} className={"bg-" + theme + "-200 w-full rounded-full inline-block"}> </span>
                </div>
                <div className="inline-block w-1/4 px-1">
                    <span style={{height: '30px'}} className={"bg-" + theme + "-400 w-full rounded-full inline-block"}> </span>
                </div>
                <div className="inline-block w-1/4 px-1">
                    <span style={{height: '30px'}} className={"bg-" + theme + "-600 w-full rounded-full inline-block"}> </span>
                </div>
                <div className="inline-block w-1/4 pl-1">
                    <span style={{height: '30px'}} className={"bg-" + theme + "-800 w-full rounded-full inline-block"}> </span>
                </div>
            </div>
        </div>
    );

}