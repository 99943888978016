import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../../modal/Modal";
import ModalHeader from "../../modal/ModalHeader";
import ModalTitle from "../../modal/ModalTitle";
import ModalBody from "../../modal/ModalBody";
import ConfirmButton from "../../../../components/buttons/ConfirmButton";
import {closeTeamSettingsModal} from "../structurePanelSlice";
import {selectStructurePanelState} from "../../../reducers/selectors";
import MinTeamOccupation from "./MinTeamOccupation";
import TeamThemeSelector from "./TeamThemeSelector";
import DeleteSection from "../../modal/DeleteSection";
import {useUpdateTeamMutation, useGetTeamsQuery, useDeleteTeamMutation} from "./teamApi"

export default function TeamSettingsModal() {

    const modalState = useSelector(state => selectStructurePanelState(state).teamSettingsModal);
    const { team } = useGetTeamsQuery(undefined, {
        selectFromResult: ({ data: teams }) => ({
            team: teams?.find((team) => team.id === modalState.teamId),
        })
    });

    const [updateTrigger] = useUpdateTeamMutation();
    const [deleteTrigger] = useDeleteTeamMutation();
    const dispatch = useDispatch();

    // form fields
    const [name, setName] = useState(team?.name);
    const [minOccupation, setMinOccupation] = useState(team?.occupation ?? 10);
    const [showInfoRow, setShowInfoRow] = useState(team?.info_row);
    const [theme, setTheme] = useState(team?.theme ?? '');

    const doUpdate = () => {
        updateTrigger({
            id: modalState.teamId,
            name,
            occupation: minOccupation,
            info_row: showInfoRow ? 1 : 0,
            theme,
        });
        dispatch(closeTeamSettingsModal());
    }

    const doDelete = () => {
        deleteTrigger(modalState.teamId);
        dispatch(closeTeamSettingsModal());
    }
    
    if(modalState.active === false){
        return null;
    }

    return (
        <Modal>
            <ModalHeader onClose={ () => dispatch(closeTeamSettingsModal())}>
                <ModalTitle>Ploeg instellingen</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <label>Naam</label>
                    <input value={name} onChange={e => setName(e.target.value)} type="text" className="form-control" />
                </div>
                <div className="mb-3">
                    <label>Info rij tonen</label>
                    <select
                        className="custom-select"
                        id="info-row"
                        value={showInfoRow ? 1 : 0}
                        onChange={e => setShowInfoRow(parseInt(e.target.value))}>
                        <option value="1">Ja</option>
                        <option value="0">Nee</option>
                    </select>
                </div>
                <MinTeamOccupation value={minOccupation} onChange={setMinOccupation}/>
                <TeamThemeSelector theme={theme} setTheme={setTheme}/>

                <DeleteSection onDelete={doDelete}>
                    Een ploeg kan niet verwijderd worden als ze nog groepen bevat.
                </DeleteSection>

                <div className="text-right">
                    <ConfirmButton onClick={doUpdate} />
                </div>
            </ModalBody>
        </Modal>
    );

}