import React from 'react';
import {useSelector} from 'react-redux'
import ProfileSection from "../profile/ProfileSection";
import {isAllowed} from "../../../../functions/authorization/authorization";
import CompanyList from "./CompanyList";
import {LIST_ALL_COMPANIES} from "../../../../functions/authorization/actions";
import TeamList from "./TeamList";
import ModeList from "./ModeList";
import YearList from "./YearList";
import ExtraList from "./ExtraList";

export default function Dropdown() {

    const canListAllCompanies = useSelector(state => isAllowed(state, LIST_ALL_COMPANIES));

    return (
        <section id="dropdown" className="w-full h-screen max-w-screen-3xl flex-auto lg:h-auto overflow-y-scroll block lg:flex justify-evenly">
            {canListAllCompanies && <CompanyList/> }
            <TeamList />
            <ModeList />
            <YearList />
            <ExtraList />
            <ProfileSection/>
        </section>
    );
}