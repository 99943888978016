import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {COMPANY_MANAGEMENT_LEVEL, GENERAL_MANAGEMENT_LEVEL} from "../../../../constants";
import {selectStructureState, selectUserState} from "../../../../redux/reducers/selectors";
import {getGroups, getTeams} from "../../../../functions/structure";
import {selectMembersByGroup} from "../selectStructure";
import {openAddTeamModal, openCompanySettingsModal} from "../structurePanelSlice"
import {isAllowed, isTeamManager} from "../../../../functions/authorization/authorization";
import {STRUCTURE_MANAGE_COMPANIES} from "../../../../functions/authorization/actions";
import {useGetGroupsByTeamQuery} from "../group/selectors";
import {useGetUsersQuery} from "../../users/usersApi"

export default function CompanySelectorInfo({ companyId }) {

    const dispatch = useDispatch();
    const user = useSelector(selectUserState)
    const structure = useSelector(selectStructureState);
    const management = useGetManagement(companyId);
    const members = useSelector(selectMembersByGroup);
    const teams = getTeams(structure, companyId);
    const canManageCompanies = useSelector(state => isAllowed(state, STRUCTURE_MANAGE_COMPANIES));
    const groupsByTeam = useGetGroupsByTeamQuery(user.team_id);

    // list all groups in this company
    const groups = teams.map(a => a[0]).reduce((prev, current) => prev.concat(getGroups(structure, current)), []);

    // count all members in this company, based on groups above
    const memberCount = Object.entries(members).filter(e => groups.includes(parseInt(e[0]))).reduce((prev, curr) => prev + curr[1].length, 0);

    const membercountByTeam = Object.entries(members).filter(e => groupsByTeam.map(team => team.id).includes(parseInt(e[0]))).reduce((prev, curr) => prev + curr[1].length, 0);

    return (
        <div className="p-3">
            {/* OVERVIEW section */}
            <div className="inline-block w-1/3 align-top">
                <div className="text-xl font-semibold text-coolgray-800 mb-2">Overzicht</div>
                <div className="flex items-center py-1">
                    <i className="material-icons mr-2 text-coolgray-600">groups</i>
                    <span>{isTeamManager(user) ? '1 ploeg' : teams.length + ' ploegen'}</span>
                </div>
                <div className="flex items-center py-1">
                    <i className="material-icons mr-2 text-coolgray-600">people</i>
                    <span>{isTeamManager(user) ? groupsByTeam.length : groups.length} groepen</span>
                </div>
                <div className="flex items-center py-1">
                    <i className="material-icons mr-2 text-coolgray-600">person</i>
                    <span>{isTeamManager(user) ? membercountByTeam : memberCount} leden</span>
                </div>
            </div>

            {/* MANAGEMENT section */}
            <div className="inline-block w-1/3 align-top">
                <div className="text-xl font-semibold text-coolgray-800 mb-2">Management</div>
                {management.map(account => (
                    <div className="flex items-center py-1" key={account.id}>
                        <i className="material-icons-outlined material-icons mr-2 text-coolgray-600">admin_panel_settings</i>
                        <span>{account.first_name} {account.last_name}</span>
                    </div>
                    )
                )}
            </div>

            {/* ACTIONS section */}
            {canManageCompanies &&
                <div className="inline-block w-1/3 align-top">
                    <div
                        className="text-xl font-semibold text-coolgray-800 mb-2">Acties
                    </div>
                    <div className="flex items-center py-1 group cursor-pointer"
                         onClick={e => dispatch(openAddTeamModal())}>
                        <i className="material-icons mr-2 text-coolgray-600">add</i>
                        <span
                            className="group-hover:underline">Voeg ploeg toe</span>
                    </div>
                    <div className="flex items-center py-1 group cursor-pointer"
                         onClick={e => dispatch(openCompanySettingsModal({companyId}))}>
                        <i className="material-icons mr-2 text-coolgray-600">settings</i>
                        <span className="group-hover:underline">Bedrijf instellingen</span>
                    </div>
                </div>
            }
        </div>
    );

}

function useGetManagement(companyId) {

    const {data: users} = useGetUsersQuery();

    if(users === undefined)
        return [];

    return users.filter(user =>
        user.company_id === companyId
        && user.level >= COMPANY_MANAGEMENT_LEVEL
        && user.level < GENERAL_MANAGEMENT_LEVEL
    )
}
