import React from "react";
import Button from "./Button";

type props = {
    children: any,
    icon: string,
    color: string,
    onClick: () => void,
}

export default function IconButton({children, icon, color, onClick} : props) {

    return (
        <Button color={color} onClick={onClick} >
            <i className="material-icons pr-2 text-white">{icon}</i>
            <span>{children}</span>
        </Button>
    );

}