import React from "react";

type props = {
    children: any,
}

export default function Head({children}: props) {

    return (
        <div className="p-3 border-b bg-gray-50 font-semibold text-coolgray-600 flex items-center">
            {children}
        </div>
    );

}
