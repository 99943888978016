import {api} from "../../../../redux/api";
import {CreateTeamRequest, Team, UpdateTeamRequest} from "./types";

export const teamApi = api.injectEndpoints({
    endpoints: (build) => ({
        getTeams: build.query<Team[], void>({
            query: () => 'team',
            providesTags: ['Teams'],
        }),
        addTeam: build.mutation<void, CreateTeamRequest>({
            query: (team) => ({
                url: '/team',
                method: 'POST',
                body: team,
            }),
            invalidatesTags: ['Teams', 'Groups']
        }),
        updateTeam: build.mutation<void, UpdateTeamRequest>({
            query: (team) => {
                return {
                    url: '/team/' + team.id,
                    method: 'PUT',
                    body: team,
                }
            },
            invalidatesTags: ['Teams']
        }),
        deleteTeam: build.mutation<void, number>({
            query: (id) => ({
                url: '/team/' + id,
                method: 'DELETE',
            }),
            invalidatesTags: ['Teams'],
        }),
    })
});

export const {
    useGetTeamsQuery,
    useAddTeamMutation,
    useUpdateTeamMutation,
    useDeleteTeamMutation,
} = teamApi;